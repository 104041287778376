import React, { useState, useEffect } from 'react'

//UI Imports
import CustomIcon from '../ui/CustomIcon';

//Icons and Images
import iconLocationPin from '../../img/Icon_Staedte_Map_Pin_Grosz.svg';
import iconAusrufezeichen from '../../img/Icon_i_Info_Anbieter_Merkmal.svg';
import OfferIcon from '../../img/Icon_Schnaeppchen_weiss.svg';

// Firebase
import { db } from '../../Firebase'
import { getDoc, doc, getDocs, collection, query, where } from 'firebase/firestore';
import Loader from '../Loader';

function Offers() {

    const [ads, setAds] = useState([])
    const [loading, setLoading] = useState(true)

    Date.prototype.addDays = function(days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }

    useEffect(() => {

        ads.length = 0;
        const postsRef = collection(db, "posts");
        const posts = query(postsRef, where("state", "==", "running"));

        getDocs(posts).then((docs) => {
            var tempArray = []

            //NOW
            var nowDate = new Date(Date.now())
            nowDate.setMinutes(0)
            nowDate.setHours(0)
            nowDate.setSeconds(0)

            docs.forEach((doc) => {
                //check if offer
                if(doc.data().offerStart){
                    console.log(doc.data().offerStart)
                    var date = new Date(doc.data().offerStart.toDate())
			        date.setDate(date.getDate() + 7)

			        if(nowDate < date){
                        tempArray.push(doc);
                    }
                }

                //results.push(doc);
                
            })

            //sort by date
            tempArray.sort(function(a,b){
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return a.data().offerStart.toDate() - b.data().offerStart.toDate()
            })
            
            setAds([...tempArray])
            setLoading(false)
            

        })
    }, [])
    

    return (
        <div className="AdsProfile">
            {ads.length !== 0 ? <h2 style={{ paddingBottom: "0px" }}>GEPLANTE SCHNÄPPCHEN</h2> : <h2 style={{ paddingBottom: "0px" }}>KEINE SCHNÄPPCHEN</h2>}
            {loading ? <Loader /> : 
            <section className="ergebnisseWrapper" style={{ paddingBottom: "20px" }}>
                <div className='ergebnisse'>
                    {ads.length !== 0 && ads.map((data, key) => (
                        <div key={key}>
                            <div className='offerBanner'>
								<div>
                                    <div>SCHNÄPPCHEN: {data.data().offerValue}{data.data().offerType === 1 ? "%" : "€"} Rabatt <span style={{fontSize: "12px"}}>vom {String(data.data().offerStart.toDate().toLocaleDateString("de-DE"))} bis {data.data().offerStart.toDate().addDays(7).toLocaleDateString("de-DE")}</span></div>
									{data.data().offerDescription !== "" ? <div style={{fontSize: "12px", marginTop: "5px", textAlign: "left"}}>{data.data().offerDescription}</div> : null} 
								</div>
								<div><img src={OfferIcon} /></div>
							</div>
                            <div className='top'>
                                <img src={data.data().img[0].url} alt="" />
                                <div>
                                    <h4>{data.data().title}</h4>
                                    <ul>
                                        <li><CustomIcon image={iconAusrufezeichen} imageAlt="Location Pin" />{data.data().servicesName}</li>
                                        <li><CustomIcon image={iconAusrufezeichen} imageAlt="Merkmal" />{data.data().serviceQuick}</li>
                                        <li><CustomIcon image={iconAusrufezeichen} imageAlt="Merkmal" />{data.data().nameQuick}</li>
                                        <li><CustomIcon image={iconLocationPin} imageAlt="Merkmal" />{data.data().address}{data.data().address && (data.data().zip || data.data().location) ? ", " : ""}{data.data().zip}{data.data().zip ? " " : ""}{data.data().location}</li>
                                    </ul>
                                    {(data.data().type === "flat" || data.data().type === "plus") && <button className="buttonSmallAd" id={data.id}>Mehr Infos und Bildergalerie</button>}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            }
        </div>
    )
}

export default Offers