import React from 'react'
import './ButtonsLayout.css';

const ButtonsLayout = props => {
    return (
        <div className="bigButtons" style={props.style}>
            {props.children}
        </div>             
    )
}

export default ButtonsLayout;