import React from 'react';
import './Pricecard.css'

const Pricecard = (props) => {
  return (
    <div className="pricecard" style={{marginTop: props.margin ? "40px" : ""}}>
        <div className="heading" style={{...props.style, paddingBottom: props.margin ? "0px" : ""}}>
            <h3>{props.heading}</h3>
            <div style={{lineHeight: "22px"}}>{props.description}</div>
        </div>
        {props.children}
    </div>
  );
}

export default Pricecard;