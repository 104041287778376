// React
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../context/UserContext';

//CSS
import './Kategorien.css';

//Firebase
import { db } from '../../Firebase'
import { collection, getDocs } from 'firebase/firestore';

//UI Imports
import Header from '../ui/Header';
import ButtonsLayout from '../ui/ButtonsLayout';
import ImgButton from '../ui/ImgButton';
import Loader from '../Loader';

//Images and Icons
import imgAgenturenPlanung from '../../img/agenturen-planung.jpg'
import TopShapeWeiss from '../../img/TrennerObenWeiß.png'
import Trenner from '../ui/Trenner';

function Kategorien() {
    const [kategorien, setKategorien] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { locationContext } = useUserContext()

    useEffect(() => {
        setLoading(true);
        getDocs(collection(db, 'categories')).then((docs) => {
            var categoriesSorted = [];
            docs.forEach((doc) => {
                categoriesSorted.push(doc);
                console.log(doc);
            })
            categoriesSorted.sort(function (a, b) {
                var nameA = a.data().name.toUpperCase() // ignore upper and lowercase
                var nameB = b.data().name.toUpperCase() // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                // names must be equal
                return 0;
            });
            setKategorien(categoriesSorted);
            setLoading(false);
        })
    }, []);

    return (
        <div className="kategorien">
            <Header className="backgroundAnimation" nav="scroll">
                <h2>RUBRIKEN</h2>
                <h3>{locationContext ? locationContext : "Deutschland"}</h3>
            </Header>
            <Trenner shape="small" foreground="white" background="animated" />
            <main>
                <section className="wrapperImgButtons">
                    {/** <p>Hallo. Ich bin ein Blindtext. Jetzt denken Sie vielleicht: Ach Herrje, der arme Text! Das muss ja furchtbar sein, so klein und ganz blind!<br />Aber das ist gar nicht so schlimm. Ich wurde nämlich schon als Blindtext geboren.</p>*/}
                    {loading ? <Loader /> :
                        <ButtonsLayout style={{paddingTop: "15px"}}>
                            {kategorien.map((data, index) => (
                                <ImgButton onClick={() => navigate(`/kategorie-anzeigen/${data.data().name}/leer`)} key={index} image={data.data().img ? data.data().img : imgAgenturenPlanung}>
                                    <span>{data.data().name}</span>
                                </ImgButton>
                            ))}
                        </ButtonsLayout>
                    }
                </section>
            </main>
        </div>
    )
}

export default Kategorien;