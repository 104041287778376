import React, { useEffect, useState } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';

function DayPickerSingle(props) {

	const WEEKDAYS_SHORT = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];

	return (
		<DayPicker
			weekdaysShort={WEEKDAYS_SHORT}
			selectedDays={props.selectedDays}
			onDayClick={props.setSelectedDays}
			firstDayOfWeek={1}
		/>
	)
}

export default DayPickerSingle;
