import React, { useRef, useState } from 'react';
import Header from './Header';
import CircularProgress from '@mui/material/CircularProgress';
import { useUserContext } from '../../context/UserContext';
import { useNavigate, Navigate, Link } from 'react-router-dom'
import PopUp from '../PopUp';


function ResetPasswordProvider() {

	const emailRef = useRef()

	const navigate = useNavigate()

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState("")

	const { forgotPassword } = useUserContext()

	async function signInNewUser(){
		setLoading(true)
        forgotPassword(emailRef.current.value).then(() => {
            setError("Wir haben dir eine E-Mail gesendet")
            setLoading(false)
        }).catch((err) => {
            setError("Es ist ein Fehler aufgetreten", err)
            setLoading(false)
        })
	}

	return (
		<>
			{/* user && <Navigate to="/Dashboard"/> */}
			{error && <PopUp message={error} setError={setError}/>}
			<div className="LoginProvider">
				<Header headline={"WILLKOMMEN BEI iVENTI"} text={"Zeige deine Region, was du zu bieten hast!"} color={"#F6F6F5"} />
				<div className="headlineNormal">Hier einloggen</div>
				<div style={{maxWidth: "90%", width: "500px", margin: "auto", paddingTop: "20px", textAlign: "center"}}>
					<input ref={emailRef} className="textField" type="text" placeholder="E-Mail-Adresse*" />
				</div>
				<div style={{cursor: "pointer" ,width: "100%", display: "flex", alignItems: "center", margin: "20px 0 50px 0", flexDirection: "column"}}>
					{loading ? 
						<button className="loadingButton"><CircularProgress style={{ color: "white", width: "31px", height: "31px" }} /></button>
						:
						<button onClick={signInNewUser} className="submitAdButton">PASSWORT ZURÜCKSETZEN</button>
					}
                    <Link to="/login" style={{cursor: "pointer", marginTop: "10px" ,fontSize: "10px"}}>Zurück</Link>
				</div>
			</div>
		</>
	)
}

export default ResetPasswordProvider;
