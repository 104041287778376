import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components'

const FlexDiv = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100px;
`;

function Loader() {
	return (
		<FlexDiv>
			<CircularProgress style={{ color: "orange" }} />
		</FlexDiv>
	)
}

export default Loader;
