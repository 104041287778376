import React, { useEffect, useState } from 'react';
import { animated, useTransition } from 'react-spring'
import { Navigate } from 'react-router-dom'
import { useUserContext } from '../../context/UserContext';
import './Dashboard.css'
import CartIcon from '../../img/cartIcon.svg'
import { NavLink, Outlet } from 'react-router-dom'
import PopUp from '../PopUp';
import IventiLogo from '../../img/LogoIventiWeiß.svg'
import TopShapeGrey from '../../img/TrennerObenWeißSmall.png'
import EmailVerification from './EmailVerification';
import { collection, getDocs, where, query } from 'firebase/firestore';
import { db } from '../../Firebase'
import Trenner from '../ui/Trenner';
import Header from '../ui/Header';
import ScrollNav from '../ui/ScollNav';
import SearchForm from '../ui/SearchForm'

function Dashboard() {

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState("")
	const [active, setActive] = useState("")

	const [checkCount, setCheckCount] = useState(0)


	const { user, logoutUser, forgotPassword, cartCount, setCartCount, admin, userType } = useUserContext()

	async function reset() {
		setLoading(true)
		forgotPassword(user.email).then(() => {
			setError("Wir haben dir eine E-Mail gesendet")
			setLoading(false)
		}).catch((err) => {
			setError("Es ist ein Fehler aufgetreten", err)
			setLoading(false)
		})
	}

	async function getCartNum() {

		const docs1 = getDocs(query(collection(db, "posts"), where("state", "==", "cart"), where("uid", "==", user.uid)))
		const docs2 = getDocs(query(collection(db, "inspos"), where("state", "==", "cart"), where("uid", "==", user.uid)))

		const [docs1Snapshot, docs2Snapshot] = await Promise.all([
			docs1,
			docs2
		])

		return docs1Snapshot.size + docs2Snapshot.size
	}

	async function getDocuments() {

		const posts = getDocs(query(collection(db, "posts"), where("state", "==", "check")))
		const inspos = getDocs(query(collection(db, "inspos"), where("state", "==", "check")))

		const [postsSnapshot, insposSnapshot] = await Promise.all([
			posts,
			inspos
		])

		return postsSnapshot.size + insposSnapshot.size

	}

	useEffect(() => {
		getCartNum().then((e) => {
			setCartCount(e)
		})

		if (admin) {
			getDocuments().then((data) => setCheckCount(data))
		}

	}, [])


	return (
		<>
			{error && <PopUp message={error} setError={setError} />}
			{!user ? "" :
				<>
					{/** 
		{!user.emailVerified &&
			<div style={{display: "flex", textTransform: "uppercase", fontSize: "14px", justifyContent: "center", fontWeight: "700", backgroundColor: "#FAB300", padding: "10px 0 7px 0", color: "white"}} className="infoBar">
				<div>Bestätige deine E-Mail-Adresse. Danach kannst du Inserate anlegen.</div>
				<div onClick={reset} style={{paddingLeft: "5px", textDecoration: "underline"}}>Bestätigung erneut senden.</div>
			</div>
		}
		*/}

					{!user.emailVerified ?
						<EmailVerification />
						:
						<>
							<div className="Dashboard">
								<Header className="backgroundAnimation" nav="simple">
									<h2 style={{ textTransform: "uppercase" }}>HEY, {user.displayName}</h2>
									<h3>WILLKOMMEN IN DEINEM ACCOUNT</h3>
								</Header>
								<Trenner shape="small" foreground="white" background="animated" />
								<div className="db_content">
									<div className="db_navigation">

										{(userType && userType !== "Benutzer") && <>

											<NavLink to="start" className="db_button">STARTSEITE</NavLink>
											<NavLink to="ads" className="db_button">NEUES<br />INSERAT IN<br />»RUBRIKEN«</NavLink>
											<NavLink to="inspo" className="db_button">NEUES<br />INSERAT IN<br />»INSPIRATION«</NavLink>
											<NavLink to="offer" className="db_button">NEUES<br />»SCHNÄPPCHEN« <br /> ANLEGEN</NavLink>
											<NavLink to="adsProfile" className="db_button">MEINE<br />INSERATE</NavLink>
											<NavLink to="merkliste" className="db_button">MERKLISTE</NavLink>
											<NavLink to="profile" className="db_button">MEIN PROFIL</NavLink>

											<NavLink style={{ position: "relative" }} value="cart" to="cart" className="db_button">
												<img className="db_cartItem" src={CartIcon} />
												{cartCount > 0 && <p style={{ position: "absolute", right: "10px", top: "10px", backgroundColor: "#f9b300", width: "27px", height: "27px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "50px", fontWeight: "700" }}>{cartCount}</p>}
											</NavLink>

										</>}

										{(userType && userType === "Benutzer") && <>

											<NavLink to="startUser" className="db_button">STARTSEITE</NavLink>
											<NavLink to="merkliste" className="db_button">MERKLISTE</NavLink>
											<NavLink to="profile" className="db_button">MEIN PROFIL</NavLink>

										</>}

										{admin &&
											<>
												<NavLink to="startUser" className="db_button">STARTSEITE <br />(Benutzer)</NavLink>
												<NavLink style={{ position: "relative" }} to="admin" className="db_button">
													FREIGEBEN
													{checkCount > 0 && <p style={{ position: "absolute", right: "10px", top: "10px", backgroundColor: "#f9b300", width: "27px", height: "27px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "50px", fontWeight: "700" }}>{checkCount}</p>}
												</NavLink>
												<NavLink to="orders" className="db_button">BESTELLUNGEN</NavLink>
												<NavLink to="editCategories" className="db_button">RUBRIKEN <br />BEARBEITEN</NavLink>
												<NavLink to="editInspo" className="db_button">INSPIRATIONEN <br />BEARBEITEN</NavLink>
												<NavLink to="checkOffers" className="db_button">ALLE <br />SCHNÄPPCHEN</NavLink>
												<NavLink to="editNews" className="db_button">NEWS <br />ANPASSEN</NavLink>
												<NavLink to="coupon" className="db_button">GUTSCHEINE</NavLink>
											</>
										}

										<a onClick={logoutUser} className="db_button">LOG-OUT</a>

									</div>
								</div>
							</div>
							<Outlet />
						</>
					}
				</>
			}
		</>

	)
}

export default Dashboard;
