import React, {useState} from 'react';
import imgCheckBorder from '../../img/CheckBorder_1.svg';
import imgToggleArrow from '../../img/toggle-arrow.png';
import './Accordion.css';

const Accordion = props => {
    const [expanded, setExpanded] = useState(false);
    const expandedClass = expanded ? 'expanded' : '';

    const onArrowClick = () => {
        setExpanded(!expanded);
    }

    return (
        <div className="point">
            <div className="icon">
                <img src={imgCheckBorder} alt=""/>
            </div>
            <div className="content">
                    <h3>{props.title}</h3>
                    <div className={"panel " + expandedClass}>
                        <p>{props.content}</p>
                    </div>
                    <button onClick={() => onArrowClick()} className="accordion"><img src={imgToggleArrow} alt="Toggle"/></button>
            </div>
        </div>
    );
}

export default Accordion;