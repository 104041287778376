import React, { useState, useEffect, useRef } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import "./Profile.css"
import { useUserContext } from '../../context/UserContext';
import { updateProfile, reload } from 'firebase/auth'
import { Navigate } from 'react-router-dom'
import PopUp from '../PopUp';

//firebase
import { db } from '../../Firebase'
import { setDoc, doc, getDoc } from "firebase/firestore";
import { deleteUser, reauthenticateWithCredential, EmailAuthProvider, getAuth } from 'firebase/auth'

import { useNavigate } from 'react-router-dom';

function Profile() {

	const navigate = useNavigate()

	//profile data
	const [name, setName] = useState("")
	const [street, setStreet] = useState("")
	const [zip, setZip] = useState("")
	const [location, setLocation] = useState("")
	const [company, setCompany] = useState("")
	const [checkedDelete, setCheckedDelete] = useState(false)

	const deleteAccount = useRef()

	const [deleteAccountPop, setDeleteAccountPop] = useState(false)

	const [loading, setLoading] = useState(false)
	const [loadingData, setLoadingData] = useState(false)
	const [error, setError] = useState("")
	const [popError, setPopError] = useState(false)

	const [password, setPassword] = useState("")

	const { user, forgotPassword, logoutUser, userType } = useUserContext()

	async function reset() {
		setLoading(true)
		forgotPassword(user.email).then(() => {
			setError("Wir haben dir eine E-Mail gesendet")
			setLoading(false)
		}).catch((err) => {
			setError("Es ist ein Fehler aufgetreten", err)
			setLoading(false)
		})
	}

	function saveData() {

		setLoadingData(true)
		updateProfile(user, {
			displayName: name
		}).then(() => {

			//save adress in profile
			setDoc(doc(db, "userData", user.uid), {
				address: street ? street : "",
				zip: zip ? zip : "",
				location: location ? location : "",
				company: company ? company : "",
				delete: deleteAccount.current?.checked ? true : false
			}, { merge: true }).then(() => {
				window.location.reload()
				setLoadingData(false)
			})
		})



	}


	function deleteAccountInDb() {

		const cred = EmailAuthProvider.credential(user.email, password)

		reauthenticateWithCredential(user, cred).then(() => {
			setPopError("")
			deleteUser(user).then(() => {
				navigate('/register')
			})
		}).catch(() => {
			setPopError("Passwort nicht korrekt.")
		})
		/*

		*/
	}

	useEffect(() => {
		setName(user.displayName)

		getDoc(doc(db, "userData", user.uid)).then((doc) => {
			setStreet(doc.data().address)
			setZip(doc.data().zip)
			setLocation(doc.data().location)
			setCompany(doc.data().company)
			setCheckedDelete(doc.data().delete)

		})


	}, [])



	return (
		<>
			{error && <PopUp message={error} setError={setError} />}

			{deleteAccountPop &&
				<div className="PopUp">
					<div style={{ position: "relative" }} className="innerPopUp">
						<div onClick={() => setDeleteAccountPop(false)} className="cancelIcon">+</div>
						<div className="headlinePopUp">Bist du sicher?</div>
						<div className="messagePopUp">Gebe dein Passwort ein:</div>
						<input value={password} onChange={(e) => setPassword(e.target.value)} style={{ margin: "0 0 20px 0", maxWidth: "90%" }} maxLength="50" className="textField" type="password" placeholder="Paswort" />
						{popError && <div style={{ paddingBottom: "15px", color: "red" }}>{popError}</div>}
						<button style={{ marginBottom: "20px" }} onClick={() => deleteAccountInDb()} className="submitAdButton">Konto löschen</button>
					</div>
				</div>
			}

			{!user ? <Navigate to="/login" /> :
				<div style={{ maxWidth: "1200px", margin: "auto", width: "90%" }} className="Profile">
					<div style={{ margin: "20px 0 20px 0" }} className="headlineNormal">Mein Profil</div>
					<input onChange={(e) => setName(e.target.value)} className="textField" type="text" placeholder="Dein Name" value={name} />

					<div className="dp_inputFields">
						<input onChange={(e) => setCompany(e.target.value)} className="textField" type="text" placeholder="Firma" value={company} />
						<input onChange={(e) => setStreet(e.target.value)} className="textField" type="text" placeholder="Straße" value={street} />
						<input onChange={(e) => setZip(e.target.value)} className="textField" type="number" placeholder="PLZ" value={zip} />
						<input onChange={(e) => setLocation(e.target.value)} className="textField" type="text" placeholder="Ort" value={location} />
						<input disabled className="textField" type="text" placeholder="Dein Name" value={user.email} />
						<input disabled className="textField" type="password" placeholder="" value={"***********"} />
					</div>
					{userType === "Benutzer" &&
						<div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "40px 0 0px 0" }} className="legal">
							<input checked={checkedDelete} onChange={(e) => setCheckedDelete(e.target.checked)} ref={deleteAccount} type="checkbox" />
							<div style={{ paddingLeft: "5px" }}>Konto 30 Tage nach letztem Event löschen.</div>
						</div>
					}
					<div className="buttonContainer" style={{ display: "flex", justifyContent: "center", margin: userType === "Benutzer" ? "20px 0 20px 0" : "40px 0 40px 0" }}>
						{loading ?
							<button style={{ margin: "8px" }} className="loadingButton"><CircularProgress style={{ color: "white", width: "31px", height: "31px" }} /></button>
							:
							<button style={{ margin: "8px" }} onClick={reset} className="submitAdButton">PASSWORT ÄNDERN</button>
						}
						{loadingData ?
							<button style={{ margin: "8px" }} className="loadingButton"><CircularProgress style={{ color: "white", width: "31px", height: "31px" }} /></button>
							:
							<button style={{ margin: "8px" }} onClick={saveData} className="submitAdButton">SPEICHERN</button>
						}

						{/** 
				<button style={{margin: "8px"}} onClick={logoutUser} className="submitAdButton">ABMELDEN</button> 
			*/}
					</div>

					{userType === "Benutzer" &&
						<div onClick={() => {
							setDeleteAccountPop(true)
							setPopError(false)
						}} style={{ cursor: "pointer", textAlign: "center", textDecoration: "underline", margin: "0 0 20px 0" }}>
							<div>Konto löschen</div>
						</div>
					}

				</div>
			}
		</>
	)
}

export default Profile;

