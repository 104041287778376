import React, { useEffect, useState } from 'react'
import './ProviderStart.css';
import Question from './Question';

//firebase
import { getDocs, collection, query, where } from 'firebase/firestore';
import { db } from '../../Firebase'
import Loader from '../Loader';

function ProviderStart() {

	const [news, setNews] = useState([])
	const [loading, setLoading] = useState(false)

	useEffect(() => {

		setLoading(true)

		var tempArray = []
		var count = 0

		getDocs(query(collection(db, "news"), where("section","==",0))).then((docs) => {
			docs.forEach((doc) => {
				tempArray.push(doc)
			})
			
			tempArray.sort(function(a,b){
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return b.data().time.toDate() - a.data().time.toDate()
            })

			var temp2Array = []

			tempArray.forEach((item) => {
				count += 1
				if(count <= 4){
					temp2Array.push(item)
				}
			})

			setNews([...temp2Array])
			setLoading(false)

		})

	}, [])

	function showAll(){
		setLoading(true)

		var tempArray = []

		getDocs(collection(db, "news")).then((docs) => {
			docs.forEach((doc) => {
				tempArray.push(doc)
			})
			
			tempArray.sort(function(a,b){
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return b.data().time.toDate() - a.data().time.toDate()
            })

			setNews([...tempArray])
			setLoading(false)

		})
	}
	

	return (
		<div className="providerStart">
			<div className="headline">
				<h2>STARTSEITE</h2>
			</div>
			<main>
				<div className="generalInformation container">
					<h3 style={{ textTransform: "uppercase", fontWeight: "800" }}>DEIN ACCOUNT – KURZ ERKLÄRT</h3>

					<p><strong>NEUES INSERAT IN »RUBRIKEN«</strong></p>
					<p>
						Mit diesem Button kannst du Inserate für alle deine Produkte und Dienstleistungen im iVenti-
						Hauptbereich »Rubriken« anlegen. Das kann ein kleines, kostenloses Inserat (iVenti ZERO) oder
						das große, kostenpflichtige Inserat »iVenti PLUS« sein. Du entscheidest, in welcher Rubrik und in
						welcher Unterkategorie dein Angebot erscheinen soll. Wichtig: Pro Inserat wird nur eine
						Dienstleistung bzw. ein Produkt beworben. Möchtest du mehr anbieten, erfordert dies ein
						weiteres Inserat.<br /><br />
						Mit dem iVenti-ZERO-Inserat hast du einmalig die Möglichkeit, ein Angebot mit vier Quick-
						Informationen kostenlos darzustellen.<br /><br />
						Der reguläre Preis für ein iVenti-PLUS-Inserat im Bereich »Rubriken« beträgt 99 € netto (erstes
						Inserat) bzw. 49 € netto (jedes weitere Inserat) für eine Laufzeit von jeweils einem Jahr. Mit
						unserem zeitlich begrenzten Top-Angebot <strong>iVenti FLAT</strong> (buchbar bis einschließlich 12.12.2022)
						kannst du zum einmaligen Preis von nur 149 € netto ein Jahr lang beliebig viele iVenti-PLUS-
						Inserate in den Bereichen »Rubriken« und »Inspiration« schalten.
					</p>

					<p><strong>NEUES INSERAT IN »INSPIRATION«</strong></p>
					<p>
						Neben dem Bereich »Rubriken« gibt es auch den Bereich »Inspiration«. Viele unserer
						Besucher:innen nutzen für ihre Suche diesen komfortablen iVenti-Bereich, um gezielt und schnell
						wertvolle Anregungen speziell für ihr anstehendes Event zu erhalten. Mit deinem Inserat in
						»Inspiration« kannst du punktgenau auf die Bedürfnisse deiner Kund:innen eingehen und somit
						noch mehr Aufmerksamkeit für Dein Angebot erlangen. <br /><br />
						Der Button NEUES INSERAT IN »INSPIRATION« bietet dir die Möglichkeit, ein bereits
						bestehendes Inserat zu duplizieren, zielgenau in Text und Bild an einen speziellen Event-Typ (z.
						B. »Hochzeit«) anzupassen und deiner Region zu präsentieren. <br /><br />
						Der reguläre Preis für ein Inserat im Bereich »Inspiration« beträgt 49 € netto, aber auch hier
						kannst du mit unserem zeitlich begrenzten Top-Angebot <strong>iVenti FLAT</strong> (buchbar bis einschließlich
						12.12.2022) zum einmaligen Preis von nur 149 € netto ein Jahr lang beliebig viele Inserate in den
						Bereichen »Rubriken« und »Inspiration« schalten.
					</p>

					<p><strong>MEINE INSERATE</strong></p>
					<p>
						Der Button MEINE INSERATE ist selbsterklärend: Hier findest du alle deine aktuell geschalteten
						Inserate und kannst sie während ihrer Laufzeit von einem Jahr beliebig oft bearbeiten und an
						dein Angebot anpassen.
					</p>


					<p><strong>MEIN PROFIL</strong></p>
					<p>
						Im Bereich MEIN PROFIL hast du jederzeit die Möglichkeit, die Zugangsdaten zu deinem iVenti-
						Account zu bearbeiten.
					</p>

					<p><strong>EINKAUFSWAGEN</strong></p>
					<p>
						Im EINKAUFSWAGEN findest du alle angelegten, aber noch nicht bezahlten Inserate.
					</p>



					{/*
          <p>Dies ist dein eigenes, kleines iVenti-Reich! Hier auf der <strong>STARTSEITE</strong> findest du die
            wichtigsten Infos zu deinem Account wie auch Tipps und Anregungen.</p>
          <p>Mit dem Button <strong>NEUES INSERAT IN »RUBRIKEN«</strong> kannst du beliebig viele Inserate im
            iVenti-Hauptbereich »Rubriken« anlegen. Dies können kleine, kostenlose Inserate (iVenti
            ZERO) oder große, kostenpflichtige Inserate (iVenti PLUS) sein. Für jedes deiner Inserate
            entscheidest du, in welcher Rubrik und in welcher Unterkategorie es erscheinen soll. Der
            reguläre Preis für ein iVenti-PLUS-Inserat im Bereich »Rubriken« beträgt 99 € netto (erstes
            Inserat) bzw. 49 € netto (jedes weitere Inserat). Mit unserem zeitlich begrenzten Top-
            Angebot <strong>iVenti FLAT</strong> (gültig bis zum 00.00.2022) kannst du zum einmaligen Preis von nur
            149 € netto ein Jahr lang beliebig viele iVenti-PLUS-Inserate schalten.</p>
          <p>Viele unserer Besucher:innen nutzen für ihre Suche den komfortablen iVenti-Bereich
            »Inspiration«. Hier finden sie wertvolle Anregungen zu ausgewählten Eventarten, z. B.
            »Hochzeiten«. Mit dem Button <strong>NEUES INSERAT IN »INSPIRATION«</strong> kannst du ein
            punktgenau auf die Bedürfnisse deiner Kund:innen zugeschnittenes Inserat gestalten. Hier
            entscheidest du dich für einen Event-Typ und eine Unterkategorie. Der reguläre Preis für ein
            Inserat im Bereich »Inspiration« beträgt 49 € netto, aber auch hier kannst du mit unserem
            zeitlich begrenzten Top-Angebot <strong>iVenti FLAT</strong> (gültig bis zum 00.00.2022) zum einmaligen
            Preis von nur 149 € netto ein Jahr lang beliebig viele Inserate schalten.</p>
          <p>Der Button <strong>MEINE INSERATE</strong> ist selbsterklärend: Hier findest du alle deine aktuell
            geschalteten Inserate und kannst sie während ihrer Laufzeit von einem Jahr beliebig oft
            bearbeiten und an dein Angebot anpassen.</p>
          <p>Im Bereich <strong>MEIN PROFIL</strong> hast du jederzeit die Möglichkeit, die Zugangsdaten zu deinem
            iVenti-Account zu bearbeiten.</p>
          <p>Im <strong>EINKAUFSWAGEN</strong> findest du alle angelegten, aber noch nicht bezahlten Inserate.</p>
        */}
				</div>
				<div className="random container" id="random1">
					<h3 style={{ fontWeight: "800", paddingBottom: "10px" }}>DU BRAUCHST EIN BILD?</h3>
					<p style={{ paddingTop: "10px", fontSize: "14px", lineHeight: "1.6em"}}><strong>GUT SCHAUST AUS!</strong></p>
					<p style={{ paddingTop: "10px", fontSize: "14px", lineHeight: "1.6em" }}>Dann sollen das auch alle sehen! Aber du weißt ja selbst: Manche Fotos sind schlichtweg
						»wow!«, andere entlocken eher ein »na ja«. Unser Profi-Fotograf Dennis hat das richtige
						Auge für dein Angebot und wie man es ins perfekte Licht rückt. Sende ihm eine Nachricht
						und lass dir ein unverbindliches Angebot erstellen. So wird auch dein Inserat ein »wow!«</p>
					<a href="mailto:hey@iventi.de" style={{ fontWeight: "700", padding: "15px 0 0 0", color: "#F9B300", display: "block", textDecoration: "none" }}>Anfrage senden</a>
				</div>
				<div className="random  container" id="random2">
					<h3 style={{ fontWeight: "800", paddingBottom: "10px" }}>DU BRAUCHST EINEN TEXT?</h3>
					<p style={{ paddingTop: "10px", fontSize: "14px", lineHeight: "1.6em"}}><strong>DU BIST EINFACH UNBESCHREIBLICH...</strong></p>
					<p style={{ paddingTop: "10px", fontSize: "14px", lineHeight: "1.6em" }}>Hand aufs Herz: Nicht jede:r von uns ist ein Goethe oder eine Giulia Enders. Ansprechende
						Texte zu schreiben ist eine Kunst, die nicht jedem von uns leicht von der Hand geht. Falls du
						Unterstützung bei den Texten für dein Inserat brauchst, wende dich gerne an unseren
						Textexperten Frank Herch. Schreib ihm eine Mail und lass dir ein unverbindliches Angebot erstellen.</p>
					<a href="mailto:hey@iventi.de" style={{ fontWeight: "700", padding: "15px 0 0 0", color: "#F9B300", display: "block", textDecoration: "none" }}>Anfrage senden</a>
				</div>
				<div className="random  container" id="random3">
					<h3 style={{ fontWeight: "800" }}>NEUIGKEITEN 🎉</h3>
					{loading && <Loader />}
					{!loading && news.map((data, index) => (
						<div className="newsSection" key={index}>
							<div className="newsTitle">{data.data().title}</div>
							<div className="newsText">{data.data().text}</div>
							{/** <div className="newsDate">{data.data().time.toDate().toLocaleDateString("de-DE")}</div>*/}
						</div>
					))}
					{news.length > 5 && <div onClick={() => showAll()} style={{color: "rgb(249, 179, 0)", fontWeight: "700", paddingTop: "10px", textAlign: "center", cursor: "pointer"}}>Alle anzeigen</div>}
				</div>
				<div className="random  container" id="random4">
					<h3 style={{ fontWeight: "800", paddingBottom: "10px" }}>DU BRAUCHST EINE WEBSEITE?</h3>
					<p style={{ paddingTop: "10px", fontSize: "14px", lineHeight: "1.6em" }}>Bei we-site bekommst du ein Rundum-Sorglos-Paket für deinen Online-Auftritt. Wir haben auch die iVenti-Plattform entwickelt.</p>
					<a href="mailto:hey@iventi.de" style={{ fontWeight: "700", padding: "15px 0 0 0", color: "#F9B300", display: "block", textDecoration: "none" }}>Anfrage senden</a>
				</div>
				<div className="questions">
					<h3>GUTE FRAGEN</h3>
					<Question title="Was ist iVenti?">Der Event-Store iVenti ist ein regionales und überregionales Online-Portal, auf dem Suchende einfach alles für ihr Event finden können. Anbieter:innen aus der Eventbranche können Inserate schalten, die dann von Interessent:innen gefunden werden. Neben der Suche über Event-Rubriken gibt es auch die Suche über „Inspiration” und „Schnäppchen”.</Question>
					<Question title="Keine Agentur! Nur du und die Suchenden.">Suchende treten immer direkt mit dir als Dienstleister:in in Kontakt. iVenti bleibt bei diesem Kontakt außen vor und hat keine Kenntnis von den Inhalten eurer Kommunikation. Von iVenti hast du bei dieser Kommunikation keinerlei Kosten zu erwarten.</Question>
					<Question title="Was für Inserat-Typen gibt es bei iVenti?">Auf iVenti.de gibt es 2 Arten von Inseraten: iVenti ZERO (kostenlos) und iVenti PLUS (kostenpflichtig). iVenti ZERO ist ein kleines Inserat mit 1 Foto und wenigen Basisinformationen. iVenti PLUS ist ein ganzseitiges Inserat mit bis zu 18 Fotos, 2 Filmen und umfangreichen Informationen zum Angebot. Über beide Inserate können Suchende kostenlos Kontakt mit den Anbieter:innen aufnehmen. In den Suchergebnissen werden beide Inserattypen in der Größe von iVenti ZERO angezeigt. Über den Button „Mehr Infos und Bildergalerie” bei iVenti-PLUS-Inseraten gelangen Suchende dann zum ganzseitigen Inserat. iVenti-PLUS-Inserate werden im vorderen Bereich der Suchergebnisse angezeigt, iVenti-ZERO-Inserate im hinteren Bereich.</Question>
					<Question title="Wie lange wird ein Inserat bei iVenti angezeigt?">Beide Inserat-Typen (iVenti ZERO und iVenti PLUS) werden jeweils für 1 Jahr auf iVenti.de gebucht und angezeigt. Sie können jederzeit bearbeitet und verlängert werden.</Question>
					<Question title="Was ist  unter „Rubriken“ zu finden?">Im Bereich „Rubriken“ werden sämtliche iVenti-Inserate angezeigt, sortiert nach Rubriken und Unterkategorien. Was immer bei iVenti angeboten wird: In „Rubriken“ wird es gefunden.</Question>
					<Question title="Wozu dient der Bereich „Inspiration”?">Viele unserer Besucher:innen nutzen für ihre Suche einfach den komfortablen iVenti-Bereich „Inspiration“. Hier finden sie zu ausgewählten Eventarten wertvolle Anregungen. Und hier finden sie auch dich –  mit deinem Inserat, das du punktgenau auf die Bedürfnisse deiner Kund:innen im gewählten Inspirationsbereich (z. B. „Hochzeit“) zugeschnitten hast. Ein Inserat im Bereich „Inspiration“ ist 1 Jahr sichtbar und mit den zusätzlichen, einmaligen Kosten von 49,– € netto verbunden.</Question>
					<Question title="Was ist ein „Schnäppchen”?">Um die Attraktivität deines iVenti-PLUS-Angebotes kurzfristig zu steigern, kannst du dein Inserat als „Schnäppchen“ kennzeichnen. Schnäppchen-Angebote sind immer 7 Tage gültig und werden im iVenti-Bereich „Schnäppchen“ exklusiv angezeigt. Außerdem erscheinen sie mit ihrem auffälligen, goldenen „Schnäppchen“-Header in den regulären Suchergebnissen unter „Rubriken“. Ein Schnäppchen-Inserat ist mit den zusätzlichen, einmaligen Kosten von 20,– € netto verbunden.</Question>
					<Question title="Wozu gibt es den „Notdienst”-Button / Notdienst-Service?">Werde ein:e Retter:in in der Not! iVenti bietet eine Notdienst-Funktion an für Menschen, denen bei ihrer Eventplanung ein:e Dienstleister:in kurzfristig abgesprungen ist. Aktivieren Suchende auf iVenti.de den „Notdienst”-Button, werden ausschließlich Inserate von Anbieter:innen angezeigt, die zum angefragten Zeitpunkt einen Notdienst bieten.Wenn du als Anbieter:in einen Notdienst-Service für dein iVenti-Inserat bereitstellen möchtest, kannst du für die gesamten Laufzeit deines Inserates beliebig viele Tage festlegen. Deine Angaben kannst du jederzeit ändern. Der Notdienst ist eine Extra-Leistung für deine Kunden und macht aus dir ein:e Held:in. Es entstehen den Suchenden und den Anbieter:innen durch den Notdienst-Service keine Kosten.</Question>
					<Question title="Wie treten Suchende mit Anbieter:innen in Kontakt?">In jedem iVenti-Inserat gibt es einen Button „Kostenlose Anfrage”. Hier können Suchende über ein Kontaktformular eine Gratis-Anfrage direkt an die Anbieter:innen versenden. Die weitere Kommunikation zwischen Interessent:in und Anbieter:in läuft dann über deren eigene E-Mail-Adressen. iVenti dient bei dieser Kommunikaton lediglich als erster Vermittler, ist selbst an dieser Kommunikation nicht beteiligt und erhält keinerlei Kenntnis von den Gesprächsinhalten. Ebenso speichert iVenti keinerlei Daten dieser Kommunikation.</Question>
					<Question title="Welchen Vorteil haben Suchende bei iVenti?">Wer ein kleines oder großes Event plant, findet auf iVenti schnell und einfach regionale und überregionale Anbieter:innen aus der gesamten Eventbranche. Suchende können für sie interessante Inserate in Merklisten zusammenfassen (hierzu müssen sie einen kostenlosen iVenti-Account anlegen). Über den Button „Kostenlose Anfrage” können Suchende über ein Kontaktformular eine kostenlose Anfrage direkt an die Anbieter:innen versenden. Die Nutzung von iVenti ist für Suchende völlig kostenlos.</Question>
					<Question title="Welchen Vorteil haben Anbieter:innen von iVenti?">Mit ihrem Inserat können Dienstleister:innen aus allen Bereichen der Eventbranche regional und überregional auf sich aufmerksam machen und schnell und einfach gefunden werden. So können sie in kurzer Zeit ihre Bekanntheit und ihren Kundenkreis vergrößern und ihre Umsätze steigern. In ihrem iVenti-Account können Anbieter:innen ihre Inserate, ihr Profil und Statistiken verwalten. Es gibt kleine, kostenlose Inserate (iVenti ZERO) und ganzseitige, kostenpflichtige Inserate (iVenti PLUS). Ein Inserat wird für 1 Jahr gebucht und kann jederzeit bearbeitet und verlängert werden. Neben den Gebühren für Inserate fallen keinerlei zusätzliche Kosten oder Provisionen an.</Question>
					<Question title="iVenti GO – was ist das?">Als Dienstleister:innen kannst du eine Bestellung nicht anliefern? Auch dein:e Kund:in hat keine Möglichkeit, bestellte Artikel abzuholen? Dann lass „das gesamte Event“ einfach von iVenti GO liefern und nach der Feier wieder abholen!</Question>
				</div>
			</main>
		</div>
	)
}

export default ProviderStart