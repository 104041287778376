import React, { useEffect, useState } from 'react';
import './AdsProfile.css'
import { getDocs, query, collection, where, deleteDoc, doc } from "firebase/firestore"; 
import { db } from '../../Firebase'
import SmallAd from './SmallAd';
import AlarmIcon from '../../img/alarmIcon.svg'
import AlarmIconActive from '../../img/alarmIconActive.svg'
import EditIcon from '../../img/editIcon.svg'
import DeleteIcon from '../../img/deletIcon.svg'
import PopUp from '../PopUp';
import { useUserContext } from '../../context/UserContext';
import Loader from '../Loader';
import { useNavigate } from 'react-router-dom';

//UI Imports
import CustomIcon from '../ui/CustomIcon';

//Icons and Images
import iconLocationPin from '../../img/Icon_Staedte_Map_Pin_Grosz.svg';
import iconAusrufezeichen from '../../img/Icon_i_Info_Anbieter_Merkmal.svg';
import iconNotdienst from '../../img/Icon_Notdienst_Verlauf.svg';
import iconNotdienstAktiv from '../../img/Icon_Notdienst_Aktiv.png';
import OfferIcon from '../../img/Icon_Schnaeppchen_weiss.svg';
import iconMerkliste from '../../img/Icon_Favorit_Verlauf.svg';
import PopUpEmergency from './PopUpEmergency';

function AdsProfile() {

	const navigate = useNavigate()

	const { user } = useUserContext()

	const [ads, setAds] = useState([])
	const [inspos, setInspos] = useState([])
	const [offers, setOffers] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState("")
	const [empty, setEmpty] = useState(false)

	const [update, setUpdate] = useState(false)

	async function getAds(){
		const docs = getDocs(query(collection(db, "posts"), where("state", "==", "running"), where("uid", "==", user.uid)))
		const docs2 = getDocs(query(collection(db, "posts"), where("state", "==", "check"), where("uid", "==", user.uid)))

		const docs3 = getDocs(query(collection(db, "inspos"), where("state", "==", "running"), where("uid", "==", user.uid)))
		const docs4 = getDocs(query(collection(db, "inspos"), where("state", "==", "check"), where("uid", "==", user.uid)))

		const docs5 = getDocs(query(collection(db, "inspos"), where("state", "==", "error"), where("uid", "==", user.uid)))
		const docs6 = getDocs(query(collection(db, "posts"), where("state", "==", "error"), where("uid", "==", user.uid)))

		var [docsSnapshot, docs2Snapshot, docs3Snapshot, docs4Snapshot, docs5Snapshot, docs6Snapshot] = await Promise.all([
			docs,
			docs2,
			docs3,
			docs4,
			docs5,
			docs6
		])

		/*

		if(docsSnapshot.size === 0 && docs2Snapshot.size === 0){
			return false
		} else if(docsSnapshot.size !== 0 && docs2Snapshot.size === 0){
			return docsSnapshot.docs
		} else if(docsSnapshot.size === 0 && docs2Snapshot.size !== 0) {
			return docs2Snapshot.docs
		} else if (docsSnapshot.size !== 0 && docs2Snapshot.size !== 0){
			return docsSnapshot.docs.concat(docs2Snapshot.docs)
		}

		*/

		return {posts: [...docsSnapshot.docs, ...docs2Snapshot.docs, ...docs6Snapshot.docs], inspos: [...docs3Snapshot.docs, ...docs4Snapshot.docs, ...docs5Snapshot.docs]}
		//return [...docsSnapshot.docs, ...docs2Snapshot.docs, ...docs3Snapshot.docs, ...docs4Snapshot.docs, ...docs5Snapshot.docs, ...docs6Snapshot.docs]
	}

	function deleteAd(e){
		var id = e.currentTarget.getAttribute("data-value")

		getDocs(query(collection(db, "inspos"), where("parent", "==", id))).then((items) => {
			if(items.size === 0){
				deleteDoc(doc(db, "posts", id)).then(() => {
					setAds(ads.filter((item) => item.id !== id))
				}).catch((err) => {
					console.log(err)
				})
			} else {
				setError("Du kannst ein Inserat nur löschen, wenn keine Inspirationen erstellt wurden.")
			}
		})

	}

	function editAd(e) {
		navigate(`/dashboard/editAds/${e.currentTarget.getAttribute("data-value")}`)
	}

	function deleteInspo(e){
		var id = e.currentTarget.getAttribute("data-value")

		deleteDoc(doc(db, "inspos", id)).then(() => {
			setInspos(inspos.filter((item) => item.id !== id))
		}).catch((err) => {
			console.log(err)
		})
	}

	function editInspo(e) {
		navigate(`/dashboard/editInspos/${e.currentTarget.getAttribute("data-value")}`)
	}

	Date.prototype.addDays = function(days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }

	useEffect(() => {
		
		getAds().then((e) => {
			if(e.posts.length === 0 && e.inspos.length === 0){
				setLoading(false)
				setEmpty(true)
			} else {
				if(e.posts.length !== 0){
					console.log("render")
					//today date
					var nowDate = new Date(Date.now())
					nowDate.setMinutes(0)
					nowDate.setHours(0)
					nowDate.setSeconds(0)

					e.posts.forEach((item) => {

						//build array with offers
						if(item.data().offerStart){
							var checkDate = new Date(item.data().offerStart.toDate())
							checkDate.setDate(checkDate.getDate() + 7)
							if(checkDate > nowDate){
								offers.push(item)
							}
						}

						ads.push(item)
						
					})
					setAds([...ads])
					setOffers([...offers])
				}
				if(e.inspos.length !== 0){
					e.inspos.forEach((item) => {
						inspos.push(item)
						
					})
					setInspos([...inspos])
				}
				setLoading(false)
			}	
		})
	}, [update])
	
	function checkTime(time){
		if(time){

			//TODAY
			var nowDate = new Date(Date.now())
			nowDate.setMinutes(0)
			nowDate.setHours(0)
			nowDate.setSeconds(0)

			var checkDate = new Date(time.toDate())
			checkDate.setDate(checkDate.getDate() + 7)

			console.log(checkDate, nowDate)

			if(checkDate > nowDate){
				return true
			} else {
				return false
				
			}

		} else {
			return false
		}
	}

	const [emergency, setEmergency] = useState(false)
	const [emergencyId, setEmergencyId] = useState()

	function editEmergency(item){
		setEmergencyId(item.currentTarget.getAttribute("data-value"))
		setEmergency(true)
	}

	function addDays(time){
		var date = new Date(time)
		date.setDate(time.getDate() + 7)
		return date.toLocaleDateString("de-DE")
	}

	return (
		<>
		{emergency && <PopUpEmergency id={emergencyId} cancel={setEmergency} update={update} setUpdate={setUpdate} /> }
		{error && <PopUp message={error} setError={setError} />}
		{loading ? 
		<Loader />
		:
		<div className="AdsProfile">
			<div className="headline">
			{empty && <h2>DU HAST KEINE INSERATE</h2>}	
			</div>
			{/**  OLD DESIGN
			{ads.length !== 0 && <h2 style={{paddingBottom: "0px"}}>INSERATE IN »RUBRIKEN«</h2>}
			<div className="ap_adsContainer">
				{ads.map((item, index) => (
					<div style={{marginTop: "50px"}} className="ap_item" key={index}>
					<div className="ap_item">
						<SmallAd headline={item.data().title} service={item.data().serviceQuick} name={item.data().nameQuick} categorie={item.data().service} address={item.data().zip} image={item.data().img[0].url} type={item.data().type}/>
						<div className="ap_editBar">
							<div className="ap_info"></div>
							<div onClick={item.data().state !== "check" ? (e) => editAd(e) : () => setError("Anzeige wird geprüft")} data-value={item.id} className="ap_delete">
								{item.data().alarm ? <img style={{width: "40px"}} src={AlarmIconActive} /> : <img style={{width: "40px"}} src={AlarmIcon} />} 
							</div>
							{(item.data().type === "flat" || item.data().type === "zero") &&
							<div onClick={(e) => deleteAd(e)} data-value={item.id} className="ap_delete">
								<img src={DeleteIcon} />
							</div>
							}
							<div onClick={(item.data().state !== "check" && checkTime(item.data().offerStart) === false) ? (e) => editAd(e) : () => setError("Anzeige wird geprüft oder ist in einem Schnäppchen hinterlegt.")} data-value={item.id} className="ap_edit">
								<img src={EditIcon} />
							</div>
						</div>
					</div>
					{item.data().state === "check" && <div style={{textAlign: "left", fontWeight: "700"}}>Dein Inserat wird geprüft :)</div>}
					{item.data().state === "error" && <div style={{textAlign: "left", fontWeight: "700", color: "red"}}>Bitte passe dein Inserat an :(</div>}
					{item.data().state === "running" && item.data().type === "zero" && <div style={{textAlign: "left", fontWeight: "700"}}>Zero-Inserat ist online</div>}
					{item.data().state === "running" && item.data().type !== "zero" && <div style={{textAlign: "left", fontWeight: "700"}}>{item.data().start.split("/")[1]}.{item.data().start.split("/")[0]}.{item.data().start.split("/")[2]} - {item.data().end.split("/")[1]}.{item.data().end.split("/")[0]}.{item.data().end.split("/")[2]}</div>}
					</div>
				))}
			
			</div>
			*/}
			{/**  NEW DESIGN */}
			{ads.length !== 0 && <h2 style={{paddingBottom: "0px"}}>INSERATE IN »RUBRIKEN«</h2>}
			<section className="ergebnisseWrapper" style={{paddingBottom: "20px"}}>
                <div className='ergebnisse'>
                    {ads.length !== 0 && ads.map((data, key) => (
                        <div key={key}>
                            <div className='top'>
                                <img src={data.data().img[0].url} alt="" />
                                <div>
                                    <h4>{data.data().title}</h4>
                                    <ul>
                                        <li><CustomIcon image={iconAusrufezeichen} imageAlt="Location Pin" />{data.data().servicesName}</li>
                                        <li><CustomIcon image={iconAusrufezeichen} imageAlt="Merkmal" />{data.data().serviceQuick}</li>
                                        <li><CustomIcon image={iconAusrufezeichen} imageAlt="Merkmal" />{data.data().nameQuick}</li>
                                        <li><CustomIcon image={iconLocationPin} imageAlt="Merkmal" />{data.data().address}{data.data().address && (data.data().zip || data.data().location) ? ", " : ""}{data.data().zip}{data.data().zip ? " " : ""}{data.data().location}</li>
                                    </ul>
                                    {(data.data().type === "flat" || data.data().type === "plus") && <button className="buttonSmallAd" onClick={(e) => navigate(`/adminAd/${e.currentTarget.id}/post`)} id={data.id}>Mehr Infos und Bildergalerie</button>}
                                </div>
                            </div>
                            <button className='btnMerkliste'><CustomIcon image={iconMerkliste} imageAlt="Merkliste" dimensions={{ width: '20px' }} />Auf Merkliste setzen</button>
                            <button className='btnNotdienst'><CustomIcon image={data.data().alarm ? iconNotdienstAktiv : iconNotdienst} imageAlt="Notdienst" dimensions={{ width: '25px' }} />{data.data().alarm ? "Notdienst eingestellt" : "Kein Notdienst eingestellt"}</button>
                            <button style={{padding: "unset"}} className='btnAnfrage'>
								<div className="ap_editBar ap_editBarCustom">
									<div onClick={(e) => editEmergency(e)} data-value={data.id} className="ap_delete">
										<img src={iconNotdienst} />
										<div>Notdienst <br/>bearbeiten</div>
									</div>
									{(data.data().type === "flat" || data.data().type === "zero") &&
									<div data-value={data.id} onClick={checkTime(data.data().offerStart) === false ? (e) => deleteAd(e) : () => setError("Anzeige ist in einem Schnäppchen hinterlegt.")} className="ap_delete">
										<img src={DeleteIcon} />
										<div>Löschen</div>
									</div>
									}
									<div onClick={(data.data().state !== "check" && checkTime(data.data().offerStart) === false) ? (e) => editAd(e) : () => setError("Anzeige wird geprüft oder ist in einem Schnäppchen hinterlegt.")} data-value={data.id} className="ap_edit">
										<img src={EditIcon} />
										<div>Bearbeiten</div>
									</div>
								</div>
                            </button>
							<div style={{paddingTop: "10px"}} className="infoBanner">
								{data.data().state === "check" && <div style={{textAlign: "left", fontWeight: "700"}}>Dein Inserat wird geprüft :)</div>}
								{data.data().state === "error" && <div style={{textAlign: "left", fontWeight: "700", color: "red"}}>Bitte passe dein Inserat an :(</div>}
								{data.data().state === "running" && data.data().type === "zero" && <div style={{textAlign: "left", fontWeight: "700"}}>Zero-Inserat ist online</div>}
								{data.data().state === "running" && data.data().type !== "zero" && <div style={{textAlign: "left", fontWeight: "700"}}>{data.data().start.split("/")[1]}.{data.data().start.split("/")[0]}.{data.data().start.split("/")[2]} - {data.data().end.split("/")[1]}.{data.data().end.split("/")[0]}.{data.data().end.split("/")[2]}</div>}
							</div>
						</div>
                    ))}
                </div>
            </section>

			{/* TEST

			{inspos.length !== 0 && <h2 style={{paddingBottom: "0px"}}>INSERATE IN »INSPIRATION«</h2>}
			<div className="ap_adsContainer">
				{inspos.map((item, index) => (
					<div style={{marginTop: "50px"}} className="ap_item" key={index}>
						<div className="ap_item">
							<SmallAd headline={item.data().title} service={item.data().service} name={item.data().nameQuick} categorie={item.data().servicesName} address={item.data().zip} image={item.data().img[0].url} type={item.data().type}/>
							<div className="ap_editBar">
								<div className="ap_info"></div>
								{item.data().type === "flat" &&
								<div onClick={item.data().state !== "check" ? (e) => deleteInspo(e) : () => setError("Anzeige wird geprüft")} data-value={item.id} className="ap_delete">
									<img src={DeleteIcon} />
								</div>
								}
								<div onClick={item.data().state !== "check" ? (e) => editInspo(e) : () => setError("Anzeige wird geprüft")} data-value={item.id} className="ap_edit">
									<img src={EditIcon} />
								</div>
							</div>
						</div>
					{item.data().state === "check" && <div style={{textAlign: "left", fontWeight: "700"}}>Dein Inserat wird geprüft :)</div>}
					{item.data().state === "error" && <div style={{textAlign: "left", fontWeight: "700", color: "red"}}>Bitte passe dein Inserat an :(</div>}
					{item.data().state === "running" && item.data().type === "zero" && <div style={{textAlign: "left", fontWeight: "700"}}>Zero-Inserat ist online</div>}
					{item.data().state === "running" && item.data().type !== "zero" && <div style={{textAlign: "left", fontWeight: "700"}}>{item.data().start.split("/")[1]}.{item.data().start.split("/")[0]}.{item.data().start.split("/")[2]} - {item.data().end.split("/")[1]}.{item.data().end.split("/")[0]}.{item.data().end.split("/")[2]}</div>}
					</div>
				))}
			</div>

			*/}

			{/**  NEW DESIGN */}
			{inspos.length !== 0 && <h2 style={{paddingBottom: "0px"}}>INSERATE IN »INSPIRATION«</h2>}
			<section className="ergebnisseWrapper" style={{paddingBottom: "20px"}}>
                <div className='ergebnisse'>
                    {inspos.length !== 0 && inspos.map((data, key) => (
                        <div key={key}>
                            <div className='top'>
                                <img src={data.data().img[0].url} alt="" />
                                <div>
                                    <h4>{data.data().title}</h4>
                                    <ul>
                                        <li><CustomIcon image={iconAusrufezeichen} imageAlt="Location Pin" />{data.data().servicesName}</li>
                                        <li><CustomIcon image={iconAusrufezeichen} imageAlt="Merkmal" />{data.data().serviceQuick}</li>
                                        <li><CustomIcon image={iconAusrufezeichen} imageAlt="Merkmal" />{data.data().nameQuick}</li>
                                        <li><CustomIcon image={iconLocationPin} imageAlt="Merkmal" />{data.data().address}{data.data().address && (data.data().zip || data.data().location) ? ", " : ""}{data.data().zip}{data.data().zip ? " " : ""}{data.data().location}</li>
                                    </ul>
                                    {(data.data().type === "flat" || data.data().type === "plus") && <button className="buttonSmallAd" onClick={(e) => navigate(`/adminAd/${e.currentTarget.id}/inspo`)} id={data.id}>Mehr Infos und Bildergalerie</button>}
                                </div>
                            </div>
                            <button className='btnMerkliste'><CustomIcon image={iconMerkliste} imageAlt="Merkliste" dimensions={{ width: '20px' }} />Auf Merkliste setzen</button>
                            <button className='btnNotdienst'><CustomIcon image={data.data().alarm ? iconNotdienstAktiv : iconNotdienst} imageAlt="Notdienst" dimensions={{ width: '25px' }} />{data.data().alarm ? "Notdienst eingestellt" : "Kein Notdienst eingestellt"}</button>
                            <button style={{padding: "unset"}} className='btnAnfrage'>
								<div className="ap_editBar ap_editBarCustom">
									{(data.data().type === "flat" || data.data().type === "zero") &&
									<div data-value={data.id} onClick={checkTime(data.data().offerStart) === false ? (e) => deleteInspo(e) : () => setError("Anzeige ist in einem Schnäppchen hinterlegt.")} className="ap_delete">
										<img src={DeleteIcon} />
										<div>Löschen</div>
									</div>
									}
									<div onClick={(data.data().state !== "check" && checkTime(data.data().offerStart) === false) ? (e) => editInspo(e) : () => setError("Anzeige wird geprüft oder ist in einem Schnäppchen hinterlegt.")} data-value={data.id} className="ap_edit">
										<img src={EditIcon} />
										<div>Bearbeiten</div>
									</div>
								</div>
                            </button>
							<div style={{paddingTop: "10px"}} className="infoBanner">
								{data.data().state === "check" && <div style={{textAlign: "left", fontWeight: "700"}}>Dein Inserat wird geprüft :)</div>}
								{data.data().state === "error" && <div style={{textAlign: "left", fontWeight: "700", color: "red"}}>Bitte passe dein Inserat an :(</div>}
								{data.data().state === "running" && data.data().type === "zero" && <div style={{textAlign: "left", fontWeight: "700"}}>Zero-Inserat ist online</div>}
								{data.data().state === "running" && data.data().type !== "zero" && <div style={{textAlign: "left", fontWeight: "700"}}>{data.data().start.split("/")[1]}.{data.data().start.split("/")[0]}.{data.data().start.split("/")[2]} - {data.data().end.split("/")[1]}.{data.data().end.split("/")[0]}.{data.data().end.split("/")[2]}</div>}
							</div>
						</div>
                    ))}
                </div>
            </section>

			{/** SHOW OFFERS */}

			{/**  NEW DESIGN */}
			{offers.length !== 0 && <h2 style={{paddingBottom: "0px"}}>INSERATE IN »SCHNÄPPCHEN«</h2>}
			<section className="ergebnisseWrapper" style={{paddingBottom: "20px"}}>
                <div className='ergebnisse'>
                    {offers.length !== 0 && offers.map((data, key) => (
                        <div key={key}>
							<div className='offerBanner'>
								<div>
									<div>SCHNÄPPCHEN: {data.data().offerValue}{data.data().offerType === 1 ? "%" : "€"} Rabatt <span style={{fontSize: "12px"}}>vom {String(data.data().offerStart.toDate().toLocaleDateString("de-DE"))} bis {data.data().offerStart.toDate().addDays(7).toLocaleDateString("de-DE")}</span></div>
									{data.data().offerDescription !== "" ? <div style={{textAlign: "left", fontSize: "12px", marginTop: "5px"}}>{data.data().offerDescription}</div> : null} 
								</div>
								<div><img src={OfferIcon} /></div>
							</div>
                            <div className='top'>
                                <img style={{borderRadius: "0px"}} src={data.data().img[0].url} alt="" />
                                <div style={{borderRadius: "0px"}}>
                                    <h4>{data.data().title}</h4>
                                    <ul>
                                        <li><CustomIcon image={iconAusrufezeichen} imageAlt="Location Pin" />{data.data().servicesName}</li>
                                        <li><CustomIcon image={iconAusrufezeichen} imageAlt="Merkmal" />{data.data().serviceQuick}</li>
                                        <li><CustomIcon image={iconAusrufezeichen} imageAlt="Merkmal" />{data.data().nameQuick}</li>
                                        <li><CustomIcon image={iconLocationPin} imageAlt="Merkmal" />{data.data().address}{data.data().address && (data.data().zip || data.data().location) ? ", " : ""}{data.data().zip}{data.data().zip ? " " : ""}{data.data().location}</li>
                                    </ul>
                                    {(data.data().type === "flat" || data.data().type === "plus") && <button className="buttonSmallAd" onClick={(e) => navigate(`/adminAd/${e.currentTarget.id}/post`)} id={data.id}>Mehr Infos und Bildergalerie</button>}
                                </div>
                            </div>
                            <button className='btnMerkliste'><CustomIcon image={iconMerkliste} imageAlt="Merkliste" dimensions={{ width: '20px' }} />Auf Merkliste setzen</button>
                            <button className='btnNotdienst'><CustomIcon image={data.data().alarm ? iconNotdienstAktiv : iconNotdienst} imageAlt="Notdienst" dimensions={{ width: '25px' }} />{data.data().alarm ? "Notdienst eingestellt" : "Kein Notdienst eingestellt"}</button>
                            <button style={{padding: "unset"}} className='btnAnfrage'></button>
							<div style={{paddingTop: "10px"}} className="infoBanner">
								{data.data().state === "check" && <div style={{textAlign: "left", fontWeight: "700"}}>Dein Inserat wird geprüft :)</div>}
								{data.data().state === "error" && <div style={{textAlign: "left", fontWeight: "700", color: "red"}}>Bitte passe dein Inserat an :(</div>}
								{data.data().state === "running" && data.data().type === "zero" && <div style={{textAlign: "left", fontWeight: "700"}}>Zero-Inserat ist online</div>}
								{data.data().state === "running" && data.data().type !== "zero" && <div style={{textAlign: "left", fontWeight: "700"}}>Schnäppchen: {data.data().offerStart.toDate().toLocaleDateString("de-DE")} - {addDays(data.data().offerStart.toDate())}</div>}
							</div>
						</div>
                    ))}
                </div>
            </section>
			

			


		</div>
		}
		</>
	)
}

export default AdsProfile;
