// React
import React from 'react';
import './Trenner.css'

// Images and Icons
import TopShapeGreySmall from '../../img/TrennerObenGreySmall.png'
import TopShapeGreyBig from '../../img/TrennerObenGrey.svg'
import TopShapeWhiteSmall from '../../img/TrennerObenWeißSmall.png'
import TopShapeWhiteBig from '../../img/TrennerObenWeiß.png'


function Trenner(props) {
    var classList = props.className ? props.className : "";
    var foreground = "";

    if(props.background == "animated"){
        classList += " backgroundAnimation";
    }

    if(props.foreground == "grey" && props.shape == "small"){
        foreground = TopShapeGreySmall;
    }else if(props.foreground == "white" && props.shape == "small"){
        foreground = TopShapeWhiteSmall;
    }else if(props.foreground == "grey" && props.shape == "big"){
        foreground = TopShapeGreyBig;
    }else if(props.foreground == "white" && props.shape == "big"){
        foreground = TopShapeWhiteBig;
    }



    
    // TODO: Figure out, how to scale and modify svg paths
    if(props.renderType === "svg"){
        return(
            <div className={`trenner ${classList}`}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5442.52 522.7">
                    <g data-name="Ebene 2">
                        <path
                        fill="#f6f5f9"
                        d="M5348.82 233.85q-93.7 73.5-265.28 73.51H2973.63q-98 0-160.31 25.17t-92.06 78.57q-29.78-53.4-92.06-78.57t-160.37-25.17H349.54q-171.59 0-265.3-73.51C21.78 184.85 0 106.9 0 0v522.7h5442.52V0q0 160.35-93.7 233.85z"
                        className="cls-1"
                        data-name="Ebene 1"
                        ></path>
                    </g>
                </svg>
            </div>
        );
    }

	return (
		<div className={`trenner ${classList}`} style={props.background ? (props.background == "animated" ? {} : {backgroundColor: props.background}) : {} }>
            <img style={{ width: "100%", display: "block" }} src={foreground} />
        </div>
	)
}

export default Trenner;