import React from 'react';
import './PopUp.css'

function PopUp(props) {
	return (
		<div className={`popupWrapper ${props.className}`}>
			<div className="popup">
				{props.children}
				<button onClick={() => props.setPopupVisible(false)} className="closeButton">+</button>
			</div>
		</div>

	)
}

export default PopUp;