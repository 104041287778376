import React, { useEffect, useState } from 'react'
import '../provider/ProviderStart.css';
import Question from '../provider/Question';

//firebase
import { getDocs, collection, query, where } from 'firebase/firestore';
import { db } from '../../Firebase'
import Loader from '../Loader';

function CustomerDashboardStart() {

	const [news, setNews] = useState([])
	const [loading, setLoading] = useState(false)

	useEffect(() => {

		setLoading(true)

		var tempArray = []
		var count = 0

		getDocs(query(collection(db, "news"), where("section","==",1))).then((docs) => {
			docs.forEach((doc) => {
				tempArray.push(doc)
			})
			
			tempArray.sort(function(a,b){
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return b.data().time.toDate() - a.data().time.toDate()
            })

			var temp2Array = []

			tempArray.forEach((item) => {
				count += 1
				if(count <= 4){
					temp2Array.push(item)
				}
			})

			setNews([...temp2Array])
			setLoading(false)

		})

	}, [])

	function showAll(){
		setLoading(true)

		var tempArray = []

		getDocs(collection(db, "news")).then((docs) => {
			docs.forEach((doc) => {
				tempArray.push(doc)
			})
			
			tempArray.sort(function(a,b){
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return b.data().time.toDate() - a.data().time.toDate()
            })

			setNews([...tempArray])
			setLoading(false)

		})
	}
	

	return (
		<div className="providerStart">
			<div className="headline">
				<h2>STARTSEITE</h2>
			</div>
			<main>
				<div className="generalInformation container">
					<h3 style={{ textTransform: "uppercase", fontWeight: "800" }}>DEIN ACCOUNT – KURZ ERKLÄRT</h3>

				</div>
				
				<div className="random  container" id="random3">
					<h3 style={{ fontWeight: "800" }}>NEUIGKEITEN 🎉</h3>
					{loading && <Loader />}
					{!loading && news.map((data, index) => (
						<div className="newsSection" key={index}>
							<div className="newsTitle">{data.data().title}</div>
							<div style={{wordBreak: "break-word"}} className="newsText">{data.data().text}</div>
							{/** <div className="newsDate">{data.data().time.toDate().toLocaleDateString("de-DE")}</div>*/}
						</div>
					))}
					{news.length > 5 && <div onClick={() => showAll()} style={{color: "rgb(249, 179, 0)", fontWeight: "700", paddingTop: "10px", textAlign: "center", cursor: "pointer"}}>Alle anzeigen</div>}
				</div>

				<div className="questions">
					<h3>GUTE FRAGEN</h3>
					
				</div>
			</main>
		</div>
	)
}

export default CustomerDashboardStart