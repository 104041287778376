import algoliasearch from 'algoliasearch'
import React from 'react'
import { SearchBox, InstantSearch, Configure, Hits, RefinementItem } from 'react-instantsearch-dom'
import '@algolia/autocomplete-theme-classic'
import { Autocomplete } from './Autocomplete'

const searchClient = algoliasearch('NIM1TBLOCU','ab07595f0187b797571393be254a8c09')
const index = searchClient.initIndex('Myindex')

index.search('Fred', {
    filters: 'name:"Fred"'
}).then(({ hits }) => {
    console.log(hits)
})

function Search() {

    return (
        <div>
            
            <InstantSearch searchClient={searchClient} indexName={'Myindex'}>
                <Configure filters='name:"Fred"'/>
                <div>
                    <Hits hitComponent={(hit) => <p>{hit.hit.name}</p>} />
                </div>
                <SearchBox searchAsYouType={false} />
            </InstantSearch>
            
            <Autocomplete />
            
        </div>
    )
}

export default Search
