//REACT
import React, { useState, useEffect } from 'react'
import { useUserContext } from '../context/UserContext';
import { useParams, useNavigate } from 'react-router-dom';

//HEADER ITEMS
import Header from './ui/Header';
import ScrollNav from './ui/ScollNav';
import SearchForm from './ui/SearchForm';

//CSS
import './SearchResults.css'
import './customer/KategorieAnzeigen.css';

//ICONS AND IMAGES
import imgKeineErgebnisse from '../img/Icon_Lupe_keine_Suchergebnisse.svg';
import iconAusrufezeichen from '../img/Icon_i_Info_Anbieter_Merkmal.svg';
import iconLocationPin from '../img/Icon_Staedte_Map_Pin_Grosz.svg';
import iconMerkliste from '../img/Icon_Favorit_Verlauf.svg';
import iconNotdienst from '../img/Icon_Notdienst_Verlauf.svg';
import iconNotdienstAktiv from '../img/Icon_Notdienst_Aktiv.png';
import iconKontakt from '../img/Icon_Nachricht_schreiben.svg';

//UI IMPORTS
import CustomIcon from './ui/CustomIcon';
import GratisAnfragePopUp from './ui/GratisAnfragePopup';


//ALGOLIA SEARCH
import algoliasearch from 'algoliasearch'
import '@algolia/autocomplete-theme-classic'
import Loader from './Loader';
import PopUpMerkliste from './customer/PopUpMerkliste';
const searchClient = algoliasearch('NIM1TBLOCU','ab07595f0187b797571393be254a8c09')
const index = searchClient.initIndex('Myindex')



/*
index.search('Fred', {
    filters: 'name:"Fred"'
}).then(({ hits }) => {
    console.log(hits)
})
*/

function SearchResults() {

	//const [results, setResults] = useState([])
	const [loading, setLoading] = useState(false)

	const { locationContext, search, notdienstSelectedDays, results, setResults } = useUserContext()

	const navigate = useNavigate()

	const [contactPopupVisible, setContactPopupVisible] = useState(false)
    const [inseratData, setInseratData] = useState()

	const [merkliste, setMerkliste] = useState(false);
    const [merklisteId, setMerklisteId] = useState();

	function startSearch(){

		setLoading(true)

		if(search === "") {
			setLoading(false)
			return
		}

		var temp = []
		notdienstSelectedDays.forEach((time) => {
			var date = new Date(time)
			date = String(date.getTime()).slice(0, -3)
			temp.push(parseInt(date))
		})
		
		index.search(`${search}`, {
			filters: !locationContext ? `state:"running"` : `(state:"running") AND (city:"${locationContext}")`
		}).then(({ hits }) => {
			var items = []
			hits.forEach((hit) => {
				if(notdienstSelectedDays.length !== 0){
					if(hit.selectedDays.some(e => temp.includes(e._seconds))){
						items.push({id: hit.objectID, data: hit})
					}
				} else {
					items.push({id: hit.objectID, data: hit})
				}
			})
			setLoading(false)

			// sort items check if zero
			// items.sort(function(a, b) {
			// 	var nameA = a.data().type.toUpperCase() // ignore upper and lowercase
			// 	var nameB = b.data().type.toUpperCase() // ignore upper and lowercase
			// 	if (nameA < nameB) {
			// 		return -1;
			// 	}
			// 	if (nameA > nameB) {
			// 		return 1;
			// 	}
			// 	// names must be equal
			// 	return 0;
			// })


			setResults(items)
		})
	}

	useEffect(() => {
		startSearch()
	}, [])

	Date.prototype.addDays = function(days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }

	function checkEmergency(data){
		var temp = []
		notdienstSelectedDays.forEach((time) => {
			var date = new Date(time)
			date = String(date.getTime()).slice(0, -3)
			temp.push(parseInt(date))
		})

        if(notdienstSelectedDays.length !== 0){
            if(data.data.selectedDays.some(e => temp.includes(e._seconds))){
				
                return true
            } 
        } 

        return false  
    }
	

	return (
		<div className="SearchResults kategorienAnzeigen">
			{contactPopupVisible && <GratisAnfragePopUp setPopupVisible={setContactPopupVisible} inseratData={inseratData} />}
			{merkliste && <PopUpMerkliste adType={0} type={0} id={merklisteId} setMerkliste={setMerkliste}/> }
			<Header className="backgroundAnimation" nav="simple">
                <ScrollNav onlyVisibleOnScroll={true} scrollOffset={500} id="scrollNav" notdienst={true} />
                <h2 style={{color: "white", fontWeight: "800", textTransform: "uppercase", fontSize: "35px", textAlign: "center", margin: "30px 0 30px 0"}}>Einfach alles für dein Event.</h2>
                <SearchForm design="normal" notdienst={true} click={() => startSearch()}/>
            </Header>
			<main>

			{loading ? <Loader /> : 
				<>
				{results.length === 0 &&
				<div className='keineErgebnisse'>
					<img src={imgKeineErgebnisse} alt="Keine Ergebnisse!" />
					<p>Keine Suchergebnisse!<br /> Bitte suche nach einem anderen Begriff</p>
				</div>
				}
				<section className="ergebnisseWrapper">
				
					<div className='ergebnisse'>
						{results.map((data, key) => (
							<div key={key}>
								<div className='top'>
									<img src={data.data.img[0].url} alt="" />
									<div>
										<h4>{data.data.title}</h4>
										<ul>
											<li><CustomIcon image={iconAusrufezeichen} imageAlt="Location Pin" />{data.data.servicesName}</li>
											<li><CustomIcon image={iconAusrufezeichen} imageAlt="Merkmal" />{data.data.serviceQuick}</li>
											<li><CustomIcon image={iconAusrufezeichen} imageAlt="Merkmal" />{data.data.nameQuick}</li>
											<li><CustomIcon image={iconLocationPin} imageAlt="Merkmal" />{data.data.address}{data.data.address && (data.data.zip || data.data.location) ? ", " : ""}{data.data.zip}{data.data.zip ? " " : ""}{data.data.location}</li>
										</ul>
										{(data.data.type === "flat" || data.data.type === "plus") && <button className="buttonSmallAd" id={data.id} onClick={(e) => navigate(`/adminAd/${e.currentTarget.id}/post`)}>Mehr Infos und Bildergalerie</button>}
									</div>
								</div>
								<button className='btnMerkliste' style={{borderRadius: "0px"}} onClick={() => {
										setMerklisteId(data.id)
										setMerkliste(true)
									}}>
										<CustomIcon image={iconMerkliste} imageAlt="Merkliste" dimensions={{ width: '20px'}} />Auf Merkliste setzen
								</button>
								<button className='btnNotdienst'><CustomIcon image={checkEmergency(data) ? iconNotdienstAktiv : iconNotdienst} imageAlt="Notdienst" dimensions={{ width: '25px' }} />{checkEmergency(data) != 0 ? "Notdienst aktiv" : "Aktuell kein Notdienst"}</button>
								<button className='btnAnfrage' onClick={() => {
									setContactPopupVisible(true);
									setInseratData({ anbieterName: data.data.nameQuick, inseratTitel: data.data.title, email: data.data.userMail });
								}}>
									<div>
										<span>Gratis Anfrage </span>
										Mit diseser Anfrage trittst du direkt mit dem Anbieter in Kontakt. iVenti ist an dieser Kommunikation nicht beteiligt und speichert sie nicht.
									</div>
									<CustomIcon image={iconKontakt} imageAlt="Kontaktieren" dimensions={{ width: '35px' }} />
								</button>
							</div>
						))}
					</div>
				</section>
				</>
				}
			</main>

		</div>
	)
}

export default SearchResults