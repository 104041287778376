import React from 'react'
import './ImgButton.css';

const ImgButton = props => {
    return (
        <button onClick={props.onClick} className="imgButton" style={props.style}>
            <img src={props.image} alt={props.imageAlt ? props.imageAlt : ""}  />
            <div className="we_overlay"></div>
            {props.children}
        </button>             
    )
}

export default ImgButton;