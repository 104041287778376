import React, { useEffect } from 'react'
import Loader from '../Loader';
import Header from './Header';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function ProcessOrder() {

  const navigate = useNavigate()

  const { id } = useParams()

  useEffect(() => {

    
    fetch('https://us-central1-iventi-6d02a.cloudfunctions.net/test/success', {
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		method: 'POST',
		body: JSON.stringify({id: id, type: "stripe"})
      })
      .then((response) => {return response.text()})
      .then((data) => {
        console.log(data)
        if(data === "success"){
          navigate("/dashboard/start")
        }
        if(data === "error"){
          alert("Es ist ein Fehler aufgetreten")
        }
      })
      .catch(() => {
        alert("Es ist ein Fehler aufgetreten")
      })
    
    
  }, [])
  

  return (
    <div className="ProcessOrder">
        <Header headline={"VIELEN DANK FÜR DEINEN EINKAUF!"}/>
        <Loader />
        <div style={{fontWeight: "800", textAlign: "center", paddingBottom: "100px"}}>BITTE BLEIBE AUF DIESER SEITE, DU WIRST GLEICH WEITERGELEITET...</div>
    </div>
  )
}

export default ProcessOrder