import React, {useState} from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const CustomSelect = props => {
    return (
        <div className="CustomSelect" style={{marginTop:"20px", border: props.error ? "1px solid red" : "", borderRadius: "5px"}}>
            <FormControl sx={{ display:'flex', textAlign: "left", ...props.sx}}>
                <Select
                value={props.select}
                onChange={(e) => {props.setSelect(e.target.value)}}
                sx={{
                    backgroundColor:'#fff',
                    fontFamily: 'Spartan, sans-serif',
                    fontSize:'14px',
                    border:'none !important',
                    ['& fieldset']:{
                        border:'none !important',
                        boxShadow: '1px 1px 5px #0000001a',
                        
                    },
                    ['& > div:first-child']:{
                        padding:'20px 32px 20px 20px !important',
                    }
                }}
                >
                {props.children}
                </Select>
            </FormControl>
        </div>
    );
}

export default CustomSelect;