import './App.css';
import './MobileDesign.css'
import Search from './components/Search';

import React, { createElement } from 'react';
import Gallery from './components/Gallery';
import CreateAd from './components/provider/CreateAd';
import MapsAutocomplete from './components/provider/MapsAutocomplete';
import ProviderGallery from './components/provider/ProviderGallery';
import { useUserContext } from './context/UserContext';
import Auth from './components/Auth'

import { db } from './Firebase'
import { collection, getDocs, getDoc, doc, query, where, deleteDoc } from 'firebase/firestore';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Summary from './components/provider/Summary';
import LandingPage from './components/provider/LandingPage';
import Dashboard from './components/provider/Dashboard';
import LoginProvider from './components/provider/LoginProvider';
import VideoTest from './components/provider/VideoTest';
import AdsProfile from './components/provider/AdsProfile';
import ResetPasswortProvider from './components/provider/ResetPasswordProvider';
import RegisterProvider from './components/provider/RegisterProvider';
import Profile from './components/provider/Profile';
import ProtectedRoutes from './components/ProtectedRoutes';
import EditAd from './components/provider/EditAd';
import CreateInspo from './components/provider/CreateInspo';
import EditInspo from './components/provider/EditInspo';
import ProviderStart from './components/provider/ProviderStart';
import ProcessOrder from './components/provider/ProcessOrder';
import Landing from './components/provider/Landing';
import CheckAds from './components/admin/CheckAds';
import ShowAd from './components/ShowAd';
import Impressum from './components/Impressum';
import Datenschutz from './components/Datenschutz';
import AGB from './components/AGB';
import Orders from './components/admin/Orders';
import CustomerStart from './components/customer/CustomerStart';
import Footer from './components/Footer';
import Categories from './components/admin/Categories';
import Kategorien from './components/customer/Kategorien';
import KategorieAnzeigen from './components/customer/KategorieAnzeigen';
import Inspirations from './components/admin/Inspirations';
import Inspirationen from './components/customer/Inspirationen';
import InspirationAnzeigen from './components/customer/InspirationAnzeigen';
import SearchResults from './components/SearchResults';
import CreateOffer from './components/provider/CreateOffer';
import ProcessOffer from './components/provider/ProcessOffer';
import Schnaeppchen from './components/customer/Schnaeppchen';
import MemoryList from './components/provider/MemoryList';
import Offers from './components/admin/Offers';
import News from './components/admin/News';
import CustomerDashboardStart from './components/customer/CustomerDashboardStart';
import Verification from './Verification';
import Coupon from './components/admin/Coupon';




function App() {
	return (
		<>
			<Router className="appContainer">
				<Routes>
					<Route element={<ProtectedRoutes />}>
						<Route path="/dashboard" element={<Dashboard />}>
							<Route path="start" element={<ProviderStart />} />
							<Route path="editAds/:id" element={<EditAd />} />
							<Route path="editInspos/:id" element={<EditInspo />} />
							<Route path="ads" element={<CreateAd />} />
							<Route path="cart" element={<Summary />} />
							<Route path="adsProfile" element={<AdsProfile />} />
							<Route path="profile" element={<Profile />} />
							<Route path="inspo" element={<CreateInspo />} />
							<Route path="admin" element={<CheckAds />} />
							<Route path="orders" element={<Orders />} />
							<Route path="editCategories" element={<Categories />} />
							<Route path="editInspo" element={<Inspirations />} />
							<Route path="offer" element={<CreateOffer />} />
							<Route path="merkliste" element={<MemoryList />} />
							<Route path="merkliste/:id" element={<MemoryList />} />
							<Route path="checkOffers" element={<Offers />} />
							<Route path="editNews" element={<News />} />
							<Route path="startUser" element={<CustomerDashboardStart />} />
							<Route path="coupon" element={<Coupon />} />
						</Route>
					</Route>

					<Route path="/login" element={<LoginProvider />} />
					<Route path="/impressum" element={<Impressum />} />
					<Route path="/datenschutz" element={<Datenschutz />} />
					<Route path="/agb" element={<AGB />} />
					<Route path="/register" element={<RegisterProvider />} />
					<Route path="/reset" element={<ResetPasswortProvider />} />
					<Route path="/thanks/:id" element={<ProcessOrder />} />
					<Route path="/thanks/offer/:id" element={<ProcessOffer />} />
					<Route path="/verify" element={<Verification />} />

					<Route path="/" element={<CustomerStart />} />
					<Route path="/adminAd/:id/:type" element={<ShowAd />} />
					<Route path="/cstart" element={<CustomerStart />} />
					<Route path="/kategorien" element={<Kategorien />} />
					<Route path="/kategorie-anzeigen/:id/:childId" element={<KategorieAnzeigen />} />
					<Route path="/inspirationen" element={<Inspirationen />} />
					<Route path="/inspiration-anzeigen/:id/:childId" element={<InspirationAnzeigen />} />
					<Route path="/schnaeppchen" element={<Schnaeppchen />} />
					<Route path="/search" element={<Search />} />
					<Route path="/anbieter" element={<Landing />} />
					<Route path="/searchResults" element={<SearchResults />} />
				</Routes>
			</Router>
			<Footer />
		</>
	);
}

export default App;
