import React from 'react'
import './ZurueckButton.css';
import { useParams, useNavigate } from 'react-router-dom';


const ZurueckButton = props => {
    const navigate = useNavigate();
    return (
        <div class="zurueckButtonWrapper">
            <button onClick={() => navigate(props.target ? props.target : -1)}>{props.title ? props.title : "Zurück"}</button>
        </div>             
    )
}

export default ZurueckButton;