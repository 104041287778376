import React, { useState, useEffect } from 'react'

//MATERIAL IMPORTS
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

//FIREBASE
import { arrayUnion ,collection, getDocs, getDoc, doc, query, where, setDoc, addDoc, deleteDoc } from 'firebase/firestore';
import { db, auth } from '../../Firebase'
import Loader from '../Loader';
import { CircularProgress, MenuItem } from '@mui/material';
import CustomSelect from '../ui/CustomSelect';



function News() {

    const [news, setNews] = useState([])
    const [loadingPop, setLoadingPop] = useState(false)
    const [loadingButton, setLoadingButton] = useState(false)
    const [newsPop, setNewsPop] = useState(false)
    const [title, setTitle] = useState("")
    const [text, setText] = useState("")
    const [id, setId] = useState()
    const [update, setUpdate] = useState(true)
    const [type, setType] = useState(true)
    const [section, setSection] = useState(0)

    useEffect(() => {
        
        var tempArray = []

        getDocs(query(collection(db, "news"), where("section","==",section))).then((docs) => {
            docs.forEach((doc) => {
                tempArray.push(doc)
            })
            
            tempArray.sort(function(a,b){
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return b.data().time.toDate() - a.data().time.toDate()
            })

            setNews([...tempArray])

        })

    }, [update, section])

    function editNewsPopUp(id){
        setType(true)
        setId(id)
        setNewsPop(true)
        setLoadingPop(true)

        getDoc(doc(db, "news", id)).then((doc) => {
            setTitle(doc.data().title)
            setText(doc.data().text)
            setLoadingPop(false)
        })
    }

    function addNewsPopUp(){
        setType(false)
        setTitle("")
        setText("")
        setNewsPop(true)

    }
    
    function saveNews(){
        setLoadingButton(true)
        setDoc(doc(db, "news", id), {
            title: title,
            text: text
        }, { merge: true }).then(() => {
            setNewsPop(false)
            setLoadingButton(false)
            setUpdate(!update)
        }).catch(() =>{
            setNewsPop(false)
            setLoadingButton(false)
        })
    }

    function saveNewNews(){
        setLoadingButton(true)

        addDoc(collection(db, "news"), {
            title: title,
            text: text,
            time: new Date(Date.now()),
            section: section
        }).then(() => {
            setNewsPop(false)
            setLoadingButton(false)
            setUpdate(!update)
        })
    }

    function deleteNews(){
        deleteDoc(doc(db, "news", id)).then(() => {
            setNewsPop(false)
            setLoadingButton(false)
            setUpdate(!update)
        })
    }

	const menuItemStyle = {
		fontFamily: 'Spartan, sans-serif !important',
		fontSize:'14px !important',
		['&.Mui-selected']:{
			background:'rgba(249, 179, 0, .3) !important'
		},
		['&:hover']:{
			background:'rgba(249, 179, 0, .3) !important'
		}
	};

    

    
    

    return (
        <div className="News">
            <div className="headline" style={{textAlign: "center", marginTop: "40px"}}>
                <h2 style={{ textAlign: "center", fontWeight: "800" }}>NEWS ANPASSEN</h2>
                <div style={{maxWidth: "200px", margin: "auto", marginBottom: "20px"}}>
                    <CustomSelect className="selectItem" select={section} setSelect={setSection}>
                        <MenuItem sx={menuItemStyle} value={0}>Anbieter</MenuItem>
                        <MenuItem sx={menuItemStyle} value={1}>Suchende</MenuItem>
                    </CustomSelect>
                </div>
                <div onClick={() => addNewsPopUp()} style={{textDecoration: "underline", color: "#FAB300", fontSize: "14px", fontWeight: "600", padding: "10px 0 10px 0", cursor: "pointer", marginBottom: "30px"}}>Neuigkeit hinzufügen</div>
            </div>

            {newsPop &&
                <div className="PopUp">
                    <div style={{position: "relative"}} className="innerPopUp">
                        {loadingPop ? <Loader /> : <>
                            <div onClick={() => setNewsPop(false)} className="cancelIcon">+</div>
                            <div className="headlinePopUp">{type ? "Neuigkeiten anpassen" : "Neuigkeit hinzufügen"}</div>
                            <div style={{marginTop: "10px"}} className="pm_checkListItem"><input value={title} onChange={(e) => setTitle(e.target.value)} style={{width: "100%"}} className="pm_input" type="text" placeholder="ÜBERSCHRIFT" /></div>
                            <div className="pm_checkListItem"><textarea value={text} onChange={(e) => setText(e.target.value)} style={{width: "100%"}} rows="5" className="pm_input" type="text" placeholder="NACHRICHT"></textarea></div>
                            {type && <div onClick={() => deleteNews()} style={{cursor: "pointer", color: "#FAB300", fontWeight: "600", textTransform: "uppercase", textDecoration: "underline", fontSize: "12px", padding: "10px"}}>Neuigkeit löschen</div>}
                            {loadingButton ? <div className="submitFlex" style={{backgroundColor: "unset", padding: "0px 0 20px 0"}}>
                                <button className="loadingButton"><CircularProgress style={{color: "white", width: "31px", height: "31px" }} /></button>
                            </div> 
                            :
                            <button onClick={type ? () => saveNews() : () => saveNewNews()} style={{marginBottom: "20px"}}  className="submitAdButton">SPEICHERN</button>
                            }
                        </>}
                    </div>
                </div>
            }
            

            {news.length !== 0 &&
                <TableContainer sx={{ maxWidth: "80%", margin: "auto", marginBottom: "50px", wordBreak: "break-all" }} component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Title</TableCell>
                                <TableCell>Nachricht</TableCell>
                                <TableCell align="right">Datum</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {news.map((row) => (
                                <TableRow
                                    key={row.id}
                                    id={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer" }}
                                    onClick={(e) => editNewsPopUp(e.currentTarget.id)}
                                >
                                    <TableCell style={{minWidth: "150px"}} component="th" scope="row">{row.data().title}</TableCell>
                                    <TableCell align="left">{row.data().text}</TableCell>
                                    <TableCell style={{minWidth: "100px"}} align="right">{row.data().time.toDate().toLocaleDateString()}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </div>
    )
}

export default News