import React from 'react';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import './Question.css';

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  paddingLeft: '30px',
  paddingRight: '30px',
  '& .MuiAccordionSummary-content': {
    margin: '20px 0'
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    '&.Mui-expanded': {
      transform: 'rotate(45deg)'
    }
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: '20px',
    paddingRight: '20px',
    '& .MuiAccordionSummary-content': {
      margin: '20px 0'
    },
  }
}));

const AccordionDetails = styled((props) => (
  <MuiAccordionDetails {...props} />
))(({ theme }) => ({
  padding: '0 30px 30px 30px',
  [theme.breakpoints.down('md')]: {
    padding: '0 0 20px 20px'
  }
}));

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const Question = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <div className="question">
        <MuiAccordion>
          <AccordionSummary
            expandIcon={<AddCircleOutlineIcon style={{ color: "#F9B300" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={{ fontWeight: 'bold', fontFamily: '\'Spartan\', sans-serif', color: '#525252' }}>{props.title}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ fontSize: '14px', lineHeight: '1.6em' }}>
            {props.children}
          </AccordionDetails>
        </MuiAccordion>
      </div>
    </ThemeProvider>
  )
}

export default Question;