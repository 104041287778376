import React, { useState, useEffect } from 'react'
import { animated, useTransition } from 'react-spring'
import { db } from '../Firebase'
import { arrayUnion ,collection, getDocs, getDoc, doc, query, where, setDoc } from 'firebase/firestore';
import './Gallery.css'
import ReactPlayer from 'react-player'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import ThumbError from '../img/videoErrorThumb.png'

import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';


function Gallery(props) {

    const [word, setWord] = useState('Moin')


    const toggleState = () => {
        if(word === 'Moin'){
            setWord('Fred')
        } else {
            setWord('Moin')
        }
    }

    const transition = useTransition(word, {
        from:{opacity: 0},
        enter: {opacity: 1}
    })

    function nextImage(){
        var index = props.images.findIndex(object => {
            return object.url === props.selectedImages.url
        })
        
        //check index 
        if(index + 1 > props.images.length - 1){
            index = 0
        } else {
            index += 1
        }

        props.setSelectedImages(props.images[index])
    }

    function prevImage(){
        var index = props.images.findIndex(object => {
            return object.url === props.selectedImages.url
        })
        
        //check index 
        if(index - 1 < 0){
            index = props.images.length - 1
        } else {
            index -= 1
        }

        props.setSelectedImages(props.images[index])
    }
    

    return (
        <div className="Gallery">

            {/** 
            {transition((style, item) => 
                <animated.div style={style}>{word}</animated.div>
            )}
            <button onClick={() => toggleState()}>Klicks</button>

            */}

            <div className="Gallery_PreviewContainer">
                {props.selectedImages.type === "img" && <img src={props.selectedImages.url} />}
                {props.selectedImages.type === "video" && <div className="videoPlayer" ><ReactPlayer   config={{ file: {attributes: {controlsList: 'nodownload'}}}} controls={true} url={props.selectedImages.url} playing={true} width={"100%"} height={"400px"}/></div>}
                <div className="ArrowContainer">
                    <ArrowCircleLeftIcon onClick={() => prevImage()} style={{color: "#FAB305", cursor: "pointer"}}/> 
                    <ArrowCircleRightIcon onClick={() => nextImage()} style={{color: "#FAB305", cursor: "pointer"}}/>
                </div>
                
            </div>

            <div className="Gallery_ImageContainer">
                {props.images.map((item, index) => (
                    <div onClick={(e) => props.setSelectedImages({url: e.currentTarget.id, type: e.currentTarget.getAttribute("data-type")})} id={item.url} key={item.id} data-type={item.type} className="Gallery_SmallImage">
                        {item.type === "img" ? 
                            <img src={item.url}/>
                        :
                        <div style={{position: "relative"}}>
                            <img src={item.thumb ? item.thumb : "https://firebasestorage.googleapis.com/v0/b/iventi-6d02a.appspot.com/o/system%2FvideoErrorThumb.png?alt=media&token=8a6c48aa-6ad2-4090-935d-6583aabfbaf5"}/>
                            <PlayCircleOutlineIcon style={{color: "white", position: "absolute", top: "50%", transform: "translate(-50%, -50%)", left: "50%"}} />
                        </div>

                        }
                    </div>    
                ))}
            </div>

            

        </div>
    )
}

export default Gallery