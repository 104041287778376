// React
import React, { useState } from 'react'
import { useUserContext } from '../../context/UserContext';

//CSS
import './NotdienstButton.css';

// UI Imports
import DayPickerCustomFunction from '../provider/DayPickerCustomFunction';
import { ClickAwayListener } from '@mui/material';

const NotdienstButton = props => {
    const { notdienstSelectedDays, setNotdienstSelectedDays } = useUserContext();
    const [notdienstToggle, setNotdienstToggle] = useState(false);

    const toggleNotdienst = () => {
        notdienstToggle ? setNotdienstToggle(false) : setNotdienstToggle(true);
    };

    return (
        <ClickAwayListener onClickAway={() => setNotdienstToggle(false)}>
            <div className="wrapperBtnNotfall">
                <button className={`btnNotfall ${notdienstSelectedDays.length != 0 ? "notdienstActive" : ""}`} type="button" onClick={() => toggleNotdienst()}>
                    Not&shy;dienst?
                </button>
                {notdienstToggle &&
                    <div className='customDayPickerWrapper'>
                        <h3>Bitte markiere alle Tage an denen du einen Notdienst brauchst</h3>
                        <DayPickerCustomFunction selectedDays={notdienstSelectedDays} setSelectedDays={setNotdienstSelectedDays} />
                        <button onClick={() => toggleNotdienst()}>Fertig</button>
                    </div>
                }
            </div>
        </ClickAwayListener>

    )
}

export default NotdienstButton;