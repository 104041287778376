import React, { useEffect, useState } from 'react'
import Header from '../provider/Header'
import { arrayUnion ,collection, getDocs, getDoc, doc, query, where, setDoc } from 'firebase/firestore';
import { db, auth } from '../../Firebase'

import { get } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'




import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Loader from '../Loader';


function CheckAds() {

	const navigate = useNavigate()

	const [documents, setDocuments] = useState([])
	const [loading, setLoading] = useState(true)

	async function getDocuments(){

		const posts = getDocs(query(collection(db, "posts"), where("state", "==", "check")))
		const inspos = getDocs(query(collection(db, "inspos"), where("state", "==", "check")))

		const [postsSnapshot, insposSnapshot] = await Promise.all([
			posts,
			inspos
		])

		return postsSnapshot.docs.concat(insposSnapshot.docs)

	}


	useEffect(() => {

		getDocuments().then((e) => {
			setDocuments(e)
			setLoading(false)
		})

	}, [])
	


	return (
		<>
			{loading ? <Loader /> : 

			<>
			<div className="headline">
				<h2 style={{textAlign: "center", margin: "50px 0 50px 0", fontWeight: "800"}}>INSERATE ZU PRÜFEN</h2>
			</div> 
			{documents.length === 0 ? <p style={{textAlign: "center", marginBottom: "50px"}}>Nicht zum freigeben...</p> :
			<TableContainer sx={{maxWidth: "80%", margin: "auto", marginBottom: "50px"}} component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
					<TableRow>
						<TableCell>Kunde</TableCell>
						<TableCell align="left">Titel</TableCell>
						<TableCell align="left">Kategorie</TableCell>
						<TableCell align="left">Leistung</TableCell>
						<TableCell align="left">Typ</TableCell>
					</TableRow>
					</TableHead>
					<TableBody>
					{documents.map((row) => (
						<TableRow
						key={row.id}
						id={row.id}
						sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer" }}
						onClick={(e) => navigate(`/adminAd/${e.currentTarget.id}/${row.data().cat}`)}
						>
						<TableCell component="th" scope="row">
							{row.data().userMail}
						</TableCell>
						<TableCell align="left">{row.data().title}</TableCell>
						<TableCell align="left">{row.data().servicesName}</TableCell>
						<TableCell align="left">{row.data().service}</TableCell>
						<TableCell align="left">{row.data().cat === "inspo" ? "Inspiration" : "Rubrik"}</TableCell>
						</TableRow>
					))}
					</TableBody>
				</Table>
			</TableContainer>
			}
			</>
			}

			

		</>	
	)
}

export default CheckAds