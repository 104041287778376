import React, { useRef, useState, useEffect } from 'react';
import './ProviderGallery.css'
import { storage, db } from '../../Firebase'
import { ref, getDownloadURL, uploadBytesResumable, deleteObject } from "firebase/storage";
import placeholderImage from '../../img/placeholderImage.png'
import { arrayUnion, doc, updateDoc, onSnapshot, setDoc, getDoc, arrayRemove } from "firebase/firestore";
import CircularProgress from '@mui/material/CircularProgress';
import IconUpload from '../../img/iconUpload.svg'
import ThumbError from '../../img/videoErrorThumb.png'
import VideoThumbnail from 'react-video-thumbnail'
import VideoImageThumbnail from 'react-video-thumbnail-image';
import DeleteIcon from '../../img/deletIconWhite.svg'


import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import PopUp from '../PopUp';

const userID = "test123"

const list = [
	{
		id: 1,
		name: "Fred"
	},
	{
		id: 2,
		name: "Gustav"
	},
	{
		id: 3,
		name: "Gustav"
	},
	{
		id: 4,
		name: "Gustav"
	},
	{
		id: 5,
		name: "Gustav"
	},
	{
		id: 6,
		name: "Gustav"
	},
	{
		id: 8,
		name: "Gustav"
	}
]

const test = {
	moin: "MOIN"
}

const MAX_IMAGES = 2
const MAX_VIDEOS = 2


function ProviderGallery(props) {

	const fileUploadImage = useRef()
	const fileUploadVideo = useRef()

	const [error, setError] = useState("")

	const [files, setFiles] = useState([])
	//const [urls, setUrls] = useState([])
	const [videos, setVideos] = useState([])
	const [progress, setProgress] = useState({})

	const [tempUrl, setTempUrl] = useState([])

	const [uploadVideo, setUploadVideo] = useState(false)
	const [uploadImage, setUploadImage] = useState(false)
	const [loading, setLoading] = useState(false)
	const [edit, setEdit] = useState(false)

	/*
	function handleChange(e) {
		if(e.target.files.length === 0) return
		if(e.target.files.length + props.urls.length > 18) {
			setError("Du kannst maximal 18 Bilder hochladen")
			setFiles([])
			return
		} 
		for(var i = 0; i < e.target.files.length; i++) {
			var newImage = e.target.files[i]
			if(newImage.size > 1 * 1024 * 1024){
				setError("Ein Bild darf maximal 1MB groß sein")
				setFiles([])
				return
			}
			files.push(newImage)
			setFiles([...files])
		}
		setUploadImage(true)
	}
	*/



	function handleChange(e) {
		const files = e.currentTarget.files;
		var imgCount = 0
		var videoCount = 0
		Object.keys(props.images).forEach(i => {
			if(props.images[i].type === "video"){
				videoCount += 1
			} else if(props.images[i].type === "img") {
				imgCount += 1
			}
		})
		//validation
		if(e.target.files.length === 0) return
		Object.keys(files).forEach(i => {
			if(files[i].type.match('image')){
				imgCount += 1
			} else if(files[i].type.match('video')){
				videoCount += 1
			}
		})

		if(videoCount > 2 || imgCount > 18) {
			setError("Du kannst maximal zwei Videos und 18 Bilder hochladen!")
			setFiles([])
			return
		}

		Object.keys(files).forEach(i => {

			//validation 

			const file = files[i];
			const reader = new FileReader();
			if(file.type.match('image')){
				if(file.size > 15 * 1024 * 1024){
					setError("Ein Bild darf maximal 15MB groß sein")
					setFiles([])
					return
				}
				reader.onload = (e) => {

					const imgElem = document.createElement("img")
					imgElem.src = e.target.result

					imgElem.onload = function(a) {
						const canvas = document.createElement("canvas")
						const MAX_WIDTH = 1000
						const scaleSize = MAX_WIDTH / a.target.width

						canvas.width = MAX_WIDTH
						canvas.height = a.target.height * scaleSize

						const ctx = canvas.getContext("2d")

						ctx.drawImage(a.target, 0, 0, canvas.width, canvas.height)

						ctx.canvas.toBlob(function(blob){
							props.images.push({id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15), file: blob, base: ctx.canvas.toDataURL(), type: "img", uploaded: false})
							props.setImages([...props.images])
						},'image/png');

					}

					/*

					console.log(e.target.width)

					const canvas = document.createElement("canvas")
					const MAX_WIDTH = 1000
					const scaleSize = MAX_WIDTH / e.target.width

					canvas.width = MAX_WIDTH
					canvas.height = e.target.height * scaleSize

					const ctx = canvas.getContext("2d")

					ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height)


					const srcEncoded = ctx.canvas.toBlob()

					console.log(srcEncoded)
					*/

	

					
				}
				reader.readAsDataURL(file);
			} else {
				if(file.size > 50 * 1024 * 1024){
					setError("Ein Video darf maximal 50MB groß sein")
					setFiles([])
					return
				}
				reader.onload = function() {
					var blob = new Blob([reader.result], {type: file.type});
					var url = URL.createObjectURL(blob);
					var video = document.createElement('video');
					var timeupdate = function() {
					  if (snapImage()) {
						video.removeEventListener('timeupdate', timeupdate);
						video.pause();
					  }
					};
					video.addEventListener('loadeddata', function() {
					  if (snapImage()) {
						video.removeEventListener('timeupdate', timeupdate);
					  }
					});
					var snapImage = function() {
						var canvas = document.createElement('canvas');

						canvas.width = video.videoWidth;
						canvas.height = video.videoHeight;
						
						canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
						var image = canvas.toDataURL();
						var success = image.length > 100000;
						if (success) {
							canvas.toBlob(function(blob){
								props.images.push({id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15), file: file, base: image, type: "video", uploaded: false, thumb: blob })
								props.setImages([...props.images])
							},'image/png')
						} else {
							//error 
						}
						
						return success;

					}
					video.addEventListener('timeupdate', timeupdate);
					video.preload = 'metadata';
					video.src = url;
					video.muted = true;
					video.playsInline = true;
					video.play();
				  }
				  reader.readAsArrayBuffer(file);
			}
		})

		
	}

	function deleteImage(e) {
		props.setImages(props.images.filter((item) => item.id !== e.currentTarget.getAttribute("data-value")))
	}

	

	function handleUploadImage(e) {
		setLoading(true)
		var promises = []
		files.map((file, index) => {
			var storageRef = ref(storage, `testCollection/img/${Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}`)
			var uploadTask = uploadBytesResumable(storageRef, file)
			promises.push(uploadTask)
			uploadTask.on("state_changed", (snapshot) => {
				//nothing						
			},
				(error) => {
					console.log(error)
				},
				async () => {
					await getDownloadURL(uploadTask.snapshot.ref).then((url) => {
						setDoc(doc(db, "posts", props.documentID), {
							img: arrayUnion({ id: uploadTask.snapshot.ref.name, url: url }),
							state: "progress",
							prem: false
						}, { merge: true }).then(() => console.log("dones"))
					})
				})
		})

		Promise.all(promises).then(() => {
			setLoading(false)
			setUploadImage(false)
			setFiles([])

		}).catch((err) => {
			console.log("error")
		})
	}

	useEffect(() => {
		/*
		onSnapshot(doc(db, "posts", props.documentID), (snapshot) => {
			if(snapshot.data()?.img){
				props.setUrls(snapshot.data().img)
			} 
		})
		*/

		setEdit(props.edit)

	}, []);

	const SortableItem = SortableElement((props) => {
		const { value: item } = props;
		return (
			<div className="content" style={{ backgroundColor: "unset", position: "relative", margin: "0 10px 10px 0" }}>
				{item.uploaded && item.type !== "video" && <img style={{ display: "block", width: "50px", height: "50px", objectFit: "cover" }} src={item.url} />}
				{item.uploaded && item.type === "video" &&  <img style={{ display: "block", width: "50px", height: "50px", objectFit: "cover" }} src={item.thumb ? item.thumb : "https://firebasestorage.googleapis.com/v0/b/iventi-6d02a.appspot.com/o/system%2FvideoErrorThumb.png?alt=media&token=8a6c48aa-6ad2-4090-935d-6583aabfbaf5"} />}
				{!item.uploaded && <img style={{ display: "block", width: "50px", height: "50px", objectFit: "cover" }} src={item.base} />}
				<a data-value={item.id} onClick={deleteImage} style={{ position: "absolute", top: "4px" }}>
					<img style={{width: "20px", height: "20px", display: "block"}} src={DeleteIcon} />
				</a>
				{item.type === "video" && <div style={{ position: "absolute", top: "50%", transform: "translate(-50%, -50%)", left: "50%"}}></div>}
			</div>
		);
	});

	const SortableList = SortableContainer((props) => {
		const { items, ...restProps } = props;
		return (
			<div style={{ display: 'flex', flexWrap: "wrap", marginTop: "20px" }}>
				{items.map((item, index) => (
					<SortableItem
						key={`item-${item.id}`}
						index={index}
						value={item}
						{...restProps}
					/>
				))}
			</div>
		);
	});


	const onSortEnd = ({ oldIndex, newIndex }) => {
		if (oldIndex !== newIndex) {
			props.setImages(arrayMoveImmutable(props.images, oldIndex, newIndex))
		}
	}


	return (
		<div className="ProviderGallery">

			<div className="uploadContainer">

				{/*
				{uploadImage && !loading &&
					<div className="uploadInnderContainer">
						<div className="headline">Du hast {files.length} Bilder ausgewählt.</div>
						<button style={{ fontSize: "14px", marginBottom: "10px" }} className="submitAdButton" onClick={handleUploadImage}>Hochladen</button>
					</div>
				}

				{loading &&
					<div className="uploadInnderContainer">
						<div className="headline">Du hast {files.length} Bilder ausgewählt.</div>
						<button style={{ marginBottom: "10px" }} className="loadingButton"><CircularProgress style={{ color: "white", width: "27px", height: "27px" }} /></button>
					</div>
				}
				*/}

				{!uploadVideo && !uploadImage && !loading &&
					<div className="uploadInnderContainer">
						<div className="headlineProv">BILDER-UPLOAD</div>
						{props.type === "zero" ?
							<>
								{props.images.length > 0 ? <div className="text">Du kannst kein weiteres Bild hochladen</div> :
									<div className="text">Hier kannst du ein Bild hochladen.</div>
								}

							</>
							:
							<div className="text">Hier kannst du bis zu 18 Bilder hochladen -<br /> mehrere gleichzeitig über den gelben Button.</div>
						}
						{props.type === "zero" ?
							<>
								{props.images.length === 0 && <button style={{ fontSize: "14px", marginBottom: "10px" }} className="submitAdButton" onClick={() => fileUploadImage.current.click()} >AUSWÄHLEN</button>}
							</>
							:
							<button style={{ fontSize: "14px", marginBottom: "10px" }} className="submitAdButton" onClick={() => fileUploadImage.current.click()} >AUSWÄHLEN</button>
						}

						<div className="text">Empfohlenes Mindestformat für Bilder: 1000x1000 Pixel<br /> und maximal 15 Megabyte</div>
						{props.type !== "zero" && <div className="text">Die Reihenfolge der Bilder kannst du anschließend unten in der Vorschau per Drag & Drop festlegen. Das erste Bild ist immer dein Produktbild in der Kleindarstellung deines Inserates bei den Suchergebnissen</div>}
						{/* <button>Videos auswählen</button> */}
					</div>
				}

				{error &&
					<PopUp message={error} setError={setError} />
				}


			</div>

			{props.images.length === 0 &&
				<div className="imagePlaceholderContainer">
					<img src={IconUpload} />
					<img src={IconUpload} />
				</div>
			}

			<input multiple className="hiddenInput" onChange={handleChange} ref={fileUploadImage} type="file" accept="video/mp4,image/png,image/jpeg" />
			{props.images.length > 0 &&
				<>
					{/*<div style={{marginTop: "10px", fontWeight: "700", textTransform: "uppercase"}}>Deine Bilder</div>*/}
					<SortableList distance={1} axis="xy" items={props.images} onSortEnd={onSortEnd} />
				</>
			}

		</div>
	)
}

export default React.memo(ProviderGallery);
