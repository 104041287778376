// React
import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useUserContext } from '../../context/UserContext';

//Moment
import moment from 'moment';

// CSS
import './KategorieAnzeigen.css';

// Firebase
import { db } from '../../Firebase'
import { getDoc, doc, getDocs, collection, query, where } from 'firebase/firestore';
import { Timestamp } from 'firebase/firestore';


//UI Imports
import TopShapeWeiss from '../../img/TrennerObenWeiß.png'
import Header from '../ui/Header';
import CustomIcon from '../ui/CustomIcon';
import Loader from '../Loader';
import GratisAnfragePopUp from '../ui/GratisAnfragePopup';

//Icons and Images
import imgKeineErgebnisse from '../../img/Icon_Lupe_keine_Suchergebnisse.svg';
import imgSchiff from '../../img/schiff.jpg';
import iconLocationPin from '../../img/Icon_Staedte_Map_Pin_Grosz.svg';
import iconAusrufezeichen from '../../img/Icon_i_Info_Anbieter_Merkmal.svg';
import iconMerkliste from '../../img/Icon_Favorit_Verlauf.svg';
import iconNotdienst from '../../img/Icon_Notdienst_Verlauf.svg';
import iconNotdienstAktiv from '../../img/Icon_Notdienst_Aktiv.png';
import iconKontakt from '../../img/Icon_Nachricht_schreiben.svg';
import Trenner from '../ui/Trenner';
import PopUpMerkliste from './PopUpMerkliste';

function KategorieAnzeigen() {
    const { id, childId } = useParams();
    const navigate = useNavigate();
    const { locationContext } = useUserContext()
    const { notdienstSelectedDays } = useUserContext();


    const [loadingSubcategories, setloadingSubcategories] = useState(false);
    const [subcategories, setSubcategories] = useState([]);

    const [currentSubcategory, setCurrentSubcategory] = useState("");

    const [loadingResults, setloadingResults] = useState(false);
    const [results, setResults] = useState([]);
    const [merkliste, setMerkliste] = useState(false);
    const [merklisteId, setMerklisteId] = useState();

    const [reload, setReload] = useState(false)

    const [contactPopupVisible, setContactPopupVisible] = useState(false);

    const [inseratData, setInseratData] = useState();

    // Handle subcategory fetch
    useEffect(() => {
        setloadingSubcategories(true);
        //getDoc(doc(db, 'categories', id)).then((doc) => {
        getDocs(query(collection(db, "categories"), where("name","==",id))).then((doc) => {
            var subCategoriesSorted = Object.keys(doc.docs[0].data().services);
            subCategoriesSorted.sort(Intl.Collator().compare);
            setSubcategories(subCategoriesSorted);
            setCurrentSubcategory(subcategories[0]);
            setloadingSubcategories(false);
        })
    }, []);

    // Handle result query and fetch
    useEffect(() => {

        //console.log(id)

        setloadingResults(true);
        results.length = 0;
        const postsRef = collection(db, "posts");
        let posts;
        if (locationContext == 0 && notdienstSelectedDays.length == 0) {
            // Location nicht gesetzt, Notdienst nicht gesetzt
            posts = query(postsRef, where("servicesName", "==", id), where("service", "==", childId), where("state", "==", "running"));
        } else if (locationContext != 0 && notdienstSelectedDays.length == 0) {
            // Location gesetzt, Notdienst nicht gesetzt
            posts = query(postsRef, where("servicesName", "==", id),where("service", "==", childId), where("city", "==", locationContext), where("state", "==", "running"));
        } else if (locationContext == 0 && notdienstSelectedDays.length > 0) {
            // Location nicht gesetzt, Notdienst gesetzt
            posts = query(postsRef, where("servicesName", "==", id),where("service", "==", childId), where("selectedDays", "array-contains-any", notdienstSelectedDays), where("state", "==", "running"));
        } else if (locationContext != 0 && notdienstSelectedDays.length > 0) {
            // Location gesetzt, Notdienst gesetzt
            posts = query(postsRef, where("servicesName", "==", id),where("service", "==", childId), where("city", "==", locationContext), where("selectedDays", "array-contains-any", notdienstSelectedDays), where("state", "==", "running"));
        }

        getDocs(posts).then((docs) => {
            var tempArray = []
            docs.forEach((doc) => {
                tempArray.push(doc);
            })

            //random sort
            tempArray = tempArray.sort((a, b) => 0.5 - Math.random())

            //sort by zero
            
			tempArray.sort(function(a, b) {
				var nameA = a.data().type.toUpperCase() // ignore upper and lowercase
				var nameB = b.data().type.toUpperCase() // ignore upper and lowercase
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}
				// names must be equal
				return 0;
			})

            setResults(tempArray);
            setloadingResults(false);
        })
    }, [childId, locationContext, notdienstSelectedDays]);

    function checkEmergency(data){
		var temp = []
		notdienstSelectedDays.forEach((time) => {
			var date = new Date(time)
			date = String(date.getTime()).slice(0, -3)
			temp.push(parseInt(date))
		})

        if(notdienstSelectedDays.length !== 0){
            if(data.data().selectedDays.some(e => temp.includes(e.seconds))){
                return true
            } 
        } 

        return false
        
    }
    

    return (
        <div className="kategorienAnzeigen">

            {merkliste && <PopUpMerkliste adType={0} type={0} id={merklisteId} setMerkliste={setMerkliste}/> }
            
            {contactPopupVisible && <GratisAnfragePopUp setPopupVisible={setContactPopupVisible} inseratData={inseratData} />}
            <Header className="backgroundAnimation" nav="scroll">
                <h2>{id}</h2>
                <h3>{locationContext ? locationContext : "Deutschland"}</h3>
            </Header>
            <Trenner shape="small" foreground="white" background="animated" />
            <main>
                <section className="unterkategorienNav">
                    <h2>Wonach suchst du?</h2>
                    {loadingSubcategories ? <Loader /> :
                        <div className="unterkategorien">
                            {subcategories.map((name, index) => (
                                // Index als Key nehmen ist AntiPattern. Bessere Lösung suchen wie z. B. Key generieren mit NanoID
                                <button className={childId === name ? "active" : ""} onClick={() => navigate(`/kategorie-anzeigen/${id}/${name}`)} key={index}>{name}</button>
                            )
                            )}
                        </div>
                    }
                </section>
                <section className="ergebnisseWrapper">
                    {loadingResults ? <>{loadingSubcategories ? null : <Loader />}</> :
                        <>
                            {results.length !== 0 ?
                                <>
                                    <div className='headline'>
                                        <h3>{currentSubcategory}</h3>
                                    </div>
                                    <div className='ergebnisse'>
                                        {results.map((data, key) => (
                                            <div key={key}>
                                                <div className='top'>
                                                    <img src={data.data().img[0].url} alt="" />
                                                    <div>
                                                        <h4>{data.data().title}</h4>
                                                        <ul>
                                                            <li><CustomIcon image={iconAusrufezeichen} imageAlt="Location Pin" />{data.data().servicesName}</li>
                                                            <li><CustomIcon image={iconAusrufezeichen} imageAlt="Merkmal" />{data.data().serviceQuick}</li>
                                                            <li><CustomIcon image={iconAusrufezeichen} imageAlt="Merkmal" />{data.data().nameQuick}</li>
                                                            <li><CustomIcon image={iconLocationPin} imageAlt="Merkmal" />{data.data().address}{data.data().address && (data.data().zip || data.data().location) ? ", " : ""}{data.data().zip}{data.data().zip ? " " : ""}{data.data().location}</li>
                                                        </ul>
                                                        {(data.data().type === "flat" || data.data().type === "plus") && <button className="buttonSmallAd" id={data.id} onClick={(e) => navigate(`/adminAd/${e.currentTarget.id}/post`)}>Mehr Infos und Bildergalerie</button>}
                                                    </div>
                                                </div>
                                                
                                                <button className='btnMerkliste' style={{borderRadius: "0px"}} onClick={() => {
                                                    setMerklisteId(data.id)
                                                    setMerkliste(true)
                                                }}>
                                                    <CustomIcon image={iconMerkliste} imageAlt="Merkliste" dimensions={{ width: '20px'}} />Auf Merkliste setzen
                                                </button>
                                                
                                                <button className='btnNotdienst'><CustomIcon image={checkEmergency(data) ? iconNotdienstAktiv : iconNotdienst} imageAlt="Notdienst" dimensions={{ width: '25px' }} />{checkEmergency(data) ? "Notdienst aktiv" : "Aktuell kein Notdienst"}</button>
                                                <button className='btnAnfrage' onClick={() => {
                                                    setContactPopupVisible(true);
                                                    setInseratData({ anbieterName: data.data().nameQuick, inseratTitel: data.data().title, email: data.data().userMail });
                                                }}>
                                                    <div>
                                                        <span>Gratis Anfrage </span>
                                                        Mit diseser Anfrage trittst du direkt mit dem Anbieter in Kontakt. iVenti ist an dieser Kommunikation nicht beteiligt und speichert sie nicht.
                                                    </div>
                                                    <CustomIcon image={iconKontakt} imageAlt="Kontaktieren" dimensions={{ width: '35px' }} />
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </>
                                :
                                <>
                                {childId !== "leer" &&
                                    <div className='keineErgebnisse'>   
                                        <img src={imgKeineErgebnisse} alt="Keine Ergebnisse!" />
                                        <p>Noch keine Suchergebnisse!<br />
                                            Bitte wähle eine Kategorie</p>
                                    </div>
                                }
                                </>
                                }
                                {/** 
                                <div style={{padding: "30px 10px 30px 10px", borderRadius: "10px"}} className='keineErgebnisse backgroundAnimation'>   
                                    <p style={{color: "white", fontSize: "20px", fontWeight: "700"}}>Wähle eine Kategorie und wir finden passende Inserate für dich.</p>
                                </div>
                                */}
                        </>
                    }
                </section>
            </main>
        </div>
    )
}

export default KategorieAnzeigen;