import React, { useState, useEffect, useRef } from 'react';
import './CreateAd.css';
import { db, storage } from '../../Firebase'
import { arrayUnion ,collection, getDocs, getDoc, doc, query, where, setDoc } from 'firebase/firestore';
import Checkbox from '@material-ui/core/Checkbox';
import Loader from '../Loader';
import ProviderGallery from './ProviderGallery';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CelebrationIcon from '@mui/icons-material/Celebration';
import CircularProgress from '@mui/material/CircularProgress';
import InfoIcon from '@mui/icons-material/Info';
import { ref, getDownloadURL, uploadBytesResumable, deleteObject, uploadBytes } from "firebase/storage";
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import PopUp from '../PopUp';
import DayPickerCustom from './DayPickerCustom';
import DayPickerCustomFunction from './DayPickerCustomFunction';
import { useUserContext } from '../../context/UserContext';
import CustomSelect from './CustomSelect';
import CustomLogicSelect from './CustomLogicSelect';
import MenuItem from '@mui/material/MenuItem';
import TopShape from '../../img/TrennerObenWeißSmall.svg'
import SmallAd from './SmallAd';

//Icons and Images
import iconLocationPin from '../../img/Icon_Staedte_Map_Pin_Grosz.svg';
import iconAusrufezeichen from '../../img/Icon_i_Info_Anbieter_Merkmal.svg';
import iconNotdienst from '../../img/Icon_Notdienst_Verlauf.svg';
import iconNotdienstAktiv from '../../img/Icon_Notdienst_Aktiv.png';
import iconKontakt from '../../img/Icon_Nachricht_schreiben.svg';
import iconMerkliste from '../../img/Icon_Favorit_Verlauf.svg';

//UI Imports
import CustomIcon from '../ui/CustomIcon';

//allow read, write: if request.auth.uid == resource.data.uid;

const userID = "test123"

function CreateInspo() {

	const { user, cartCount, setCartCount } = useUserContext()

	const categorieRef = useRef()

	const navigate = useNavigate()

	const zeroRef = useRef()
	const plusRef = useRef()

	const [loading, setLoading] = useState(true)
	const [loadingSubmit, setLoadingSubmit] = useState(false)
	const [categories, setCategories] = useState([])
	const [services, setServices] = useState(0)
	const [service, setService] = useState(0)
	const [servicesName, setServicesName] = useState("")
	const [serviceSelect, setServiceSelect] = useState(0)
	const [collectionId, setCollectionId] = useState(null)
	const [keywords, setKeywords] = useState({})
	const [documentID, setDocumentID] = useState(Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15))
	const [type, setType] = useState("plus")
	const [error, setError] = useState("")
	const [urls , setUrls] = useState([])
	const [edit , setEdit] = useState(false)
	const [selectedDays , setSelectedDays] = useState([])
	const [isFirst, setIsFirst] = useState()
	const [isZero, setIsZero] = useState()
	const [isFlat, setIsFlat] = useState()
	const [state, setState] = useState("")

	const [choosen, setChoosen] = useState([])
	const [selectedAd, setSelectedAd] = useState()

	//QuickInfo
	const [serviceQuick, setServiceQuick] = useState(0)
	const [nameQuick, setNameQuick] = useState("")

	const [alarm, setAlarm] = useState(false)

	const [userFlat, setUserFlat] = useState()

	const [start, setStart] = useState("")
	const [end, setEnd] = useState("")

	
	const [title, setTitle] = useState("")
	const [address, setAddress] = useState("")
	const [location, setLocation] = useState("")
	const [zip, setZip] = useState("")
	const [radius, setRadius] = useState(10)
	const [city, setCity] = useState(0)
	const [headline, setHeadline] = useState("")
	const [headline2, setHeadline2] = useState("")
	const [text, setText] = useState("")
	const [text2, setText2] = useState("")

	const [categorieSelect, setCategorySelect] = useState(0)

	const [images, setImages] = useState([])
	const [imagesUpload, setImagesUpload] = useState([])

	const [saveAdress, setSaveAdress] = useState(false)


	//validation
	const [errorTitle, setErrorTitle] = useState(false)
	const [errorService, setErrorService] = useState(false)
	const [errorName, setErrorName] = useState(false)
	const [errorZip, setErrorZip] = useState(false)
	const [errorLocation, setErrorLocation] = useState(false)
	const [errorLocationSelect, setErrorLocationSelect] = useState(false)
	const [errorHeadline, setErrorHeadline] = useState(false)
	const [errorText, setErrorText] = useState(false)

	//const [oldImages, setOldImages] = useState([])

	/*
	useEffect(() => {
		if(type === "zero"){
			setKeywords([])
			setHeadline(null)
			setHeadline2(null)
			setText(null)
			setText2(null)
		}
	}, [type]);
	*/

	/*
	function saveToDb(){
		setImagesUpload([])
		var promises = []
		images.map((item, index) => {
			if(item.uploaded){
				
			} else {
			if(item.type === "img"){
				var storageRef = ref(storage, `userData/${user.uid}/img/${Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}`)
			} else if(item.type === "video"){
				var storageRef = ref(storage, `userData/${user.uid}/video/${Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}`)
			}
			var uploadTask = uploadBytesResumable(storageRef, item.file)
			promises.push(uploadTask)
			uploadTask.on("state_changed", (snapshot) => {
				//nothing
				console.log("upload")						
			},
				(error) => {
					console.log(error)
				},
				async () => {
					await getDownloadURL(uploadTask.snapshot.ref).then((url) => {
						setDoc(doc(db, "posts", documentID), {
							img: arrayUnion({ id: uploadTask.snapshot.ref.name, url: url, type: item.type, upoaded: true }),
							state: "progress",
							prem: false
						}, { merge: true }).then(() => console.log("dones"))
					})
				})
			}
		})
		Promise.all(promises).then(() => {
			console.log("done all")
		}).catch((err) => {
			console.log("error")
		})
	}
	*/

	function handlePlan(value){
		if(value === "zero"){
			if(images.length > 1){
				setError("Du kannst nur ein Bild in iVenti Zero benutzen. Lösche deine Bilder und versuche es erneut.")
			} else if(images[0]?.type === "video"){
				setError("Du kannst nur ein Bild in iVenti Zero benutzen. Lösche dein Video und versuche es erneut.")
			} else {
				setKeywords([])
				setHeadline("")
				setHeadline2("")
				setText("")
				setText2("")
				setType("zero")
			}
		} else {
			setType(value)
		}
	}
	

	const { id } = useParams()

	function handleCheckbox(value){
		if(value.target.checked){
			if(keywords[value.target.getAttribute("data-group")]) {
				keywords[value.target.getAttribute("data-group")].push(value.target.value)
			} else {
				keywords[value.target.getAttribute("data-group")] = [value.target.value]
	
			}
		} else {
			keywords[value.target.getAttribute("data-group")].splice(keywords[value.target.getAttribute("data-group")].indexOf(value.target.value), 1)
			if(keywords[value.target.getAttribute("data-group")].length === 0){
				delete keywords[value.target.getAttribute("data-group")]
			}
		}
		setKeywords({...keywords})
	}
	
	async function runProgress(value) {
		if(value.state === 1 && value.data !== "0"){
			setServiceSelect(0)
			setService(0)
			const querySnapshot =  await getDoc(doc(db, "inspirations", value.data))

			var arraySort = querySnapshot.data().services

			arraySort.sort(function(a, b) {
				var nameA = a.toUpperCase() // ignore upper and lowercase
				var nameB = b.toUpperCase() // ignore upper and lowercase
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}
				// names must be equal
				return 0;
			})

			setServices(arraySort)
			setServicesName(querySnapshot.data().name)	
		
		} 

		if(value.state === 1 && value.data === "0"){
			setServiceSelect(0)
			setService(0)
			setServices(0)
			setServicesName("")
			setCollectionId(0)		
		} 

		if(value.state === 2 && value.data !== "0"){
			setServiceSelect(value.data)
			//const querySnapshot =  await getDoc(doc(db, "categories", collectionId))
			//setService(querySnapshot.data().services[value.data])
		} 

		if(value.state === 2 && value.data === "0"){
			setServiceSelect(0)
			setService(0)
		} 
	}

	/*
	function saveAd() {
		setLoadingSubmit(true)
		if(type !== "zero"){
			if(address === "" || location === "" || title === "" || images.length === 0 || headline === "" || text === ""){
				setError("Bitte alle Pflichtfelder ausfüllen und ein Bild hochladen.")
				setLoadingSubmit(false)
				return
			}
		}
		var promises = []
		images.map((item, index) => {
			if(item.uploaded){
				if(index === 0){
					setDoc(doc(db, "posts", documentID), {
						services: collectionId,
						servicesName: servicesName,
						service: serviceSelect,
						address: address,
						location: location,
						keywords: keywords,
						title: title,
						state: "cart",
						radius: radius,
						headline: headline,
						text: text,
						headline2: headline2,
						text2: text2,
						type: type,
						img: [{ id: item.id, url: item.url, type: item.type, uploaded: true }],
						prem: false
					}).then(() => console.log("dones"))
				} else {
					setDoc(doc(db, "posts", documentID), {
						img: arrayUnion({ id: item.id, url: item.url, type: item.type, uploaded: true }),
						state: "progress",
						prem: false
					}, { merge: true }).then(() => console.log("dones"))
				}
			} else {

				//CLEAR DB AND SAVE NEW ORDER IN IMAGE, ALSO ERSTMAL WERT AUF NULL UND DANN WEITER
				if(item.type === "img"){
					var storageRef = ref(storage, `testCollection/img/${Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}`)
				} else if(item.type === "video"){
					var storageRef = ref(storage, `testCollection/video/${Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}`)
				}
				var uploadTask = uploadBytesResumable(storageRef, item.file)
				promises.push(uploadTask)
				uploadTask.on("state_changed", (snapshot) => {
					//nothing
					console.log("upload")						
				},
					(error) => {
						console.log(error)
					},
					async () => {
						await getDownloadURL(uploadTask.snapshot.ref).then((url) => {
							if(index === 0){
								setDoc(doc(db, "posts", documentID), {
									services: collectionId,
									servicesName: servicesName,
									service: serviceSelect,
									address: address,
									location: location,
									keywords: keywords,
									title: title,
									state: "cart",
									radius: radius,
									headline: headline,
									text: text,
									headline2: headline2,
									text2: text2,
									type: type,
									img: arrayUnion({ id: uploadTask.snapshot.ref.name, url: url, type: item.type, uploaded: true }),
									state: "progress",
									prem: false
								}, { merge: true }).then(() => console.log("dones"))
							} else {
								setDoc(doc(db, "posts", documentID), {
									img: arrayUnion({ id: uploadTask.snapshot.ref.name, url: url, type: item.type, uploaded: true }),
									state: "progress",
									prem: false
								}, { merge: true }).then(() => console.log("dones"))
							}
						})
					})			
				}
		})

		Promise.all(promises).then(() => {
			console.log("done all")
			setLoadingSubmit(false)
		}).catch((err) => {
			console.log("error")
		})

		/* OLD
		setDoc(doc(db, "posts", documentID), {
			services: collectionId,
			servicesName: servicesName,
			service: serviceSelect,
			address: address,
			location: location,
			keywords: keywords,
			title: title,
			state: "cart",
			radius: radius,
			headline: headline,
			text: text,
			headline2: headline2,
			text2: text2,
			type: type
		}, { merge: true }).then(() => {
			navigate("/cart")
		}).catch(() => {
			setLoadingSubmit(false)
			setError("Es ist ein Fehler aufgetreten")
		})
		 
	}*/

	function uploadImage(item, index, imagesToUpload){
		if(item.type === "img"){
			var storageRef = ref(storage, `userData/${user.uid}/img/${Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}`)
		} else if(item.type === "video"){
			var storageRef = ref(storage, `userData/${user.uid}/video/${Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}`)
		}
		return uploadBytes(storageRef, item.file).then((snapshot) => {
			return getDownloadURL(snapshot.ref).then((url) => {
				imagesToUpload.push({order: index, item: { id: snapshot.ref.name, url: url, type: item.type, uploaded: true }})
			})
		})
	}

	function saveAd() {
		setLoadingSubmit(true)
		var imagesToUpload = []
		if(type !== "zero"){
			if(title === "" || images.length === 0 || headline === "" || text === "" || nameQuick === "" || zip === "" || city === 0){
				validation()
				setError("Bitte alle Pflichtfelder ausfüllen und ein Bild hochladen.")
				setLoadingSubmit(false)
				return
			}
			else if(images[0].type === "video"){
				setError("An Position 1 muss ein Bild sein.")
				setLoadingSubmit(false)
				return
			}
		}

		if(type === "zero"){
			if(title === "" || images.length === 0 || nameQuick === "" || zip === "" || city === 0){
				validation()
				setError("Bitte alle Pflichtfelder ausfüllen und ein Bild hochladen.")
				setLoadingSubmit(false)
				return
			}
			else if(images[0].type === "video"){
				setError("An Position 1 muss ein Bild sein.")
				setLoadingSubmit(false)
				return
			}
		}
		

		var promises = []
		images.map((item, index) => {
			if(item.uploaded){
				imagesToUpload.push({order: index, item: item})
			} else {
				promises.push(uploadImage(item, index, imagesToUpload))	
			}
		})

		Promise.all(promises).then(() => {
			imagesToUpload.sort((a, b) => {
				return a.order - b.order
			})

			var tempArray = []

			imagesToUpload.forEach((elem) => {
				tempArray.push(elem.item)
			})

			setDoc(doc(db, "inspos", documentID), {
				services: collectionId,
				servicesName: servicesName,
				service: serviceSelect,
				address: address,
				location: location,
				keywords: keywords,
				title: title,
				state: type === "zero" || state === "running" || userFlat ? "check" : "cart",
				radius: radius,
				headline: headline,
				text: text,
				headline2: headline2,
				text2: text2,
				type: userFlat ? "flat" : type,
                cat: "inspo",
				img: tempArray,
				selectedDays: selectedDays,
				serviceQuick: serviceQuick,
				nameQuick: nameQuick,
				zip: zip,
				radius: radius,
				city: city,
				alarm: alarm,
				userMail: user.email,
				uid: user.uid,
				start: start ? start : "",
				end: end ? end: "",
				prem: userFlat ? true : false,
				parent: selectedAd
			}).then(() => {

				if(saveAdress) {
					setDoc(doc(db, "userData", user.uid), {
						address: address,
						zip: zip,
						location: location,
						company: nameQuick
					}, {merge: true})
				}
				
				if(type === "zero" || userFlat) {
					navigate("/dashboard/adsProfile")
				} else {
					setCartCount(cartCount + 1)
					navigate("/dashboard/cart")
				}
				
				setLoadingSubmit(false)
			}).catch((err) => {
				setLoadingSubmit(false)
				setError("Es ist ein Fehler aufgetreten")
			})
		}).catch((err) => {
			setLoadingSubmit(false)
			setError("Es ist ein Fehler aufgetreten")
			//console.log("error " , err)
		})

	}

	/*
    function getUserData(id){
		getDoc(doc(db, "posts", id)).then((data) => {
			getDoc(doc(db, "categories", data.data().services)).then((innerData) => {
				setService(innerData.data().services[data.data().service])
				setTitle(data.data().title)
				setServicesName(innerData.data().name)
				setCollectionId(innerData.id)
				setServiceSelect(data.data().service)
				setType(data.data().type)
				setAddress(data.data().address)
				setLocation(data.data().location)
				setRadius(data.data().radius)
				setHeadline(data.data().headline)
				setText(data.data().text)
				setHeadline2(data.data().headline2)
				setText2(data.data().text)
				setKeywords(data.data().keywords ? data.data().keywords : [])
				setImages(data.data().img)
				setNameQuick(data.data().nameQuick)
				setZip(data.data().zip)
				setServiceQuick(data.data().serviceQuick)
				setCity(data.data().city)
				setState(data.data().state)
				setAlarm(data.data().alarm)

				var tempDaysArray = []
				data.data().selectedDays.forEach((item) => {
					tempDaysArray.push(item.toDate())
				})
				setSelectedDays(tempDaysArray)
				setLoading(false)
			})
		})
	}
	*/

	async function getAds(){

		var returnObject = {}

		const q = query(collection(db, "posts"), where("state", "==", "cart"), where("uid", "==", user.uid))
		const docs = getDocs(q)
		const docs2 = getDocs(query(collection(db, "posts"), where("prem", "==", true), where("uid", "==", user.uid)))
		const docs3 = getDocs(query(collection(db, "posts"), where("type", "==", "zero"), where("uid", "==", user.uid)))
		const docs4 = getDocs(query(collection(db, "posts"), where("type", "==", "flat"), where("uid", "==", user.uid)))
		const docs5 = getDocs(query(collection(db, "inspos"), where("type", "==", "flat"), where("uid", "==", user.uid)))

		const [docsSnapshot, docs2Snapshot, docs3Snapshot, docs4Snapshot, docs5Snapshot] = await Promise.all([
			docs,
			docs2,
			docs3,
			docs4,
			docs5
		])

		if(docsSnapshot.size > 0 || docs2Snapshot.size > 0){
			returnObject["first"] = true
		} else {
			returnObject["first"] = false
		}

		if(docs3Snapshot.size > 0){
			returnObject["zero"] = true
		} else {
			returnObject["zero"] = false
		}

		if(docs4Snapshot.size > 0 || docs5Snapshot.size > 0){
			returnObject["flat"] = true
		} else {
			returnObject["flat"] = false
		}

		return returnObject


	}


	async function getAdsToChoose(){
		const docs = getDocs(query(collection(db, "posts"), where("state", "==", "running"), where("uid", "==", user.uid), where("type", "==" ,"plus")))
		//const docs2 = getDocs(query(collection(db, "posts"), where("state", "==", "check"), where("uid", "==", user.uid), where("type", "==" ,"plus")))
		const docs3 = getDocs(query(collection(db, "posts"), where("state", "==", "cart"), where("uid", "==", user.uid), where("type", "==" ,"plus")))
		const docs4 = getDocs(query(collection(db, "posts"), where("state", "==", "cart"), where("uid", "==", user.uid), where("type", "==" ,"flat")))
		//const docs5 = getDocs(query(collection(db, "posts"), where("state", "==", "check"), where("uid", "==", user.uid), where("type", "==" ,"flat")))
		const docs6 = getDocs(query(collection(db, "posts"), where("state", "==", "running"), where("uid", "==", user.uid), where("type", "==" ,"flat")))

		var [docsSnapshot, docs3Snapshot, docs4Snapshot, docs6Snapshot] = await Promise.all([
			docs,
			docs3,
			docs4,
			docs6
		])

		//return docsSnapshot.docs.concat(docs2Snapshot.docs.concat(docs3Snapshot.docs.concat(docs4Snapshot.docs)))

		return [...docsSnapshot.docs, ...docs3Snapshot.docs, ...docs4Snapshot.docs, ...docs6Snapshot.docs]
	}

	function validation(){
		//reset
		setErrorTitle(false)
		setErrorService(false)
		setErrorName(false)
		setErrorZip(false)
		setErrorLocation(false)
		setErrorLocationSelect(false)
		setErrorHeadline(false)
		setErrorText(false)

		if(title === "") setErrorTitle(true)
		if(serviceQuick === 0) setErrorService(true)
		if(nameQuick === "") setErrorName(true)
		if(zip === "") setErrorZip(true)
		if(location === "") setErrorLocation(true)
		if(city === 0) setErrorLocationSelect(true)
		if(headline === "") setErrorHeadline(true)
		if(text === "") setErrorText(true)
	}


	console.log(userFlat)

	useEffect(() => {
		if(!user) return
		setLoading(true)
		if(id){
			setDocumentID(id)
			getUserData(id)
			setEdit(true)
		} else {

			var sortArray = []

			getDocs(collection(db, "inspirations")).then((docs) => {
				docs.forEach((doc) => {
					//categories.push({id: doc.id, name: doc.data().name})
					sortArray.push({id: doc.id, name: doc.data().name})
				})

				sortArray.sort(function(a, b) {
					var nameA = a.name.toUpperCase() // ignore upper and lowercase
					var nameB = b.name.toUpperCase() // ignore upper and lowercase
					if (nameA < nameB) {
						return -1;
					}
					if (nameA > nameB) {
						return 1;
					}
					// names must be equal
					return 0;
				})

				

				setCategories(sortArray)
				
				


				//setCategories([...categories])
                //console.log(categories)

				getAds().then((e) => {
					setIsFirst(e.first)
					setIsZero(e.zero)
					setIsFlat(e.flat)
					

					getDoc(doc(db, "userData", user.uid)).then((doc) => {
						setUserFlat(doc.data().flat)
						setStart(doc.data().start)
						setEnd(doc.data().end)

						getAdsToChoose().then((items) => {
							items.forEach((innerItem) => {
								choosen.push(innerItem)
							})
							setChoosen([...choosen])
							setLoading(false)
						})

					}).catch((err) => {
						console.log("error", err)
					})

				}).catch(() => {
					//nothing
				})
			}).catch(() => {
				//nothing
			})
		}
	}, []);


	function getUserData(id){
		setLoading(true)
		getDoc(doc(db, "posts", id)).then((data) => {
            setTitle(data.data().title)
            //setServiceSelect(data.data().service)
            setServicesName(data.data().servicesName)
            setType(data.data().type)
            setAddress(data.data().address)
            setLocation(data.data().location)
            setRadius(data.data().radius)
            setHeadline(data.data().headline)
            setText(data.data().text)
            setHeadline2(data.data().headline2)
            setText2(data.data().text)
            setKeywords(data.data().keywords ? data.data().keywords : [])
            setImages(data.data().img)
            setNameQuick(data.data().nameQuick)
            setZip(data.data().zip)
            setServiceQuick(data.data().serviceQuick)
            setCity(data.data().city)
            //setState(data.data().state)
            setAlarm(data.data().alarm)

            var tempDaysArray = []
            data.data().selectedDays.forEach((item) => {
                tempDaysArray.push(item.toDate())
            })
            setSelectedDays(tempDaysArray)
			setSelectedAd(id)
            setLoading(false)
		})
	}

	const menuItemStyle = {
		fontFamily: 'Spartan, sans-serif !important',
		fontSize:'14px !important',
		['&.Mui-selected']:{
			background:'rgba(249, 179, 0, .3) !important'
		},
		['&:hover']:{
			background:'rgba(249, 179, 0, .3) !important'
		}
	};


	return (
		<>	{!user.emailVerified || choosen.length === 0 ?  
			<>
			{loading ? <Loader /> :
				<div className="headline">
					<h2 style={{textAlign: "center", margin: "50px 0 50px 0", fontWeight: "800"}}>{user.emailVerified ? "KEINE INSERATE VERFÜGBAR" : "BESTÄTIGE DEINE E-MAIL-ADRESSE"}</h2>
				</div> 
			}
			</>
			: 
			<>
			{!user && <Navigate to="/login"/>}
			{error && <PopUp message={error} setError={setError} />}
			{loading ? <Loader />
			:
			<>
			<div className="CreateAd">
				{/* 
				<div className="headline">
					{id ? <h2>Inserat bearbeiten</h2> : <h2>Neues Inserat IN »INSPIRATION« anlegen</h2>}
				</div>
				*/}
				{(!userFlat && selectedAd) && 
				<div className="productChoose">
					{!isFlat && 
					<a className={"checkboxSwitch" + (type === "plus" ? " activePlan" : "")} onClick={() => handlePlan("plus")}>
					    iVenti INSPIRATION (49 €/Jahr)
					</a>
					}
					<a className={"checkboxSwitch" + (type === "flat" ? " activePlan" : "")} onClick={() => handlePlan("flat")}>
						iVenti FLAT (149 €/Jahr)
					</a>
				</div>
				}

				

				
				{/* Auswahl eines bestehenden Inserates */}

				{!selectedAd &&	
				<>
				<div style={{marginTop: "40px"}} className="headline">
					<h2>Welches Inserat möchtest du zusätzlich <br/>im Bereich »Inspiration« anzeigen?</h2>
				</div>

				{/** <p style={{textAlign: "center", maxWidth: "90%", width: "800px", margin: "0px auto 30px auto"}} >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</p>*/}
				{/** OLD 
				<div  style={{marginBottom: "50px"}} className="ap_adsContainer">
					{choosen.map((item, index) => (
						<div style={{marginTop: "20px"}} className="ap_item" key={index}>
							<div onClick={(e) => getUserData(e.currentTarget.id)} className="ap_item ap_item_choose" id={item.id}>
								<SmallAd headline={item.data().title} service={item.data().serviceQuick} name={item.data().nameQuick} categorie={item.data().service} address={item.data().zip} image={item.data().img[0].url} type={item.data().type}/>
							</div>
						</div>
					))}


					
				</div>
				*/}

				<section className="ergebnisseWrapper">
					<div className='ergebnisse'>
						{choosen.length !== 0 && choosen.map((data, key) => (
							<div className="choosePost" key={key} id={data.id} onClick={(e) => getUserData(e.currentTarget.id)} >
								<div className='top'>
									<img src={data.data().img[0].url} alt="" />
									<div>
										<h4>{data.data().title}</h4>
										<ul>
											<li><CustomIcon image={iconAusrufezeichen} imageAlt="Location Pin" />{data.data().servicesName}</li>
											<li><CustomIcon image={iconAusrufezeichen} imageAlt="Merkmal" />{data.data().serviceQuick}</li>
											<li><CustomIcon image={iconAusrufezeichen} imageAlt="Merkmal" />{data.data().nameQuick}</li>
											<li><CustomIcon image={iconLocationPin} imageAlt="Merkmal" />{data.data().address}{data.data().address && (data.data().zip || data.data().location) ? ", " : ""}{data.data().zip}{data.data().zip ? " " : ""}{data.data().location}</li>
										</ul>
										{(data.data().type === "flat" || data.data().type === "plus") && <button className="buttonSmallAd" id={data.id}>Mehr Infos und Bildergalerie</button>}
									</div>
								</div>
								<button className='btnMerkliste'><CustomIcon image={iconMerkliste} imageAlt="Merkliste" dimensions={{ width: '20px' }} />Auf Merkliste setzen</button>
								<button className='btnNotdienst'><CustomIcon image={data.data().alarm ? iconNotdienstAktiv : iconNotdienst} imageAlt="Notdienst" dimensions={{ width: '25px' }} />{data.data().alarm ? "Notdienst eingestellt" : "Kein Notdienst eingestellt"}</button>
								<button className='btnAnfrage'>
									<div>
										<span>Gratis Anfrage </span>
										Mit diseser Anfrage trittst du direkt mit dem Anbieter in kontakt. iVenti ist an dieser Kommunikation nicht beteiligt und speichert sie nicht.
									</div>
									<CustomIcon image={iconKontakt} imageAlt="Kontaktieren" dimensions={{ width: '35px' }} />
								</button>
							</div>
						))}
					</div>
				</section>
				</>
				}
				
				
				{selectedAd && 
				<>
				<div style={{marginTop: "40px"}} className="headline">
					<h2>Wähle Kategorie und Bereich und passe dein Inserat an.</h2>
				{/*<p style={{textAlign: "center", maxWidth: "90%", width: "800px", margin: "30px auto 50px auto"}} >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</p>*/}
				</div>

				<div className="selectContainer">
					<CustomLogicSelect runProgress={runProgress} state={1} select={categorieSelect} setSelect={setCategorySelect}>
						<MenuItem sx={menuItemStyle} value={0}>Kategorie - bitte wählen*</MenuItem>
							{categories.map((categorie, index) => (
								<MenuItem sx={menuItemStyle} key={index} value={categorie.id}>{categorie.name}</MenuItem>
							))}
					</CustomLogicSelect>
					<CustomLogicSelect runProgress={runProgress} state={2} select={serviceSelect} setSelect={setServiceSelect} disabled={services === 0 ? true : false}>
						<MenuItem sx={menuItemStyle} value={0}>Bereich - bitte wählen*</MenuItem>
							{Object.keys(services).map((service, index) => (
								<MenuItem sx={menuItemStyle} key={index} value={services[index]}>{services[index]}</MenuItem>
							))}
					</CustomLogicSelect>
				</div>
				</>
				}

				

				{serviceSelect !== 0 &&

				<>


				<input style={{border: errorTitle ? "1px solid red" : "", fontWeight: "800"}} value={title} maxLength="50" className="textField textFieldHeadlineMiddle" type="text" placeholder="DEINE INSERATSÜBERSCHRIFT" onChange={(e) => setTitle(e.target.value)}/>	

				<div className="galleryAndContent">
					<div className="firstColumn">
						<ProviderGallery edit={edit} documentID={documentID} type={type} images={images} setImages={setImages} />
						{type !== "zero" &&
						<>
							<input style={{border: errorHeadline ? "1px solid red" : ""}} maxLength="30" value={headline} className="textField textFieldHeadlineMiddle" type="text" placeholder="Überschrift" onChange={(e) => setHeadline(e.target.value)}/>
							<textarea style={{border: errorText ? "1px solid red" : ""}} value={text} rows="4" maxLength={2500} placeholder="Gib hier einen freien Text ein (max. 2500 Zeichen)" onChange={(e) => setText(e.target.value)}></textarea>
							<input maxLength="50" value={headline2} className="textField textFieldHeadlineMiddle" type="text" placeholder="Weitere Überschrift (optional)" onChange={(e) => setHeadline2(e.target.value)}/>
							<textarea value={text2} rows="4" maxLength={2500} placeholder="Gib hier einen weiteren freien Text ein (max. 2500 Zeichen)" onChange={(e) => setText2(e.target.value)}></textarea>
							<a href="mailto:hey@iventi.de" style={{color: "#525254", display: "block", paddingTop: "20px", marginBottom: "20px", textTransform: "uppercase", fontWeight: "700", fontSize: "14px", textAlign: "center", textDecoration: "underline"}}>Dir fehlt eine Information? Kontaktiere uns</a>
						</>
						}
					</div>
					<div className="secondColumn">

						<input style={{marginTop: "0px", border: errorName ? "1px solid red" : ""}} value={nameQuick} maxLength="50" className="textField" type="text" placeholder="Name/Firma/Künstler*" onChange={(e) => setNameQuick(e.target.value)}/>
						<input value={address} maxLength="50" className="textField" type="text" placeholder="Straße und Hausnummer" onChange={(e) => setAddress(e.target.value)}/>
						<input maxLength="5" style={{width: "40%", border: errorZip ? "1px solid red" : ""}} className="textField" type="number" placeholder="Plz" value={zip} onChange={(e) => setZip(e.target.value)}/>
						<input maxLength="30" value={location} style={{width: "57%", float: "right", border: errorLocation ? "1px solid red" : ""}} className="textField" type="text" placeholder="Ort" onChange={(e) => setLocation(e.target.value)} />

						<div style={{marginTop: "20px", display: "flex", alignItems: "center"}}>
							<input style={{flexBasis: "unset"}} checked={saveAdress} type="checkbox" className="checkboxContainer" onChange={(e) => setSaveAdress(e.target.checked)}/>
							<div style={{marginLeft: "10px", flexBasis: "unset"}}>Angaben in Profil speichern.</div>
						</div>

						<div style={{marginTop: "20px", marginBottom: "20px", textTransform: "uppercase", fontWeight: "700"}}>Wo willst du gefunden werden?</div>
						<CustomSelect error={errorLocationSelect} className="selectItem" select={city} setSelect={setCity}>
							<MenuItem sx={menuItemStyle} value={0}>Ort wählen</MenuItem>
							<MenuItem sx={menuItemStyle} value={"Hamburg & Umland"}>Hamburg & Umland</MenuItem>
							<MenuItem sx={menuItemStyle} value={"Flensburg & Umland"}>Flensburg & Umland</MenuItem>
							<MenuItem sx={menuItemStyle} value={"Kiel & Umland"}>Kiel & Umland</MenuItem>
							<MenuItem sx={menuItemStyle} value={"Lübeck & Umland"}>Lübeck & Umland</MenuItem>
							<MenuItem sx={menuItemStyle} value={"Neumünster & Umland"}>Neumünster & Umland</MenuItem>
							<MenuItem sx={menuItemStyle} value={"Husum & Umland"}>Husum & Umland</MenuItem>
							<MenuItem sx={menuItemStyle} value={"Lüneburg & Umland"}>Lüneburg & Umland</MenuItem>
						</CustomSelect>

						<div style={{marginTop: "35px", textTransform: "uppercase", fontWeight: "700"}}>Kurzinfo</div>
						<div className="infoPoint">
							<div><CelebrationIcon/></div>
							<div style={{marginLeft: "5px"}}><strong>{servicesName}</strong></div>
						</div>
						<div className="infoPoint">
							<div><InfoIcon/></div>
							<div style={{marginLeft: "5px"}}><strong>{serviceSelect}</strong></div>
						</div>
						<div className="infoPoint">
							<div><InfoIcon/></div>
							<div style={{marginLeft: "5px"}}>{nameQuick ? <strong>nameQuick</strong> : "Dein Name"}</div>
						</div>
						<div className="infoPoint">
							<div><LocationOnIcon/></div>
							<div style={{marginLeft: "5px"}}>{address || location || zip ? (<><strong>{address}{address && (zip || location) ? ", " : "" }{zip} {location}</strong></>) : "Deine Adresse"}</div>
						</div>
						
						
						
						{/* 
						<CustomSelect className="selectItem" select={radius} setSelect={setRadius}>
							<MenuItem sx={menuItemStyle} value={10}>+ 10 km</MenuItem>
							<MenuItem sx={menuItemStyle} value={50}>+ 50 km</MenuItem>
						</CustomSelect>
						*/}
					</div>
				</div>

				

				

				</>
				}

			

			</div>

            
            {serviceSelect !== 0 && 
            <>
				<div className="lineBreakTopGrey">
						<img style={{display: "block"}} src={TopShape} />
				</div>

                <div style={{width: "100%", display: "flex", justifyContent: "center", padding: "50px 0 50px 0", backgroundColor: "white"}}>

				{loadingSubmit ? 
					<button className="loadingButton"><CircularProgress style={{ color: "white", width: "31px", height: "31px" }} /></button>
					:
					<>
					{type === "zero" || userFlat ?
						<button className="submitAdButton" onClick={saveAd}>Aktivieren</button> :
						<button className="submitAdButton" onClick={saveAd}>Weiter</button>
					}
					</>
			}
                </div>
                </>
                }
            </>
            }
			</>
			}
		</>
	)
}

export default CreateInspo;
