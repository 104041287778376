import React, { useEffect, useState } from 'react';
import './Categories.css'
import { deleteDoc ,arrayUnion ,collection, getDocs, getDoc, doc, query, where, setDoc, onSnapshot, updateDoc, writeBatch, addDoc } from 'firebase/firestore';
import { db, auth } from '../../Firebase'
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom'
import Loader from '../Loader';

function Inspirations() {

	const navigate = useNavigate()

	const [loading, setLoading] = useState(false)
	const [categories, setCategories] = useState([])
	const [changeName, setChangeName] = useState(false)
	const [changeNameId, setChangeNameId] = useState("")
	const [loadingSubmit, setLoadingSubmit] = useState(false)
	const [newName, setNewName] = useState("")
	const [oldName, setOldName] = useState("")
	const [reload, setReload] = useState(true)
	const [site, setSite] = useState(0)
	const [error, setError] = useState("")
	const [serviceId, setServiceId] = useState()
	const [serviceName, setServiceName] = useState()
	const [service, setService] = useState([])
	const [selectedService, setSelectedService] = useState()
	const [selectedServiceName, setSelectedServiceName] = useState()
	const [selectedServiceData, setSelectedServiceData] = useState()
	const [innerKeywords, setInnerKeywords] = useState()
	const [innerKeywordName, setInnerKeywordName] = useState()

	//add new values
	const [add, setAdd] = useState(false)

	
	//######### DELETE FUCTION ###########

	//DELETE STATES
	const [deletePop, setDeletePop] = useState(false)
	
	function runDeletePopup(id, name){
		setLoadingSubmit(false)
		setError("")
		setDeletePop(true)
		setChangeNameId(id)
		setOldName(name)
	}

	function deleteValue(){
		setLoadingSubmit(true)

		if(site === 0){

			getDocs(query(collection(db, "inspos"), where("servicesName", "==", oldName))).then((docs) => {

				if(docs.size === 0){
					
					deleteDoc(doc(db, "inspirations", changeNameId)).then(() => {
						setDeletePop(false)
						setReload(!reload)
					})
					

					
				} else {
					setError("Kann nicht gelöscht werden")
					setLoadingSubmit(false)
				}

				
	
			}).catch((err) => {
				console.log(err)
			})
		}

		if(site === 1){

			getDocs(query(collection(db, "inspos"), where("service", "==", oldName))).then((docs) => {

				if(docs.size === 0){

					getDoc(doc(db, "inspirations", serviceId)).then((doc) => {
						var oldData = doc.data().services
						oldData.splice(oldData.indexOf(oldName), 1)
						
						updateDoc(doc.ref, {
							services: oldData
						}).then(() => {
							setDeletePop(false)
							update()
						})
					})

					
				} else {
					setError("Kann nicht gelöscht werden")
					setLoadingSubmit(false)
				}

				
	
			}).catch((err) => {
				console.log(err)
			})
		}
	}




	
	//######### END DELETE FUCTION ###########


	function changeCatName(id, name){
		setError("")
		setChangeName(true)
		setChangeNameId(id)
		setNewName(name)
		setOldName(name)
	}

	function selectService(id){
		setServiceId(id)

		var arraySort = []

		getDoc(doc(db, "inspirations", id)).then((data) => {
			setServiceName(data.data().name)
			Object.keys(data.data().services).map((index) => {
				arraySort.push(data.data().services[index])
			})

			arraySort.sort(function(a, b) {
				var nameA = a.toUpperCase() // ignore upper and lowercase
				var nameB = b.toUpperCase() // ignore upper and lowercase
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}
				// names must be equal
				return 0;
			})
			setService(arraySort)
			setSite(1)
		})
	}

	function update(){

		var arraySort = []

		if(site === 0){
			getDocs(collection(db, "inspirations")).then((docs) => {
				docs.forEach((doc) => {
					arraySort.push(doc)
				})
	
				arraySort.sort(function(a, b) {
					var nameA = a.data().name.toUpperCase() // ignore upper and lowercase
					var nameB = b.data().name.toUpperCase() // ignore upper and lowercase
					if (nameA < nameB) {
						return -1;
					}
					if (nameA > nameB) {
						return 1;
					}
					// names must be equal
					return 0;
				})
	
				setLoading(false)
	
				setCategories(arraySort)
	
			})
		}


		if(site === 1){
			getDoc(doc(db, "inspirations", serviceId)).then((data) => {
				setServiceName(data.data().name)
				Object.keys(data.data().services).map((index) => {
					arraySort.push(data.data().services[index])
				})
	
				arraySort.sort(function(a, b) {
					var nameA = a.toUpperCase() // ignore upper and lowercase
					var nameB = b.toUpperCase() // ignore upper and lowercase
					if (nameA < nameB) {
						return -1;
					}
					if (nameA > nameB) {
						return 1;
					}
					// names must be equal
					return 0;
				})
				setService(arraySort)
				setSite(1)
				setLoadingSubmit(false)
				setChangeName(false)
			})
		
		}
	}

	function selectKeywords(id){
		setSelectedService(id)

		var arraySort = []

		getDoc(doc(db, "categories", serviceId)).then((data) => {

			arraySort = data.data().services[id].keywords
			
			arraySort.sort(function(a, b) {
				var nameA = a.name.toUpperCase() // ignore upper and lowercase
				var nameB = b.name.toUpperCase() // ignore upper and lowercase
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}
				// names must be equal
				return 0;
			})
			setSelectedServiceData(arraySort)
			setSite(2)

		})

	}

	function showInnerKeywords(id){

		setInnerKeywordName(id)

		var arraySort = []

		getDoc(doc(db, "categories", serviceId)).then((data) => {

			var arrayIndex = data.data().services[selectedService].keywords.findIndex(obj => obj.name === id)
			var arrayData = data.data().services[selectedService].keywords[arrayIndex].words
			
			
			arrayData.sort(function(a, b) {
				var nameA = a.toUpperCase() // ignore upper and lowercase
				var nameB = b.toUpperCase() // ignore upper and lowercase
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}
				// names must be equal
				return 0;
			})
			
			setInnerKeywords(arrayData)
			setSite(3)
			
			

		})
	}

	function saveCatName(){
		setLoadingSubmit(true)

		if(newName === ""){
			setError("Name kann nicht leer sein!")
			setLoadingSubmit(false)
			return
		}

		if(newName === oldName){
			setError("Name identisch mit altem Name!")
			setLoadingSubmit(false)
			return
		}

		if(String(newName).includes("/")){
			setError("Name kann kein '/' enthalten!")
			setLoadingSubmit(false)
			return
		}

		getDocs(query(collection(db, "inspirations"), where("name", "==", newName))).then((docs) => {
			if(docs.size > 0){
				setError("Name bereits vorhanden!")
				setLoadingSubmit(false)
				return
			}
			getDocs(query(collection(db, "inspos"), where("servicesName", "==", oldName))).then((docs) => {
				const batch = writeBatch(db)
	
				batch.set(doc(db, "inspirations", changeNameId), {
					name: newName
				}, {merge: true})
	
				
				if(docs.size > 0){
					docs.forEach((doc) => {
						batch.set(doc.ref, {
							servicesName: newName
						}, {merge: true})
					})
				}
				
	
				batch.commit().then(() => {
					console.log("done batch job")
					setReload(!reload)
				}).catch((err) => {
					console.log(err)
				})
	
			}).catch((err) => {
				console.log(err)
			})

		})
	}

	function saveKeyword(){

		setLoadingSubmit(true)

		if(newName === ""){
			setError("Name kann nicht leer sein!")
			setLoadingSubmit(false)
			return
		}

		if(newName === oldName){
			setError("Name identisch mit altem Name!")
			setLoadingSubmit(false)
			return
		}

		if(String(newName).includes("/")){
			setError("Name kann kein '/' enthalten!")
			setLoadingSubmit(false)
			return
		}

		getDoc(doc(db, "categories", serviceId)).then((doc) => {

			var arrayIndex = doc.data().services[selectedService].keywords.findIndex(obj => obj.name === innerKeywordName)
			var arrayData = doc.data().services[selectedService].keywords[arrayIndex].words

			if(arrayData.includes(newName)){
				setError("Name bereits vorhanden!")
				setLoadingSubmit(false)
				return
			}

			getDocs(query(collection(db, "posts"), where("service", "==", selectedService))).then((docs) => {
				
				const batch = writeBatch(db)

				var copyService = doc.data().services
				copyService[selectedService].keywords[arrayIndex].words[copyService[selectedService].keywords[arrayIndex].words.indexOf(oldName)] = newName
				
				
				batch.update(doc.ref, {
					services: copyService
				})
				

				if(docs.size > 0){
					docs.forEach((doc) => {
						var saveData = doc.data().keywords
						if(saveData[innerKeywordName]){
							if(saveData[innerKeywordName].includes(oldName)){
								saveData[innerKeywordName][saveData[innerKeywordName].indexOf(oldName)] = newName
	
								batch.update(doc.ref, {
									keywords: saveData
								})
								
							}
						}
						if(innerKeywordName === "Leistungen"){
							if(doc.data().serviceQuick === oldName){
								batch.update(doc.ref, {
									serviceQuick: newName
								})
							}
						}
					})
				}

				batch.commit().then(() => {
					console.log("done")
					update()
				}).catch((err) => {
					console.log(err)
				})

				
	
			}).catch((err) => {
				console.log(err)
			})
			

		})
		
	}

	function addNewValue(){
		setLoadingSubmit(false)
		setError("")
		setNewName("")
		setAdd(true)
	}

	function saveKeywordGroup(){
		setLoadingSubmit(true)

		if(newName === ""){
			setError("Name kann nicht leer sein!")
			setLoadingSubmit(false)
			return
		}

		if(newName === oldName){
			setError("Name identisch mit altem Name!")
			setLoadingSubmit(false)
			return
		}

		if(String(newName).includes("/")){
			setError("Name kann kein '/' enthalten!")
			setLoadingSubmit(false)
			return
		}

		getDoc(doc(db, "categories", serviceId)).then((doc) => {
			if(doc.data().services[selectedService].keywords.filter(e => e.name === newName).length > 0){
				setError("Name bereits vorhanden!")
				setLoadingSubmit(false)
				return
			}

			getDocs(query(collection(db, "posts"), where("service", "==", selectedService))).then((docs) => {

				const batch = writeBatch(db)

				var copyService = doc.data().services
				var index = copyService[selectedService].keywords.findIndex((obj) => obj.name == oldName)

				copyService[selectedService].keywords[index].name = newName
				
				
				batch.update(doc.ref, {
					services: copyService
				})
				
				
				if(docs.size > 0){
					docs.forEach((doc) => {
						
						var keywords = doc.data().keywords
						var oldKeywords = keywords[oldName]
						if(oldKeywords){
							delete keywords[oldName]
							keywords[newName] = oldKeywords
							
							batch.update(doc.ref, {
								keywords: keywords
							})
						}
					})
				}
				
				
	
				batch.commit().then(() => {
					update()
				}).catch((err) => {
					console.log(err)
				})
				
				
	
			}).catch((err) => {
				console.log(err)
			})

		})


	}

	function saveServiceName(){
		setLoadingSubmit(true)

		if(newName === ""){
			setError("Name kann nicht leer sein!")
			setLoadingSubmit(false)
			return
		}

		if(newName === oldName){
			setError("Name identisch mit altem Name!")
			setLoadingSubmit(false)
			return
		}

		if(String(newName).includes("/")){
			setError("Name kann kein '/' enthalten!")
			setLoadingSubmit(false)
			return
		}

		getDoc(doc(db, "inspirations", serviceId)).then((doc) => {
			
			if(doc.data().services.includes(newName)){
				setError("Name bereits vorhanden!")
				setLoadingSubmit(false)
				return
			}

			
			getDocs(query(collection(db, "inspos"), where("service", "==", oldName))).then((docs) => {

				const batch = writeBatch(db)

				var oldData = doc.data().services
				oldData[oldData.indexOf(oldName)] = newName

				
				batch.set(doc.ref, {
					services: oldData
				},{merge: true})
	
				
				if(docs.size > 0){
					docs.forEach((doc) => {
						batch.set(doc.ref, {
							service: newName,
							serviceQuick: newName
						}, {merge: true})
					})
				}
				
				
	
				batch.commit().then(() => {
					update()
				}).catch((err) => {
					console.log(err)
				})
				
				
				
	
			}).catch((err) => {
				console.log(err)
			})


			

		})
	}

	function saveValue(){
		setLoadingSubmit(true)

		if(newName === ""){
			setError("Name kann nicht leer sein!")
			setLoadingSubmit(false)
			return
		}

		if(String(newName).includes("/")){
			setError("Name kann kein '/' enthalten!")
			setLoadingSubmit(false)
			return
		}

		if(site === 0){

			getDocs(query(collection(db, "inspirations"), where("name","==", newName))).then((docs) => {
				if(docs.size > 0){
					setError("Name bereits vorhanden!")
					setLoadingSubmit(false)
					return
				}

				addDoc(collection(db, "inspirations"), {
					name: newName,
					services: ["Beispiel für eine Unterkategorie"]
				}).then(() => {
					setLoadingSubmit(false)
					setAdd(false)
					update()
				}).catch((err) => {
					setError("Es ist ein Fehler aufgetreten")
					setLoadingSubmit(false)
				})

			})
		}

		if(site === 1){

			getDoc(doc(db, "inspirations", serviceId)).then((doc) => {
			
				if(doc.data().services.includes(newName)){
					setError("Name bereits vorhanden!")
					setLoadingSubmit(false)
					return
				}

				var oldData = doc.data().services
				oldData.push(newName) 

				
				setDoc(doc.ref, {
					services: oldData
				}, {merge: true}).then(() => {
					update()
					setAdd(false)
				}).catch((err) => {
					console.log(err)
				})
				
			})
		}
		
	}

	useEffect(() => {
		setLoading(true)

		var arraySort = []

		setChangeName(false)
		setLoadingSubmit(false)

		getDocs(collection(db, "inspirations")).then((docs) => {
			docs.forEach((doc) => {
				arraySort.push(doc)
			})

			arraySort.sort(function(a, b) {
				var nameA = a.data().name.toUpperCase() // ignore upper and lowercase
				var nameB = b.data().name.toUpperCase() // ignore upper and lowercase
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}
				// names must be equal
				return 0;
			})

			setLoading(false)

			setCategories(arraySort)

		})

	}, [reload])
	

	return <div style={{marginBottom: "100px"}}>

			{loading ? <Loader /> : <> 

			{changeName && 
				<div className="PopUp">
					<div className="innerPopUp">
						{site === 0 && <div className="headlinePopUp">RUBRIK ANPASSEN</div>}
						{site === 1 && <div className="headlinePopUp">LEISTUNG ANPASSEN</div>}

						{error && <div style={{color: "red", marginTop: "10px"}}>{error}</div>}
						<input value={newName}  onChange={(e) => setNewName(e.target.value)} type="text" className="textField" style={{maxWidth: "90%", margin: "20px 0 20px 0"}} /> 
						{loadingSubmit ? 
							<button style={{height: "61px",marginBottom: "20px"}} className="loadingButton"><CircularProgress style={{ color: "white", width: "31px", height: "31px" }} /></button>
						:
						<>
							{site === 0 && <button style={{marginBottom: "20px"}} className="submitAdButton" onClick={() => saveCatName()}>SPEICHERN</button>}
							{site === 1 && <button style={{marginBottom: "20px"}} className="submitAdButton" onClick={() => saveServiceName()}>SPEICHERN</button>}
						</>
						}
						<button style={{marginBottom: "20px", backgroundColor: "lightgray"}} className="submitAdButton" onClick={() => setChangeName("")}>ABBRECHEN</button>
					</div>
				</div>
			}

			{add && 
				<div className="PopUp">
					<div className="innerPopUp">
						{site === 0 && <div className="headlinePopUp">INSPIRATION HINZUFÜGEN</div>}
						{site === 1 && <div className="headlinePopUp">UNTERKATEGORIE HINZUFÜGEN</div>}

						{error && <div style={{color: "red", marginTop: "10px"}}>{error}</div>}
						<input value={newName}  onChange={(e) => setNewName(e.target.value)} type="text" className="textField" style={{maxWidth: "90%", margin: "20px 0 20px 0"}} /> 
						{loadingSubmit ? 
							<button style={{height: "61px",marginBottom: "20px"}} className="loadingButton"><CircularProgress style={{ color: "white", width: "31px", height: "31px" }} /></button>
						:
						<>
							<button style={{marginBottom: "20px"}} className="submitAdButton" onClick={() => saveValue()}>SPEICHERN</button>
						</>
						}
						<button style={{marginBottom: "20px", backgroundColor: "lightgray"}} className="submitAdButton" onClick={() => setAdd(false)}>ABBRECHEN</button>
					</div>
				</div>
			}

			{/* DELETE POPUP */}
			{deletePop && 
				<div className="PopUp">
					<div className="innerPopUp">
						{site === 0 && <div style={{marginBottom: "10px"}} className="headlinePopUp">RUBRIK WIRKLICH LÖSCHEN?</div>}
						{site === 1 && <div style={{marginBottom: "10px"}} className="headlinePopUp">DIENSTLEISTUNG WIRKLICH LÖSCHEN?</div>}
						{site === 2 && <div className="headlinePopUp">KATEGORIE HINZUFÜGEN</div>}
						{site === 3 && <div className="headlinePopUp">SUCHBEGRIFF HINZUFÜGEN</div>}

						{error && <div style={{color: "red", marginTop: "10px", marginBottom: "10px", textAlign: "center"}}>{error}</div>}
						{loadingSubmit ? 
							<button style={{height: "61px",marginBottom: "20px"}} className="loadingButton"><CircularProgress style={{ color: "white", width: "31px", height: "31px" }} /></button>
						:
						<>
							<button style={{marginBottom: "20px"}} className="submitAdButton" onClick={() => deleteValue()}>LÖSCHEN</button>
						</>
						}
						<button style={{marginBottom: "20px", backgroundColor: "lightgray"}} className="submitAdButton" onClick={() => setDeletePop(false)}>ABBRECHEN</button>
					</div>
				</div>
			}

			

			<div className="headline">
                {site === 0 && <h2 style={{ textAlign: "center", margin: "50px 0 50px 0", fontWeight: "800" }}>INSPIRATIONEN BEARBEITEN</h2>}
				{site === 1 && <h2 style={{ textAlign: "center", margin: "50px 0 0px 0", fontWeight: "800", textTransform: "uppercase" }}>{serviceName} - UNTERKATEGORIEN BEARBEITEN</h2>}
				{site === 1 &&<div style={{cursor: "pointer", textAlign: "center", margin: "10px 0 40px 0", color: "#FFCE00", fontWeight: "800"}} onClick={() => setSite(site - 1)}>Zurück</div>}
			</div>

			

			<div className="cat_content">

				{site === 0 && <>
				
					{categories.map((item, index) => (
						<div key={index} className="cat_container">
							<div>{item.data().name}</div>
							<div id={item.id} style={{marginLeft: "auto", textDecoration: "underline", cursor: "pointer"}} onClick={(e) => runDeletePopup(e.currentTarget.id, item.data().name)}>Löschen</div>
							<div id={item.id} style={{marginLeft: "10px", textDecoration: "underline", cursor: "pointer"}} onClick={(e) => changeCatName(e.currentTarget.id, item.data().name)}>Ändern</div>
							<div id={item.id} style={{marginLeft: "10px", textDecoration: "underline", cursor: "pointer"}} onClick={(e) => selectService(e.currentTarget.id)}>Unterkategorien</div>
						</div>
					))}

					
					<div className="cat_container" style={{justifyContent: 'center', cursor: "pointer"}} onClick={() => addNewValue()}>
						<div style={{cursor: "pointer",fontWeight: "800"}}>NEUE INSPIRATION HINZUFÜGEN</div>
					</div>
					
					

				</>}

				{site === 1 && <>
				
					{service.map((item, index) => (
						<div key={index} className="cat_container">
							<div>{item}</div>
							<div id={item.id} style={{marginLeft: "auto", textDecoration: "underline", cursor: "pointer"}} onClick={(e) => runDeletePopup(e.currentTarget.id, item)}>Löschen</div>
							<div id={item} style={{marginLeft: "10px", textDecoration: "underline", cursor: "pointer"}} onClick={(e) => changeCatName(e.currentTarget.id, item)}>Ändern</div>
						</div>
					))}

					<div className="cat_container" style={{justifyContent: 'center', cursor: "pointer"}} onClick={() => addNewValue()}>
						<div style={{cursor: "pointer",fontWeight: "800"}}>NEUE UNTERKATEGORIE HINZUFÜGEN</div>
					</div>

				</>}
				

			

			</div>

			</>}


	</div>;
}

export default Inspirations;
