import React from 'react';
import './PricecardElement.css';
import Tooltip from './Tooltip';

const PricecardElement = props => {
  return (
    <div className="pricecardElement ui">
      {props.caption && <Tooltip caption={props.caption}/>}
      {props.content}
      {props.children}
    </div>
  );
}

export default PricecardElement;