import { useEffect, useState } from "react";
import Loader from "../Loader";
import { collection, getDocs, query, where, doc, deleteDoc, onSnapshot, setDoc } from 'firebase/firestore';
import { db } from '../../Firebase'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';

export default function Coupon() {

    const [loading, setLoading] = useState(true);
    const [coupons, setCoupons] = useState([]);
    const [numberOfCoupons, setNumberOfCoupons] = useState("");
    const [loadingButton, setLoadingButton] = useState(false);
    const [error, setError] = useState(false)

    function deleteCoupon(id) {
        const couponRef = doc(db, "coupon", id);
        deleteDoc(couponRef);
    }

    function createCoupons() {

        setError(false)

        if (numberOfCoupons < 1) {
            setError("Die Anzahl muss mindesens 1 sein.")
            return
        }

        if (numberOfCoupons > 50) {
            setError("Die Anzahl darf maximal 50 sein.")
            return
        }

        setLoadingButton(true);
        for (let i = 0; i < numberOfCoupons; i++) {
            const randomString = Math.random().toString(36).substring(2, 7).toUpperCase();
            setDoc(doc(db, "coupon", `iVenti-${randomString}`), {
                active: false
            });
        }
        setLoadingButton(false);
    }

    useEffect(async () => {
        const unsubscribe = onSnapshot(query(collection(db, "coupon")), (snapshot) => {
            const docs = [];
            snapshot.forEach((doc) => {
                docs.push(doc);
            });
            setCoupons(docs);
        });
        return () => unsubscribe();

    }, []);

    return <>
        <div className="headline">
            <h2 style={{ textAlign: "center", margin: "50px 0 20px 0", fontWeight: "800" }}>OFFENE GUTSCHEINE</h2>
        </div>
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '20px',
            gap: '10px'
        }}>
            <input style={{ marginTop: '0px', width: '200px', textAlign: 'center' }} onChange={(e) => setNumberOfCoupons(e.target.value)} className="textField" type="number" placeholder="Anzahl Gutscheine" value={numberOfCoupons} />
            {loadingButton === false ?
                <button onClick={() => createCoupons()} className="submitAdButton">Gutschein(e) anlegen</button>
                :
                <button className="loadingButton"><CircularProgress style={{ color: "white", width: "31px", height: "31px" }} /></button>
            }

        </div>
        {error !== false && <div style={{
            color: 'red',
            textAlign: 'center',
            marginBottom: '20px'
        }}>{error}</div>}
        <TableContainer sx={{ maxWidth: "80%", margin: "auto", marginBottom: "50px" }} component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell width={20} align="left">Nummer</TableCell>
                        <TableCell align="left">Gutscheincode</TableCell>
                        <TableCell align="right">Aktion</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {coupons.filter(item => item.data().active === false).map((row, index) => (
                        <TableRow
                            key={row.id}
                            id={row.id}
                            style={{ cursor: "inherit" }}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer" }}
                        >
                            <TableCell component="th" scope="row">
                                {index + 1}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.id}
                            </TableCell>
                            <TableCell align="right" component="th" scope="row">
                                <div onClick={() => deleteCoupon(row.id)} style={{ textDecoration: 'underline', cursor: 'pointer' }}>Löschen</div>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <div className="headline">
            <h2 style={{ textAlign: "center", margin: "50px 0 20px 0", fontWeight: "800" }}>EINGELÖSTE GUTSCHEINE</h2>
        </div>
        <TableContainer sx={{ maxWidth: "80%", margin: "auto", marginBottom: "50px" }} component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell width={20} align="left">Nummer</TableCell>
                        <TableCell align="left">Gutscheincode</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {coupons.filter(item => item.data().active === true).map((row, index) => (
                        <TableRow
                            key={row.id}
                            id={row.id}
                            style={{ cursor: "inherit" }}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer" }}
                        >
                            <TableCell component="th" scope="row">
                                {index + 1}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.id}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </>
}