import React, { useRef, useState } from 'react';
import Header from '../ui/Header';
import CircularProgress from '@mui/material/CircularProgress';
import { useUserContext } from '../../context/UserContext';
import { useNavigate, Navigate, Link, useParams } from 'react-router-dom'
import PopUp from '../PopUp';
import { sendEmailVerification, updateProfile } from 'firebase/auth'
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../Firebase'
import Trenner from '../ui/Trenner';
import './RegisterProvider.css'


function RegisterProvider() {

	const emailRef = useRef()
	const legal = useRef()
	const nameRef = useRef()
	const passwordRef = useRef()
    const passwordRepeatRef = useRef()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
	const [type, setType] = useState("user")

	const navigate = useNavigate()

	const { registerUser, user } = useUserContext()

	async function signInNewUser(){
        setLoading(true)

		if(nameRef.current.value === "" || emailRef.current.value === "" || passwordRef.current.value === "" || passwordRepeatRef.current.value === ""){
			setError("Bitte fülle alle Pflichtfelder aus")
			setLoading(false)
			return
		}

		if(!legal.current.checked){
			setError("Akzeptiere unsere Datenschutzerlärung und AGB um ein Konto zu erstellen")
			setLoading(false)
			return
		}

		if(passwordRef.current.value !== passwordRepeatRef.current.value){
			setError("Passwörter stimmen nicht überein")
			setLoading(false)
			return
		}

        registerUser(emailRef.current.value, passwordRef.current.value).then((data) => {
            sendEmailVerification(data.user)
			updateProfile(data.user, {
				displayName: nameRef.current.value
			}).then(() => {
				setDoc(doc(db, "userData", data.user.uid), {
					flat: false,
					type: type === "user" ? "Benutzer" : "Anbieter"
				}).then(() => {
					navigate("/dashboard")
				})
			})
        }).catch((err) => {
			if(String(err).includes("(auth/invalid-email)")){
				setError("Bitte gebe eine gültige E-Mail an")
			} else if(String(err).includes("(auth/weak-password)")){
				setError("Das Passwort muss aus mindestens 6 Zeichen bestehen")
			} else if(String(err).includes("(auth/email-already-in-use)")){
				setError("Es gibt bereits ein Konto mit dieser E-Mail")
			} else {
				setError("Es ist ein Fehler aufgetreten")
			}
			console.log(err)
            setLoading(false)
        })
	}

	return (
		<>
			{error && <PopUp message={error} setError={setError}/>}
			<div className="LoginProvider">
				<Header style={{paddingBottom: "15px"}} className="backgroundAnimation" nav="simple">
					<h2 style={{color: "#fff", textAlign: "center", fontSize: "35px", fontWeight: "800", margin: "50px 0 0 0"}}>KONTO ANLEGEN BEI iVENTI</h2>
				</Header>
				<Trenner shape="small" foreground="grey" background="animated" />
				<div className="headlineNormal" style={{backgroundColor: "#F6F6F5"}}>Konto erstellen</div>

				{/** choose type of user */}
				<div className="toggleSwitchContainer">
					<div className={"toggleSwitchUser" + (type === "user" ? " active" : "")} onClick={() => setType("user")}>Suchende</div>
					<div className={"toggleSwitchUser" + (type === "customer" ? " active" : "")} onClick={() => setType("customer")}>Anbieter:in</div>
				</div>


				<div style={{maxWidth: "90%", width: "500px", margin: "auto", paddingTop: "0px", textAlign: "center"}}>
					<input ref={nameRef} className="textField" type="text" placeholder="Dein Name*" />
					<input ref={emailRef} className="textField" type="text" placeholder="E-Mail-Adresse*" />
					<input ref={passwordRef} className="textField" type="password" placeholder="Passwort*" />
                    <input ref={passwordRepeatRef} className="textField" type="password" placeholder="Passwort wiederholen*" />
					<div style={{display: "flex", justifyContent: "center", alignItems: "center", padding: "25px 0 10px 0"}} className="legal">
						<input type="checkbox" ref={legal}/>
						<div style={{paddingLeft: "5px"}}>Ich akzeptiere die Datenschutzerklärung und AGB*</div>
					</div>
				</div>
				<div style={{cursor: "pointer" ,width: "100%", display: "flex", alignItems: "center", margin: "20px 0 50px 0", flexDirection: "column"}}>
					<Link to="/login" style={{marginBottom: "20px" ,fontSize: "12px"}}>Du hast bereits einen Account? Hier anmelden</Link>
					{loading ? 
						<button className="loadingButton"><CircularProgress style={{ color: "white", width: "31px", height: "31px" }} /></button>
						:
						<button onClick={signInNewUser} className="submitAdButton">REGISTRIEREN</button>
					}
				</div>
			</div>
		</>
	)
}

export default RegisterProvider;
