import React from 'react'
import './CustomIcon.css';

const CustomIcon = props => {
    var style = {};
    if(props.dimensions){
        if(props.dimensions.width && props.dimensions.height){
            style.width = props.dimensions.width;
            style.height = props.dimensions.height;
        }else if(props.dimensions.width){
            style.width = props.dimensions.width;
            style.height = "auto";
        }else{
            style.width = "auto";
            style.height = props.dimensions.height;
        }
    }


    return (
        <div className='iconWrapper' style={props.style}>
            <img src={props.image} alt={props.imageAlt} style={style}/>
        </div>
    )
}

export default CustomIcon;