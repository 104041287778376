import React, { useState, useEffect } from 'react'
import { arrayUnion ,collection, getDocs, getDoc, doc, query, where, setDoc } from 'firebase/firestore';
import { db, auth } from '../../Firebase'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Loader from '../Loader';

function Orders() {

    const [loading, setLoading] = useState(true)
    const [orders, setOrders] = useState([])

    useEffect(() => {

        getDocs(collection(db, "orders")).then((docs) => {
            docs.forEach((doc) => {
                if(doc.data().paid) orders.push(doc) 
            })
            setOrders([...orders])
            setLoading(false)
        })

    }, [])
    

    return (
        <div className="Orders" >

            {loading ? <Loader /> : <>

            <div className="headline">
                <h2 style={{ textAlign: "center", margin: "50px 0 50px 0", fontWeight: "800" }}>BESTELLUNGEN</h2>
            </div>
            {orders.length === 0 ? <p style={{ textAlign: "center", marginBottom: "50px" }}>Keine Bestellungen...</p> :
                <TableContainer sx={{ maxWidth: "80%", margin: "auto", marginBottom: "50px" }} component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Kunde</TableCell>
                                <TableCell align="left">Rechnungsadresse</TableCell>
                                <TableCell align="right">Preis</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orders.map((row) => (
                                <TableRow
                                    key={row.id}
                                    id={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer" }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.data().userEmail}
                                    </TableCell>
                                    <TableCell align="left">{row.data().company} {row.data().address}, {row.data().location}</TableCell>
                                    <TableCell align="right">{row.data().price}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
            </>}
        </div>
    )
}

export default Orders