import React, { useState, useEffect } from 'react'
import DayPickerCustomFunction from './DayPickerCustomFunction'

//FIREBASE
import { db } from '../../Firebase'
import { arrayUnion ,collection, getDocs, getDoc, doc, query, where, setDoc } from 'firebase/firestore';
import Loader from '../Loader';
import { CircularProgress } from '@mui/material';

function PopUpEmergency(props) {

    const [selectedDays, setSelectedDays] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingSubmit, setLoadingSubmit] = useState(false)

    useEffect(() => {
        setLoading(true)
        var tempDaysArray = []
        getDoc(doc(db, "posts", props.id)).then((doc) => {
            doc.data().selectedDays.forEach((item) => {
                tempDaysArray.push(item.toDate())
            })
            setSelectedDays(tempDaysArray)
            setLoading(false)
        })

        /*
        var tempDaysArray = []
        data.data().selectedDays.forEach((item) => {
            tempDaysArray.push(item.toDate())
        })
        setSelectedDays(tempDaysArray)
        */
    }, [])

    function saveEmergency(){
        setLoadingSubmit(true)
        setDoc(doc(db, "posts", props.id), {
            selectedDays: selectedDays,
            alarm: selectedDays.length === 0 ? false : true
        }, {merge: true}).then(() => {
            props.cancel(false)
            window.location.reload()
            //props.setUpdate(!props.update)
        })
    }
    

    return (
        <div className="PopUp PopUpEmergency">

            <div style={{position: "relative"}} className="innerPopUp">
                <div className="cancelIcon" onClick={() => props.cancel(false)} >+</div>
                <div style={{maxWidth: "300px" ,marginTop:"50px", color: "#525252", fontWeight: "800", textTransform: "uppercase", fontSize: "14px", textAlign: "center"}}>Wähle die Tage an denen Du ein Notdienst anbietest.</div>
                {loading ? <Loader /> :
                <DayPickerCustomFunction selectedDays={selectedDays} setSelectedDays={setSelectedDays} />
                }
                {loadingSubmit ? 
                <div className="submitFlex" style={{marginTop:"20px", backgroundColor: "unset", padding: "0px 0 20px 0"}}>
                    <button className="loadingButton"><CircularProgress style={{color: "white", width: "31px", height: "31px" }} /></button>
                </div> 
                : 
                <button style={{ marginTop:"20px", marginBottom: "20px" }} onClick={() => saveEmergency()} className="submitAdButton">FERTIG</button>
                }
            </div>

        </div>
    )
}

export default PopUpEmergency