import React, { useEffect, useState, useRef } from 'react';
import { db } from '../../Firebase'
import { getDocs, query, collection, where, doc, setDoc, deleteDoc, getDoc } from "firebase/firestore"; 
import './Summary.css'
import VideoThumbnail from 'react-video-thumbnail'
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../context/UserContext';
import Loader from '../Loader';
import PopUp from '../PopUp';
import CircularProgress from '@mui/material/CircularProgress';
import Checkmark from '../../img/checkMark.svg'
import TopShapeGrey from '../../img/TrennerObenWeißSmall.svg'




function Summary() {

	const legal = useRef()

	const { user, cartCount, setCartCount } = useUserContext()

	const [cart, setCart] = useState([])
	const [cartInspo, setCartInspo] = useState([])
	const [isFirst, setIsFirst] = useState()
	const [isFlat, setIsFlat] = useState()
	const [loading, setLoading] = useState(true)
	const [loadingSubmit, setLoadingSubmit] = useState(false)
	const [loadingCode, setLoadingCode] = useState(false)
	const [price, setPrice] = useState(true)
	const [error, setError] = useState("")
	const [sendCode, setSendCode] = useState("")

	const [company, setCompany] = useState("")
	const [address, setAddress] = useState("")
	const [location, setLocation] = useState("")
	const [code, setCode] = useState("")

	const navigate = useNavigate() 

	function submitCart(){

		setLoadingSubmit(true)

		if(!legal.current.checked){
			setError("Akzeptiere unsere Datenschutzerlärung und AGB")
			setLoadingSubmit(false)
			return
		}

		/*
		if(sendCode === ""){
			setError("Du kannst nur mit einem Gutschein bezahlen")
			setLoadingSubmit(false)
			return
		}
		*/

		if(sendCode === "" && (company === "" || location === "" || address === "" || company === undefined || location === undefined || address === undefined)){
			setError("Bitte gebe deine Rechnungsadresse an")
			setLoadingSubmit(false)
			return
		}

		fetch('https://us-central1-iventi-6d02a.cloudfunctions.net/test/createPayment', {
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		method: 'POST',
		body: JSON.stringify({userUid: user.uid, type: sendCode !== "" ? "coupon" : "stripe", company: company, address: address, location: location, code: sendCode, userEmail: user.email})
        })
        .then((response) => {return response.text()})
        .then((data) => {
			console.log(data)
			if(data === "error"){
				setError("Es ist ein Fehler aufgetreten.")
				setLoadingSubmit(false)
			} else if(data === "isActive"){
				setError("Gutschein wurde bereits eingelöst.")
				setLoadingSubmit(false)
			} else if(data === "done"){
				setCartCount(0)
				navigate("/dashboard/adsProfile")
			} else if(data === "wrong"){
				setError("Falscher Gutschein")
				setLoadingSubmit(false)
			} else if(sendCode === ""){
				window.location.href = data
			}
		})
        .catch((err) => {
            console.log("error", err)
        })

		/*
		getDocs(query(collection(db, "posts"), where("state", "==", "cart"))).then((snapshot) => {
			snapshot.forEach((snap) => {
				setDoc(doc(db, "posts", snap.id), {
					state: "check"
				}, {merge : true}).then(() => {
					console.log("done")
					navigate("/dashboard/adsProfile")
				}).catch(() => {
					//nothing
				})
			})
		}).catch(() => {
			//nothing
		})

		getDocs(query(collection(db, "inspos"), where("state", "==", "cart"))).then((snapshot) => {
			snapshot.forEach((snap) => {
				setDoc(doc(db, "inspos", snap.id), {
					state: "check"
				}, {merge : true}).then(() => {
					console.log("done")
					navigate("/dashboard/adsProfile")
				}).catch(() => {
					//nothing
				})
			})
		}).catch(() => {
			//nothing
		})
		*/
		
	}

	async function getAds(){

		var returnObject = {}

		const docs2 = getDocs(query(collection(db, "posts"), where("prem", "==", true), where("uid", "==", user.uid)))
		const docs3 = getDocs(query(collection(db, "posts"), where("type", "==", "flat"), where("uid", "==", user.uid)))
		const docs4 = getDocs(query(collection(db, "inspos"), where("type", "==", "flat"), where("uid", "==", user.uid)))

		const [docs2Snapshot, docs3Snapshot, docs4Snapshot] = await Promise.all([
			docs2,
			docs3,
			docs4
		])

		if(docs2Snapshot.size > 0){
			returnObject["first"] = true
		} else {
			returnObject["first"] = false
		}

		if(docs3Snapshot.size > 0 || docs4Snapshot.size){
			returnObject["flat"] = true
		} else {
			returnObject["flat"] = false
		}

		return returnObject
	}

	function deleteInspo(elem){
		var id = elem.currentTarget.getAttribute("data-value")

		deleteDoc(doc(db, "inspos", id)).then(() => {
			setCartInspo(cartInspo.filter((item) => item.id !== id))
			setCartCount(cartCount - 1)
		}).catch((err) => {
			console.log(err)
		})
	}

	function deleteAd(elem){
		var id = elem.currentTarget.getAttribute("data-value")

		getDocs(query(collection(db, "inspos"), where("parent", "==", id))).then((items) => {
			if(items.size === 0){
				deleteDoc(doc(db, "posts", id)).then(() => {
					setCart(cart.filter((item) => item.id !== id))
					setCartCount(cartCount - 1)
				}).catch((err) => {
					console.log(err)
				})
			} else {
				setError("Du kannst ein Inserat nur löschen, wenn keine Inspirationen erstellt wurden.")
			}
		})
	}

	function editAd(elem){
		navigate(`/dashboard/editAds/${elem.currentTarget.getAttribute("data-value")}`)
	}

	function editInspo(elem){
		navigate(`/dashboard/editInspos/${elem.currentTarget.getAttribute("data-value")}`)
	}

	function getPrice(flat, first){
		if(flat){
			setPrice(99)
		} else if(!first){
			setPrice(99 + 49 * (cart.length - 1) + 49 * cartInspo.length)
		} else {
			setPrice(49 * cart.length + 49 * cartInspo.length)
		}
	}

	async function getAll(){
		const docs2 = getDocs(query(collection(db, "inspos"), where("state", "==", "cart"), where("uid", "==", user.uid)))
		const docs3 = getDocs(query(collection(db, "posts"), where("state", "==", "cart"), where("uid", "==", user.uid)))

		const [docs2Snapshot, docs3Snapshot] = await Promise.all([
			docs2,
			docs3
		])

		return {inspo: docs2Snapshot.docs, posts: docs3Snapshot.docs}
	}

	useEffect(() => {
		setLoading(true)
		getAll().then((result) => {
			result.posts.forEach((item) => {
				cart.push(item)
				setCart([...cart])
			})
			result.inspo.forEach((item) => {
				cartInspo.push(item)
				setCartInspo([...cartInspo])
			})
			getAds().then((e) => {
				console.log(e)
				setIsFlat(e.flat)
				setIsFirst(e.first)
				setLoading(false)
				getPrice(e.flat, e.first)
			})

			getDoc(doc(db, "userData", user.uid)).then((docUser) => {
                setLocation(`${docUser.data().zip ? docUser.data().zip : ""} ${docUser.data().location ? docUser.data().location : ""}`)
                setCompany(docUser.data().company)
                setAddress(docUser.data().address)
            })
		})
		/*
		const q = query(collection(db, "posts"), where("state", "==", "cart"), where("uid", "==", user.uid))
		getDocs(q).then((docs) => {
			docs.forEach((doc) => {
				console.log(doc.data())
				cart.push(doc)
				setCart([...cart])
			})
			getAds().then((e) => {
				console.log(e)
				setIsFlat(e.flat)
				setIsFirst(e.first)
				setLoading(false)
				getPrice(e.flat, e.first)
			})
		})
		*/
	}, [])

	function checkCode(){
		setLoadingCode(true)

		if(code === ""){
			setError("Bitte gebe einen Gutschein ein")
			setLoadingCode(false)
			return
		}

		fetch('https://us-central1-iventi-6d02a.cloudfunctions.net/test/checkCoupon', {
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		method: 'POST',
		body: JSON.stringify({code: code})
        })
        .then((response) => {return response.text()})
        .then((data) => {
			if(data === "error"){
				setError("Gutschein nicht vorhanden")
				setLoadingCode(false)
			}
			if(data === "true"){
				setSendCode(code)
				setCode("")
				document.querySelectorAll(".sum_price").forEach((item) => {
					item.innerHTML = "<strong>FLAT</strong>"
				})
				setPrice(0)
				setLoadingCode(false)
			}
		})
        .catch(() => {
            setError("Es ist ein Fehler aufgetreten")
			setLoadingCode(false)
        })
	}

	useEffect(() => {
		getAds().then((e) => {
			setIsFlat(e.flat)
			setIsFirst(e.first)
			setLoading(false)
			getPrice(e.flat, e.first)
		})
	}, [cart, cartInspo])

	return (
		<>	
		{loading ?
			<Loader />
		:
		<>
		{error && <PopUp message={error} setError={setError} />}
		<div className="Summary">
			{cart.length === 0 && cartInspo.length === 0 ? 
			<h1 style={{paddingBottom: "40px"}}>Dein Warenkorb ist leer</h1> :
			<>
			<h1>Deine Inserate: Zusammenfassung</h1>
			<div className="cartContainer">
			{cart.map((item, index) => (
				<div key={item.id} className="sum_cartItem">
					<div className="sum_columnImg">
						{item.data().img[0].type === "img" ? 
							<img src={item.data().img[0].url}/> :
							<VideoThumbnail className="ca_thumbnail" videoUrl={item.data().img[0].url} />
						}
						
					</div>
					<div className="sum_column">
						<div className="sum_headline">INSERAT iVENTI PLUS</div>
						<div className="sum_text">{item.data().title}</div>
						<div className="sum_text">Rubrik: {item.data().servicesName}</div>
						<div className="sum_text">Unterkategorie: {item.data().service}</div>
						<div style={{display: "flex"}} >
							<div onClick={editAd} data-value={item.id} className="sum_text sum_edit">Inserat bearbeiten</div>
							<div onClick={deleteAd} style={{marginLeft: "10px"}} data-value={item.id} className="sum_text sum_edit">Inserat löschen</div>
						</div>
					</div>
					<div className="sum_columnPrice">
						<div className="sum_price"><strong>{isFlat ? "FLAT" : (index === 0 && !isFirst ? "99 €" : "49 €")}</strong></div>
					</div>
				</div>
			))}

			{cartInspo.map((item, index) => (
				<div key={item.id} className="sum_cartItem">
					<div className="sum_columnImg">
						{item.data().img[0].type === "img" ? 
							<img src={item.data().img[0].url}/> :
							<VideoThumbnail className="ca_thumbnail" videoUrl={item.data().img[0].url} />
						}
						
					</div>
					<div className="sum_column">
						<div className="sum_headline">INSERAT iVENTI INSPIRATION</div>
						<div className="sum_text">{item.data().title}</div>
						<div className="sum_text">Bereich: {item.data().servicesName}</div>
						<div className="sum_text">Unterkategorie: {item.data().service}</div>
						<div style={{display: "flex"}} >
							<div onClick={editInspo} data-value={item.id} className="sum_text sum_edit">Inserat bearbeiten</div>
							<div onClick={deleteInspo} style={{marginLeft: "10px"}} data-value={item.id} className="sum_text sum_edit">Inserat löschen</div>
						</div>
					</div>
					<div className="sum_columnPrice">
						<div className="sum_price"><strong>{isFlat ? "FLAT" : "49 €"}</strong></div>
					</div>
				</div>
			))}

				<div className="sum_cartItem">
					<div className="sum_summary">
						<div className="sum_columnPayment">
							<div className="sum_headlineSmall">RECHNUNGSADRESSE</div>
							<input value={company} onChange={(e) => setCompany(e.target.value)} className="textFieldUnderline" type="text" placeholder="Name/Firma"/>
							<input value={address} onChange={(e) => setAddress(e.target.value)} className="textFieldUnderline" type="text" placeholder="Adresse"/>
							<input value={(location === " " || location === "") ? null : location} onChange={(e) => setLocation(e.target.value)} className="textFieldUnderline" type="text" placeholder="Plz & Ort"/>
						</div>
						<div className="sum_columnCoupon">
							<div className="sum_headlineSmall">GUTSCHEIN VORHANDEN?</div>
							<div className="sum_couponHolder">
								<input value={code} onChange={(e) => setCode(e.target.value)} className="sum_coupon_textField" type="text" placeholder="Hier Code eingeben" />
								{!loadingCode ? 
								<div onClick={checkCode} className="sum_sumbmitCoupon">
									<img src={Checkmark} />
								</div>
								:
								<div className="sum_sumbmitCoupon">
									<CircularProgress style={{ color: "white", width: "31px", height: "31px" }} />
								</div>
								}
							</div>
							<div style={{marginTop: "20px"}} className="sum_headlineSmall">ZAHLUNGSMETHODE</div>
							<div className="sum_text">Kreditkarte</div>
						</div>
						<div className="sum_columnPriceSummary">
							<div className="sum_headlineSmall">SUMME</div>
							<div className="sum_priceList">
								<div>Netto-Gesamtpreis</div>
								<div>{price} €</div>
							</div>
							<div className="sum_priceList">
								<div>zzgl. 19% MwSt.</div>
								<div>{(price * 0.19).toFixed(2)} €</div>
							</div>
							<hr/>
							<div className="sum_priceList">
								<div><strong>Brutto-Gesamtpreis</strong></div>
								<div><strong>{(price * 1.19).toFixed(2)} €</strong></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="lineBreakTopWhite">
				<img style={{display: "block"}} src={TopShapeGrey} />
			</div>
			</>
			}
		</div>
		{(cart.length !== 0 || cartInspo.length !== 0) && 
		<>
			{loadingSubmit ? 
				<div className="submitFlex">
					<button className="loadingButton"><CircularProgress style={{ color: "white", width: "31px", height: "31px" }} /></button>
				</div>
				:
				<>
				<div style={{display: "flex", justifyContent: "center", alignItems: "center", padding: "25px 0 10px 0", backgroundColor: "white"}} className="legal">
					<input type="checkbox" ref={legal}/>
					<div style={{paddingLeft: "5px"}}>Ich akzeptiere die Datenschutzerklärung und AGB*</div>
				</div>
				<div className="submitFlex">
					<button onClick={submitCart} className="submitAdButton">JETZT KAUFEN</button> 
				</div>
				</>
			}
		</>
		}
		</>
		}
		</>


	)
}

export default Summary;
