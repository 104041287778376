import React, { useEffect, useState, useRef } from 'react'

//firebase
import { applyActionCode, verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import { auth } from './Firebase'
import Loader from './components/Loader';
import Header from './components/provider/Header';
import PopUp from './components/PopUp';

import { useNavigate } from 'react-router-dom'
import { CircularProgress } from '@mui/material';


function Verification() {

    const [error, setError] = useState("")

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(null)

    const passwordRef = useRef()
    const passwordRepeatRef = useRef()

    function getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    useEffect(() => {
        console.log("run")
        // Get the action to complete.
        const mode = getParameterByName('mode');
        // Get the one-time code from the query parameter.
        const actionCode = getParameterByName('oobCode');

        // Handle the user management action.
        switch (mode) {
            case 'resetPassword':
                // Display reset password handler and UI.
                setPage(2)

                break;
            case 'recoverEmail':
                // Display email recovery handler and UI.

                break;
            case 'verifyEmail':
                setPage(1)
                // Display email verification handler and UI.
                applyActionCode(auth, actionCode).then((resp) => {
                    // Email address has been verified.
                    navigate("/dashboard")
                    //window.location.href = "http://localhost:3000/dashboard"
                    // TODO: Display a confirmation message to the user.
                    // You could also provide the user with a link back to the app.
        
                    // TODO: If a continue URL is available, display a button which on
                    // click redirects the user back to the app via continueUrl with
                    // additional state determined from that URL's parameters.
                }).catch((error) => {
                    // Code is invalid or expired. Ask the user to verify their email address
                    // again.
                    setError("Der Link ist abgelaufen.")
                });
                break;
            default:
            // Error: invalid mode.
        }
    }, [])

    function resetPassword(){
        setLoading(true)
        if(passwordRef.current.value === ""){
            setError("Bitte wähle ein Passwort.")
        } else if(passwordRef.current.value !== passwordRepeatRef.current.value){
            setError("Passwörter stimmen nicht überein.")
        }
        // Get the action to complete.
        const mode = getParameterByName('mode');
        // Get the one-time code from the query parameter.
        const actionCode = getParameterByName('oobCode');

        verifyPasswordResetCode(auth, actionCode).then((email) => {
            const accountEmail = email;

            // TODO: Show the reset screen with the user's email and ask the user for
            // the new password.
            const newPassword = passwordRef.current.value;

            // Save the new password.
            confirmPasswordReset(auth, actionCode, newPassword).then((resp) => {
            // Password reset has been confirmed and new password updated.
            navigate('/login')
            // TODO: Display a link back to the app, or sign-in the user directly
            // if the page belongs to the same domain as the app:
            // auth.signInWithEmailAndPassword(accountEmail, newPassword);

            // TODO: If a continue URL is available, display a button which on
            // click redirects the user back to the app via continueUrl with
            // additional state determined from that URL's parameters.
            }).catch((error) => {
                setError("Es ist ein Fehler aufgetreten.")
                setLoading(false)

            // Error occurred during confirmation. The code might have expired or the
            // password is too weak.
            });
        }).catch((error) => {
            setError("Dein Link ist ungültig.")
            setLoading(false)

            // Invalid or expired action code. Ask user to try to reset the password
            // again.
        }); 

    }



    return (
        <div>
            {error && <PopUp message={error} setError={setError} />}
            <Header headline={"FAST GESCHAFFT"} />
            {page === null && <Loader />}
            {page === 1 && <>
                <Loader />
                <div style={{ lineHeight: "25px", fontWeight: "700", maxWidth: "1000px", width: "90%", margin: "auto", textAlign: "center", marginBottom: "50px" }}>Bestätigung wird ausgeführt. Du wirst im Anschluss weitergeleitet.</div>
            </>}
            {page === 2 && <>
                <div className="headlineNormal" style={{backgroundColor: "#F6F6F5"}}>Passwort zurücksetzen</div>
                <div style={{maxWidth: "90%", width: "500px", margin: "auto", paddingTop: "0px", textAlign: "center"}}>
					<input ref={passwordRef} className="textField" type="password" placeholder="Passwort*" />
                    <input ref={passwordRepeatRef} className="textField" type="password" placeholder="Passwort wiederholen*" />
				</div>
				<div style={{cursor: "pointer" ,width: "100%", display: "flex", alignItems: "center", margin: "20px 0 50px 0", flexDirection: "column"}}>
					{loading ? 
						<button className="loadingButton"><CircularProgress style={{ color: "white", width: "31px", height: "31px" }} /></button>
						:
						<button onClick={resetPassword} className="submitAdButton">SPEICHERN</button>
					}
                </div>
            </>}
        </div>
    )
}

export default Verification