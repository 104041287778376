import React from 'react'
import Header from './provider/Header'
import { Link } from 'react-router-dom'

function Datenschutz() {
  return (
    <div className="legal">
        <Header headline={"DATENSCHUTZ"} />
        <div style={{maxWidth: "1000px", width: "90%", margin: "auto", lineHeight: "25px", paddingBottom: "50px", wordBreak: "break-word"}} >
        <Link style={{textDecoration: "none", color: "rgb(249, 179, 0)", fontWeight: "700", marginBottom: "20px", display: "block"}} to="/dashboard/start">Zurück</Link>
        <p><strong>1 Allgemeines</strong></p>
<p>Wir informieren Sie nachfolgend gem&auml;&szlig; den gesetzlichen Vorgaben des Datenschutzrechts (insb. Gem&auml;&szlig; BDSG n.F. und der europ&auml;ischen Datenschutz-Grundverordnung, DSGVO) &uuml;ber die Art, den Umfang und Zweck der Verarbeitung personenbezogener Daten durch unser Unternehmen. Diese Datenschutzerkl&auml;rung gilt f&uuml;r unsere Website und auch f&uuml;r alle unsere Sozial-Media-Profile.</p>
<p>&nbsp;</p>
<p><strong>Verantwortliche Stelle im Sinne der Datenschutzbestimmungen ist.</strong></p>
<p>e.i. Group</p>
<p>Marcus Schmelzer</p>
<p>Friedlandstra&szlig;e 60</p>
<p>25451 Quickborn</p>
<p>&nbsp;</p>
<p>Der Schutz und die Sicherheit Ihrer Pers&ouml;nlichen Daten hat f&uuml;r uns hohe Priorit&auml;t. Wir erheben verarbeiten und nutzen ihren Daten gem&auml;&szlig; der europ&auml;ischen Datenschutzverordnung (DSGVO).</p>
<p>&nbsp;</p>
<p>&Auml;nderung dieser Datenschutzerkl&auml;rung:</p>
<p>Wir k&ouml;nnen diese Datenschutzerkl&auml;rung jederzeit &auml;ndern, indem wir die ge&auml;nderte Version auf der iVENTI Website ver&ouml;ffentlichen, einschlie&szlig;lich des Inkrafttretens der ge&auml;nderten Version. Wir werden dir per E Mail alle wesentlichen &Auml;nderungen dieser Datenschutzerkl&auml;rung bekannt geben.</p>
<p>&nbsp;</p>
<p><strong>2</strong>&nbsp;<strong>Welche personenbezogene Daten wir erheben.</strong></p>
<p>Wir erheben, verwenden und speichern personenbezogene Daten von dir und den Endger&auml;ten (auch Mobilger&auml;te), die Du durch das einrichten Deines pers&ouml;nlichen Accounts vermittelst. Einige deiner personenbezogenen Daten, wie dein Name und deine e Mail Adresse sind erforderlich, um ein iVENTI Account f&uuml;r Anbieter er&ouml;ffnen zu k&ouml;nnen.</p>
<p>&nbsp;</p>
<p><strong>2 a. Datenverarbeitung im Rahmen eines Vertragsverh&auml;ltnisses.</strong></p>
<p>&nbsp;Schalten sie eine Anzeige auf dem iVENTI.de Portal, so erheben, verarbeiten und speichern wir im Rahmen dieses Vertragsverh&auml;ltnisses folgende Daten zu folgenden Zwecken:</p>
<ul>
<li>Einrichten und verwalten ihres Benutzerkontos</li>
<li>Im Rechercheservice werden ihre Suchanforderungen, ihr Standort, ihr Name / Firma und ihre Mailadresse erhoben und gespeichert.</li>
</ul>
<p>&nbsp;</p>
<p><strong>3 Datenverarbeitung bei Besuch der iVENTI Website</strong>&nbsp;</p>
<p>Beim Aufrufen unserer Website <a href="http://www.iventi.de">www.iventi.de</a> werden durch den auf ihrem Endger&auml;t zum Einsatz kommenden Browser automatisch Informationen an den Server unserer Website gesendet. Diese Informationen werden tempor&auml;r in einem sog. Logfile gespeichert. Folgende Informationen werden dabei ohne ihr Zutun erfasst und bis zur automatisierten L&ouml;schung gespeichert:</p>
<p>&nbsp;</p>
<p>IP-Adresse des anfragenden Rechners&nbsp;</p>
<p>Datum und Uhrzeit des Zugriffs&nbsp;&nbsp;</p>
<p>Name und URL der abgerufenen Datei</p>
<p>Website, von der aus der Zugriff erfolgt (Referrer-URL)</p>
<p>Verwendeter Browser und ggf. das Betriebssystem ihres Rechners sowie der Name ihres Access-Provider</p>
<p>Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:</p>
<p>&nbsp;</p>
<p>Gew&auml;hrleistung eines reibungslosen Verbindungsaufbaus der Website</p>
<p>Gew&auml;hrleistung einer komfortablen Nutzung unserer Website Auswertung der Systemsicherheit und -stabilit&auml;t sowie zu weiteren administrativen Zwecken</p>
<p>Die Rechtsgrundlage f&uuml;r die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser berechtigtes Interesse folgt aus oben genannten Zwecken zur Datenerhebung. In keinem Fall verwenden wir die erhobenen Daten um R&uuml;ckschl&uuml;sse auf ihre Person zu ziehen.</p>
<p>Dar&uuml;ber hinaus setzen wir beim Besuch unserer Website Cookies sowie Analysedienste ein.</p>
<p>&nbsp;</p>
<p><strong>4 Betroffenenrechte</strong>&nbsp;</p>
<p>Sie haben das Recht:&nbsp;</p>
<ul>
<li>gem&auml;&szlig; Art. 15 DSGVO Auskunft &uuml;ber ihre von uns verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere k&ouml;nnen sie Auskunft &uuml;ber die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empf&auml;ngern, gegen&uuml;ber denen ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, L&ouml;schung, Einschr&auml;nkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie &uuml;ber das Bestehen einer automatisierten Entscheidungsfindung einschlie&szlig;lich Profiling und ggf. aussagekr&auml;ftigen Informationen zu deren Einzelheiten verlangen;</li>
<li>gem&auml;&szlig; Art. 16 DSGVO unverz&uuml;glich die Berechtigung unrichtiger oder Vervollst&auml;ndigung ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;&nbsp;&nbsp;</li>
<li>gem&auml;&szlig; Art. 17 DSGVO die L&ouml;schung ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur Aus&uuml;bung des Rechts auf freie Meinungs&auml;u&szlig;erung und Information, zur Erf&uuml;llung einer rechtlichen Verpflichtung, aus Gr&uuml;nden des &ouml;ffentlichen Interesses oder zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen erforderlich ist;</li>
<li>gem&auml;&szlig; Art. 18 DSGVO die Einschr&auml;nkung der Verarbeitung ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten von ihnen bestritten wird, die Verarbeitung unrechtm&auml;&szlig;ig ist, sie aber deren L&ouml;schung ablehnen und wir die Daten nicht mehr ben&ouml;tigen, sie jedoch diese zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen ben&ouml;tigen oder sie gem&auml;&szlig; Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;</li>
<li>gem&auml;&szlig; Art. 20 DSGVO ihre personenbezogenen Daten, die sie uns bereitgestellt haben, in einem strukturierten, g&auml;ngigen und maschienenlesbaren Format zu erhalten oder die &Uuml;bermittlung an einen anderen Verantwortlichen zu verlangen;</li>
<li>gem&auml;&szlig; Art. 7 Abs. 3 DSGVO ihre einmal erteilte Einwilligung jederzeit gegen&uuml;ber uns zu widerrufen. Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, f&uuml;r die Zukunft nicht mehr fortf&uuml;hren d&uuml;rfen und</li>
<li>gem&auml;&szlig; Art 77 DSGVO sich bei einer Aufsichtsbeh&ouml;rde ihres &uuml;blichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Kanzleisitzes wenden.</li>
</ul>
<p>&nbsp;</p>
<p><strong>5 Weitergabe von Daten</strong>&nbsp;</p>
<p>Eine &Uuml;bermittlung ihrer pers&ouml;nlichen Daten an Dritte zu anderen als den Folgenden aufgef&uuml;hrten Zwecken findet nicht statt.</p>
<p>Wir geben ihre pers&ouml;nlichen Daten nur an dritte weiter; wenn:</p>
<ul>
<li>Sie ihre nach Art. 6 Abs. S. 1 lit. A DSGVO ausdr&uuml;ckliche Einwilligung dazu erteilen,</li>
<li>die Weitergabe nach Art. 6 Abs. 1. S. 1 lit. F DSGVO zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen erforderlich ist und kein Grund zur Annahme besteht, dass sie ein &Uuml;berwiegendes schutzw&uuml;rdiges Interesse an der Nichtweitergabe ihrer Daten haben,</li>
<li>f&uuml;r den Fall, dass f&uuml;r die Weitergabe nach Art. 6 Abs. 1 S lit. C DSGVO eine gesetzliche Verpflichtung besteht, sowie</li>
<li>dies gesetzlich zul&auml;ssig und nach Art. 6 Abs. 1 S 1 lit. B DSGVO f&uuml;r die Abwicklung von Vertragsverh&auml;ltnissen mit ihnen erforderlich ist.</li>
</ul>
<p>&nbsp;</p>
<p><strong>6 Kontaktformular</strong>&nbsp;&nbsp;</p>
<p>Sie k&ouml;nnen &uuml;ber ein auf der Website bereitgestelltes Formular Kontakt mit einem Dienstleister/Anbieter direkt aufnehmen. Hierzu ist die Angabe einer g&uuml;ltigen E-Mail-Adresse erforderlich, um diese beantworten zu k&ouml;nnen. Weitere Angaben k&ouml;nnen freiwillig get&auml;tigt werden. Die Datenverarbeitung zum Zwecke der Kontaktaufnahme mit dem Dienstleister/Anbieter erfolgt gleichzeitig auch in Kopie &uuml;ber und nach Art. 6 Abs. 1 S. 1 lit. a DSGVO auf Grundlage ihrer freiwillig erteilten Einwilligung. Die f&uuml;r die Benutzung des Kontaktformulars von uns erhobenen personenbezogenen Daten werden nach Erledigung der von Ihnen gestellten Anfrage automatisch gel&ouml;scht.</p>
<p>&nbsp;</p>
<p><strong>7 Dauer der Personenbezogenen Datenspeicherung.</strong></p>
<p>Die Dauer der Speicherung von personenbezogenen Daten bemisst sich anhand der jeweiligen Rechtsgrundlage, am Verarbeitungszweck und &ndash; sofern einschl&auml;gig &ndash; zus&auml;tzlich anhand der jeweiligen gesetzlichen Aufbewahrungsfrist (z.B. handels- und steuerrechtliche Aufbewahrungsfristen).</p>
<p>Bei der Verarbeitung von personenbezogenen Daten auf Grundlage einer ausdr&uuml;cklichen Einwilligung gem&auml;&szlig; Art. 6 Abs. 1 lit. a DSGVO werden diese Daten so lange gespeichert, bis der Betroffene seine Einwilligung widerruft.</p>
<p>Existieren gesetzliche Aufbewahrungsfristen f&uuml;r Daten, die im Rahmen rechtsgesch&auml;ftlicher bzw. rechtsgesch&auml;fts&auml;hnlicher Verpflichtungen auf der Grundlage von Art. 6 Abs. 1 lit. b DSGVO verarbeitet werden, werden diese Daten nach Ablauf der Aufbewahrungsfristen routinem&auml;&szlig;ig gel&ouml;scht, sofern sie nicht mehr zur Vertragserf&uuml;llung oder Vertragsanbahnung erforderlich sind und/oder unsererseits kein berechtigtes Interesse an der Weiterspeicherung fortbesteht.</p>
<p>Bei der Verarbeitung von personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO werden diese Daten so lange gespeichert, bis der Betroffene sein Widerspruchsrecht nach Art. 21 Abs. 1 DSGVO aus&uuml;bt, es sei denn, wir k&ouml;nnen zwingende schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung nachweisen, die die Interessen, Rechte und Freiheiten der betroffenen Person &uuml;berwiegen, oder die Verarbeitung dient der Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen.</p>
<p>Bei der Verarbeitung von personenbezogenen Daten zum Zwecke der Direktwerbung auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO werden diese Daten so lange gespeichert, bis der Betroffene sein Widerspruchsrecht nach Art. 21 Abs. 2 DSGVO aus&uuml;bt.</p>
<p>Sofern sich aus den sonstigen Informationen dieser Erkl&auml;rung &uuml;ber spezifische Verarbeitungssituationen nichts anderes ergibt, werden gespeicherte personenbezogene Daten im &Uuml;brigen dann gel&ouml;scht, wenn sie f&uuml;r die Zwecke, f&uuml;r die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig sind.</p>
<p>&nbsp;</p>
<p><strong>8 Anfrage per Mail, Telefon</strong></p>
<p>Wenn sie uns per E-Mail oder Telefon kontaktieren, wird ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne ihre Einwilligung weiter.</p>
<p>Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern ihre Anfrage mit der Erf&uuml;llung eines Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen &uuml;brigen F&auml;llen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.</p>
<p>Die von ihnen an uns per Kontaktanfragen &uuml;bersandten Daten verbleiben bei uns, bis sie uns zur L&ouml;schung auffordern, ihre Einwilligung zur Speicherung widerrufen oder der Zweck f&uuml;r die Datenspeicherung entf&auml;llt (z. B. nach abgeschlossener Bearbeitung ihres Anliegens). Zwingende gesetzliche Bestimmungen &ndash; insbesondere gesetzliche Aufbewahrungsfristen &ndash; bleiben unber&uuml;hrt.</p>
<p>&nbsp;</p>
<p><strong>9 K&uuml;ndigung des Anbietervertrages</strong></p>
<ul>
<li>Der Anbieter kann seinen Inseratvertrag jederzeit, auch innerhalb der laufenden Vertragszeit ohne Angaben von Gr&uuml;nden fristlos zum Ende seiner Vertragslaufzeit gegen&uuml;ber iVENTI k&uuml;ndigen. Der Vertrag kann von dem Anbieter durch die L&ouml;schung seines Anbieterkontos oder in Textform (z.B. Mail oder Post) gegen&uuml;ber iVENTI fristgerecht oder vorzeitig gek&uuml;ndigt werden.</li>
<li>iVENTI kann den Anbietervertrag jederzeit, ohne Angabe von Gr&uuml;nden mit einer Frist von einem Monat zum Vertragsende k&uuml;ndigen.</li>
<li>Das Recht zur au&szlig;erordentlichen K&uuml;ndigung f&uuml;r beide Seiten bleibt unber&uuml;hrt.</li>
<li>Nach Wirksamwerden der K&uuml;ndigung kann der Anbieter bis zum Ender der Vertragslaufzeit sein Account vollwertig nutzen. Das Kundenkonto wird automatisch nach Ablauf der Vertragszeit gel&ouml;scht. Der Anbieter kann in der Folge keine Einsicht mehr in sein Account nehmen.</li>
<li>K&uuml;ndigungen nach dem Ziffern 2 und 3 bed&uuml;rfen zu ihrer Wirksamkeit der Textform.</li>
<li>Der Anbietervertrag f&uuml;r die iVenti Plattform gilt immer f&uuml;r unbestimmte Zeit, die Laufzeit f&uuml;r den Vertrag &uuml;ber die Pr&auml;sentation eines Eintrages eines Dienstleisters auf der Plattform bemisst sich nach dem gew&auml;hlten Leistungspaket in einem festen Zeitraum von einem Jahr.</li>
</ul>
<p>&nbsp;</p>
<p><strong>10 Kontaktaufnahme</strong></p>
<p>Mit einer zu iVENTI bezogenen Kontaktaufnahme durch das Kontaktformular werden personenbezogene Daten erhoben. Welche Daten im Falle der Nutzung eines Kontaktformulars erhoben werden, ist aus dem vorgegebenen Kontaktformular ersichtlich. Diese Daten werden ausschlie&szlig;lich zum Zweck der Beantwortung ihres Anliegens bzw. f&uuml;r die Kontaktaufnahme und die damit verbundene technische Administration gespeichert und verwendet. Rechtsgrundlage f&uuml;r die Verarbeitung dieser Daten ist unser berechtigtes Interesse an der Beantwortung ihres Anliegens gem&auml;&szlig; Art. 6 Abs 1 lit. F DSGVO. Zielt ihre Kontaktierung auf den Abschluss eines Vertrages ab, so ist zus&auml;tzlich Rechtsgrundlage f&uuml;r die Verarbeitung Art. 6 1 lit. B DSGVO. Ihre Daten werden nach abschlie&szlig;ender Berarbeitung ihrer Anfrage gel&ouml;scht. Dies ist der Fall, wenn sich aus den Umst&auml;nden entnehmen l&auml;sst, dass der betroffene Sachverhalt abschlie&szlig;end gekl&auml;rt ist und sofern keine gesetzlichen Aufbewahrungspflichten entgegenstehen.</p>
<p>&nbsp;</p>
<p><strong>11 Cookies</strong></p>
<p>Wir setzen auf unserer Seite Cookies ein. Hierbei handelt es sich um kleine Dateien, die ihr Browser automatisch erstellt und die auf ihrem Endger&auml;t (PC, Laptop, Smartphone o.&auml;.) gespeichert werden, wenn sie unsere Seite besuchen. Cookies richten auf ihrem Endger&auml;t keinen Schaden an, enthalten keine Viren, Trojaner oder sonstige Schadsoftware.</p>
<p>In dem Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem spezifisch eingesetzten Endger&auml;t ergeben. Dies bedeutet jedoch nicht, dass wir dadurch unmittelbar Kenntnis von ihrer Identit&auml;t erhalten.</p>
<p>Der Einsatz von Cookies dient einerseits dazu, die Nutzung unseres Angebots f&uuml;r sie angenehmer zu gestalten. So setzen wir sogenannte Sessions-Cookies ein, um zu erkennen, dass Sie einzelne Seiten unserer Website bereits besucht haben. Diese werden nach Verlassen unserer Seite automatisch gel&ouml;scht.</p>
<p>Dar&uuml;ber hinaus setzen wir ebenfalls zur Optimierung der Benutzerfreundlichkeit tempor&auml;re Cookies ein, die f&uuml;r einen bestimmten festgelegten Zeitraum auf ihrem Endger&auml;t gespeichert werden. Besuchen sie unsere Seite erneut, um unsere Dienste in Anspruch zu nehmen, wird automatisch erkannt, dass sie bereits bei uns waren und welche Eingaben und Einstellungen sie get&auml;tigt haben, um diese nicht noch einmaleingeben zu m&uuml;ssen.</p>
<p>Zum anderen setzen wir Cookies ein, um die Nutzung unserer Website statistisch zu erfassen und zum Zwecke der Optimierung unseres Angebotes f&uuml;r sie auszuwerten. Die Cookies erm&ouml;glichen es uns, bei einem erneuten Besuch unsere Seite automatisch zu erkennen, dass sie bereits bei uns waren. Diese Cookies werden nach einer jeweils definierten Zeit automatisch gel&ouml;scht.</p>
<p>Die durch Cookies verarbeiteten Daten sind f&uuml;r die genannten Zwecke zur Wahrung unserer berechtigten Interessen sowie der Dritter nach Art 1S. 1 lit. F DSGVO erforderlich.</p>
<p>Viele Browser akzeptieren Cookies automatisch. Sie k&ouml;nnen ihren Browser jedoch so konfigurieren, dass keine Cookies auf ihrem Computer gespeichert werden oder stets ein Hinweis erscheint, bevor ein neuer Cookie angelegt wird. Die vollst&auml;ndige Deaktivierung von Cookies kann jedoch dazu f&uuml;hren, dass sie nicht alle Funktionen unserer iVENTI Website nutzen k&ouml;nnen.</p>
<p>&nbsp;</p>
<p><strong>12 Social Media</strong>&nbsp;</p>
<p>Wir setzen auf unserer iVENTI Website auf Grundlage des Art. 6 Abs 1 lit. F DSGVO SocialPlugins der sozialen Netzwerke Facebook, Instagram, Tik Tok und Xing ein, um die Marke iVENTI hier&uuml;ber bekannter zu machen. Der dahinterstehende werbliche Zweck ist als berechtigtes Interesse im Sinne der DSVGO anzusehen. Die Verantwortung f&uuml;r den datenschutzkonformen Betrieb ist durch deren jeweiligen Anbieter zu gew&auml;hrleisten. Die Einbindung dieser Plug-ins durch uns erfolgt im Wege der sogenannten Shariff-Methode um Besucher unserer Website bestm&ouml;glich zu sch&uuml;tzen.</p>
<p>&nbsp;</p>
<p><strong>12 a. Facebook</strong></p>
<p>Auf unserer iVENTI Website kommen Social-Media Plugins von Facebook zum Einsatz, um deren Nutzung pers&ouml;nlicher zu gestalten. Hierf&uuml;r nutzen wir den &bdquo;LIKE&ldquo; oder TEILEN&ldquo;-Button. Es handelt sich dabei um ein Angebot von Facebook.</p>
<p>Wenn Sie eine Seite unseres Webauftritts aufrufen, die ein solches Plugin enth&auml;lt, baut ihr Browser eine direkte Verbindung mit dem Servern von Facebook aus. Der Inhalt des Plugins wird von Facebook direkt an ihren Browser &uuml;bermittelt und von diesem in die Website eingebunden.</p>
<p>Die durch Cookies verarbeiteten Daten sind f&uuml;r die genannten Zwecke zur Wahrung unserer berechtigten Interessen sowie der Dritter nach Art 1S. 1 lit. F DSGVO erforderlich.</p>
<p>Viele Browser akzeptieren Cookies automatisch. Sie k&ouml;nnen ihren Browser jedoch so konfigurieren, dass keine Cookies auf ihrem Computer gespeichert werden oder stets ein Hinweis erscheint, bevor ein neuer Cookie angelegt wird. Die vollst&auml;ndige Deaktivierung von Cookies kann jedoch dazu f&uuml;hren, dass sie nicht alle Funktionen unserer iVENTI Website nutzen k&ouml;nnen.</p>
<p>Durch die Einbindung der Plugins erh&auml;lt Facebook die Information, dass ihr Browser die entsprechende Seite unseres Webauftritts aufgerufen hat. Auch wenn sie kein Facebook-Konto besitzen oder gerade nicht bei Facebook eingeloggt sind. Diese Information (einschlie&szlig;lich ihrer IP Adresse) wird von ihrem Browser direkt an einen Server von Facebook in den USA &uuml;bermittelt und dort gespeichert.</p>
<p>Sind sie bei Facebook eingeloggt, kann Facebook den Besuch unserer iVENTI Website ihrem Facebook-Konto direkt zuordnen. Wenn sie mit dem Plugins interagieren, zum Beispiel den &bdquo;LIKE oder &bdquo;TEILEN&ldquo;-Button bet&auml;tigen, wird die entsprechende Information ebenfalls direkt an einen Server von Facebook &uuml;bermittelt und dort gespeichert. Die Informationenwerden zudem auf Facebook ver&ouml;ffentlicht und ihren Facebook-Freunden angezeigt.</p>
<p>Facebook kann diese Informationen zum Zwecke der Werbung, Marktforschung und bedarfsgerechten Gestaltung der Facebook-Seiten benutzen. Hierzu werden Facebook Nutzungs-, Interessen- und Beziehungsprofile erstellt, z.B. um ihre Nutzung unserer Website im Hinblick auf die ihnen bei Facebook eingeblendeten Werbeanzeigen auszuwerten, andere Facebook-Nutzer &uuml;ber ihre Aktivit&auml;ten auf unserer iVENTI Website zu informieren und um weitere mit der Nutzung von Facebook verbundene Dienstleistungen zu erbringen.</p>
<p>Wenn sie nicht m&ouml;chten, dass Facebook die &uuml;ber unserem Webauftritt gesammelten Daten ihrem Facebook-Konto zuordnet, m&uuml;ssen sie sich vor ihrem Besuch unserer iVENTI Website bei Facebook ausloggen.</p>
<p>Zwecks und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Facebook sowie ihre diesbez&uuml;glichen Rechte und Einstellungsm&ouml;glichkeiten zum Schutz ihrer Privatsph&auml;re entnehmen sie bitte den Datenschutzhinweisen von Facebook. <a href="https://www.facebook.com/about/privacy/">https://www.facebook.com/about/privacy/</a>).</p>
<p>&nbsp;</p>
<p><strong>12 b. Instagram</strong></p>
<p>Auf unserer Website werden auch sogenannte Social Plugins von INSTAGRAM verwendet, das von der Instagram LLC Willow Road, Menlo Park, CA 94025, USA betrieben wird.</p>
<p>Die Plugins sind mit einem Istagram-Logo beispielweise in Form einer &bdquo;Instgram-Logo&ldquo; gekennzeichnet.</p>
<p>Wenn sie eine Seite unseres Webauftritts aufrufen, die ein solches Plugin enth&auml;lt, stellt ihr Browser eine direkte Verbindung zu den Servern von Instagram her. Der Inhalt des Plugins wird von Instagram direkt an ihren Browser &uuml;bermittelt und in die Seite eingebunden. Durch diese Einbindung erh&auml;lt Instagram die Informationen, dass ihr Browser die entsprechende Seite unseres Webauftritts aufgerufen hat, auch wenn sie kein Instagram- Profil besitzen oder gerade nicht bei Instagram eingeloggt sind.</p>
<p>Diese Informationen (einschlie&szlig;lich ihrer&nbsp; IP-Adresse) wird von ihrem Browser direkt an einen Server von Instagram in die USA &uuml;bermittelt und dort gespeichert. Sind sie bei Instagram eingeloggt, kann Instagram den Besuch der iVENTI Website ihrem Instagram-Account unmittelbar zuordnen. Wenn sie mit dem Plugins interagieren, zum Beispiel das &bdquo;Instagram&ldquo;-Button bet&auml;tigen, wird diese Information ebenfalls direkt an einen Server von Instagram &uuml;bermittelt und dort gespeichert.</p>
<p>Die Informationen werden au&szlig;erdem auf ihrem Instagram-Account ver&ouml;ffentlicht und dort ihrem Kontakten angezeigt.</p>
<p>Wenn sie nicht m&ouml;chten, dass Instagram die &uuml;ber unseren Webauftritt gesammelten Daten unmittelbar ihrem Instagram-Account zuordnet, m&uuml;ssen sie sich vor ihrem Besuch unserer iVENTI Website bei Instagram ausloggen.</p>
<p>Weitere Informationen hierzu finden sie in der Datenschutzerkl&auml;rung von Instagram.</p>
<p>(<a href="https://help.instagram.com/155833707900388">https://help.instagram.com/155833707900388</a>).</p>
<p>&nbsp;</p>
<p><strong>12 c. Xing</strong></p>
<p>Auf unserer Website werden auch sogenannte Social Plugins von Xing verwendet, das von der Xing SE, Damtorstra&szlig;e 30, 20354 Hamburg betrieben wird.</p>
<p>Die Plugins sind mit einem Xing-Logo gekennzeichnet.</p>
<p>Wenn sie eine Seite unseres Webauftritts aufrufen, die ein solches Plugin enth&auml;lt, stellt ihr Browser eine direkte Verbindung zu den Servern von Xing her. Der Inhalt des Plugins wird von Xing direkt an ihren Browser &uuml;bermittelt und in die Seite eingebunden. Durch diese Einbindung erh&auml;lt Xing die Informationen, dass ihr Browser die entsprechende Seite unseres Webauftritts aufgerufen hat, auch wenn sie kein Xing- Profil besitzen oder gerade nicht bei Xing eingeloggt sind.</p>
<p>Diese Informationen (einschlie&szlig;lich ihrer&nbsp; IP-Adresse) wird von ihrem Browser direkt an einen Server &uuml;bermittelt und dort gespeichert. Sind sie bei Xing eingeloggt, kann Xing den Besuch der ihrer Website ihrem Xing-Account unmittelbar zuordnen. Wenn sie mit den Plugin interagieren, wird die entsprechende Information ebenfalls direkt an einen Server von Xing &uuml;bermittelt und dort gespeichert. Die Informationen werden zudem auf Xing ver&ouml;ffentlicht und Xing-Kontakten angezeigt.</p>
<p>Wenn sie nicht m&ouml;chten, dass Xing die &uuml;ber unseren Webauftritt gesammelten Daten unmittelbar ihrem Xing-Account zuordnet, m&uuml;ssen sie sich vor ihrem Besuch unserer iVENTI Website bei Xing ausloggen.</p>
<p>Weitere Informationen hierzu finden sie in der Datenschutzerkl&auml;rung von Xing.</p>
<p>(<a href="https://www.xing.com/privacy?ttt_webview=iosm">https://www.xing.com/privacy?ttt_webview=iosm</a>)</p>
<p>&nbsp;</p>
<p><strong>12 d Youtube-Videos</strong></p>
<p>Auf unserer Website werden auch Youtube Funktionen und Wiedergaben von Videos des Anbieters angezeigt, dass zu der Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland (Google) geh&ouml;rt.</p>
<p>Hierbei wird der erweiterte Datenschutzmodus verwendet, der nach Anbieterangaben eine Speicherung von Nutzerinformationen erst bei Wiedergabe des/der Videos in Gang setzt. Wird die Wiedergabe eingebetteter Youtube-Videos gestartet, setzt der Anbieter Youtube Cookies ein, um Informationen &uuml;ber das Nutzerverhalten zu sammeln. Hinweisen von Youtube zufolge dienen diese unter anderem dazu, Videostatistiken zu erfassen, die Nutzerfreundlichkeit zu verbessern und missbr&auml;uchliche Handlungsweisen zu unterbinden. Wenn sie bei Google eingeloggt sind, werden ihre Daten direkt ihrem Konto zugeordnet, wenn sie ein Video anklicken. Wenn sie die Zuordnung mit ihrem Profil bei Youtube nicht w&uuml;nschen, m&uuml;ssen sie sich vor Aktivierung des Buttons ausloggen. Google speichert ihre Daten, dass gilt auch f&uuml;r nicht eingeloggte Nutzer, als Nutzungsprofile und wertet diese aus. Eine solche Auswertung erfolgt insbesondere gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO auf Basis der berechtigten Interessen von Google an der Einblendung personalisierter Werbung, Marktforschung und/oder bedarfsgerechten Gestaltung seiner Website. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung dieser Nutzerprofile, wobei sie sich zur Aus&uuml;bung dessen an YouTube richten m&uuml;ssen. Im Rahmen der Nutzung von Youtube kann es auch zu einer &Uuml;bermittlung von personenbezogenen Daten an die Server der Google LLC. in den USA kommen.<br /> Unabh&auml;ngig von einer Wiedergabe der eingebetteten Videos wird bei jedem Aufruf dieser Website eine Verbindung zum Google-Netzwerk aufgenommen, was ohne unseren Einfluss weitere Datenverarbeitungsvorg&auml;nge ausl&ouml;sen kann.</p>
<p>Weitere Informationen zum Datenschutz bei &bdquo;Youtube&ldquo; finden sie in den Youtube-Nutzungsbedingungen unter https://www.youtube.com/static?template=terms sowie in der Datenschutzerkl&auml;rung von Google unter https://www.google.de/intl/de/policies/privacy.</p>
<p>Soweit rechtlich erforderlich, haben wir zur vorstehend dargestellten Verarbeitung Ihrer Daten Ihre Einwilligung gem&auml;&szlig; Art. 6 Abs. 1 lit. a DSGVO eingeholt. Sie k&ouml;nnen Ihre erteilte Einwilligung jederzeit mit Wirkung f&uuml;r die Zukunft widerrufen. Um Ihren Widerruf auszu&uuml;ben, deaktivieren sie diesen Dienst im auf der Webseite bereitgestellten &bdquo;Cookie-Consent-Too.</p>
<p>&nbsp;</p>
<p><strong>13 Newsletter</strong></p>
<p>Wenn sie den auf der Website angebotenen Newsletter beziehen m&ouml;chten, ben&ouml;tigen wir von Ihnen eine E-Mail-Adresse sowie Informationen, welche uns die &Uuml;berpr&uuml;fung gestatten, dass sie der Inhaber der angegebenen E-Mail-Adresse sind und mit dem Empfang des Newsletters einverstanden sind. Weitere Daten werden nicht bzw. nur auf freiwilliger Basis erhoben. Diese Daten verwenden wir ausschlie&szlig;lich f&uuml;r den Versand der angeforderten Informationen und geben diese nicht an Dritte weiter.</p>
<p>Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen Daten erfolgt ausschlie&szlig;lich auf Grundlage ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des Newsletters k&ouml;nnen sie jederzeit widerrufen, etwa &uuml;ber den &bdquo;Austragen&ldquo;-Link im Newsletter. Die Rechtm&auml;&szlig;igkeit der bereits erfolgten Datenverarbeitungsvorg&auml;nge bleibt vom Widerruf unber&uuml;hrt.</p>
<p>Die von ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter bei uns bzw. dem Newsletterdiensteanbieter gespeichert und nach der Abbestellung des Newsletters oder nach Zweckfortfall aus der Newsletterverteilerliste gel&ouml;scht. Wir behalten uns vor, E-Mail-Adressen aus unserem Newsletterverteiler nach eigenem Ermessen im Rahmen unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO zu l&ouml;schen oder zu sperren.</p>
<p>Nach ihrer Austragung aus der Newsletterverteilerliste wird Ihre E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf. in einer Blacklist gespeichert, um k&uuml;nftige Mailings zu verhindern. Die Daten aus der Blacklist werden nur f&uuml;r diesen Zweck verwendet und nicht mit anderen Daten zusammengef&uuml;hrt. Dies dient sowohl ihrem Interesse als auch unserem Interesse an der Einhaltung der gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die Speicherung in der Blacklist ist zeitlich nicht befristet.&nbsp;Sie k&ouml;nnen der Speicherung widersprechen, sofern Ihre Interessen unser berechtigtes Interesse &uuml;berwiegen.</p>
<p>&nbsp;</p>
<p><strong>14</strong>&nbsp;<strong>Google Maps</strong></p>
<p>Diese Seite nutzt den Kartendienst Google Maps. Anbieter ist die Google Ireland Limited (&bdquo;Google&ldquo;), Gordon House, Barrow Street, Dublin 4, Irland.</p>
<p>Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP-Adresse zu speichern. Diese Informationen werden in der Regel an einen Server von Google in den USA &uuml;bertragen und dort gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf diese Daten&uuml;bertragung. Wenn Google Maps aktiviert ist, kann Google zum Zwecke der einheitlichen Darstellung der Schriftarten Google Web Fonts verwenden. Beim Aufruf von Google Maps l&auml;dt ihr Browser die ben&ouml;tigten Web Fonts in ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.</p>
<p>Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote und an einer leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.</p>
<p>Die Daten&uuml;bertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gest&uuml;tzt. Details finden sie hier:&nbsp;<a href="https://privacy.google.com/businesses/gdprcontrollerterms/">https://privacy.google.com/businesses/gdprcontrollerterms/</a>&nbsp;und&nbsp;<a href="https://privacy.google.com/businesses/gdprcontrollerterms/sccs/">https://privacy.google.com/businesses/gdprcontrollerterms/sccs/</a>.</p>
<p>Mehr Informationen zum Umgang mit Nutzerdaten finden sie in der Datenschutzerkl&auml;rung von Google:&nbsp;<a href="https://policies.google.com/privacy?hl=de">https://policies.google.com/privacy?hl=de</a>.</p>
<p>&nbsp;</p>
<p><strong>15</strong>&nbsp;<strong>Nutzung von Google Firebase</strong></p>
<p><em>Firestore</em></p>
<p>Wir nutzen Firestore, um die Daten, die bei der Nutzung unserer Apps anfallen, sicher zu speichern. Ohne die Speicherung der Daten w&uuml;rden s&auml;mtliche Nutzer- und Profildaten verloren gehen und ein erneutes Log-In w&auml;re nicht m&ouml;glich. Das Speichern der Daten in Firestore ist ferner f&uuml;r die Aus&uuml;bung jeglicher Interaktionen der User untereinander innerhalb der Apps unentbehrlich. Die Datenspeicherung ist f&uuml;r die Funktionsf&auml;higkeit unserer Apps somit notwendig. Weitergehend dient die Speicherung der Nutzerdaten der Verhinderung von Betrugs- und Manipulationsversuchen von Dritten. Die Funktionsf&auml;higkeit des Dienstes, seine Weiterentwicklung sowie das Sicherstellen der Integrit&auml;t und Sicherheit unserer informationstechnischen Systeme sind berechtigte Interessen im Sinne des Art. 6 Abs. 1 lit. f DSGVO. Die Verarbeitung in Form der Speicherung erfolgt somit mit Rechtsgrund.</p>
<p>Die Datenschutzerkl&auml;rung von Google finden Nutzer/Nutzerinnen unter&nbsp;<a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>.</p>
<p>&nbsp;</p>
<p><em>Authentication</em></p>
<p>Wir nutzen in unseren Apps Google Firebase Authentication, einen Dienst der Google Ireland Limited, Gordon House, 4 Barrow Street, D04 E5W5 Dublin, Irland (&bdquo;Google&ldquo;). Firebase Authentication ist ein von Google bereitgestellter Anmelde- und Authentifizierungsdienst. Um den Anmelde- und Authentifizierungsprozess zu vereinfachen, kann Firebase Authentication Identit&auml;tsdienste von Drittparteien benutzen und die Informationen auf ihrer Plattform speichern. In Bezug auf die m&ouml;gliche Datenerhebung bei Drittparteien weisen wir auf 7.2. und 7.3. hin, wo die jeweils erhobenen Daten aufgef&uuml;hrt werden. Firebase Authentication fungiert somit als Mittler der Datenerhebung, Dritten und uns. Firebase Authentication speichert die erhobenen Daten in unserem Auftrag, sodass wir jene f&uuml;r die Apps nutzen und verarbeiten k&ouml;nnen. In Bezug auf den Auftragsverarbeitungsvertrag im Sinne des Art. 28 DSGVO halten wir die strengen gesetzlichen Vorgaben f&uuml;r einen solchen Vertrag mit Firebase Authentication ein.<br />Es handelt sich um folgende erhobene personenbezogene Daten: Nutzername, Profilbild und E-Mail-Adresse. Bei der Anmeldung mittels SMS-Identifikation wird ebenfalls die zugeh&ouml;rige Mobilnummer erhoben.<br />In diesen Verarbeitungsf&auml;llen erfolgt die Verarbeitung aufgrund Ihrer Einwilligung gem&auml;&szlig; Art. 6 Abs. 1 lit. a in Verbindung mit Art. 7 DSGVO. Die Verarbeitung erfolgt somit mit Rechtsgrund.<br />Weitere Informationen zum Datenschutz k&ouml;nnen den Datenschutzbedingungen https://policies.google.com/privacy von Google entnommen werden.</p>
<p>&nbsp;</p>
<p><strong>16</strong>&nbsp;<strong>Widerspruchsrecht</strong></p>
<p>Sofern ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gem&auml;&szlig; Art. 6 Abs 1 S. 1 lit. F DSVGO verarbeitet werden, haben sie das Recht, gem&auml;&szlig; Art. 21 DSGVO Widerspruch gegen die Verarbeitung ihrer personenbezogenen Daten einzulegen, soweit daf&uuml;r Gr&uuml;nde vorliegen, die sich aus ihrer besonderen Situation ergeben oder sich der Widerspruch gegen Direktwerbung richtet. Im letzteren Fall haben sie ein generelles Widerspruchsrecht, das ohne Angabe einer besonderen Situation von uns umgesetzt wird.</p>
<p>M&ouml;chten sie von ihrem Widerufs- oder Widerspruchsrecht Gebrauch machen, gen&uuml;gt eine E-Mail an hey@iventi.de.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>17 SSL-bzw. TLS Verschl&uuml;sselung</strong><strong>&nbsp;</strong></p>
<p>Diese Seite nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der &Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschl&uuml;sselung. Eine verschl&uuml;sselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von &bdquo;http://&ldquo; auf &bdquo;https://&ldquo; wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.</p>
<p>Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist, k&ouml;nnen die Daten, die Sie an uns &uuml;bermitteln, nicht von Dritten mitgelesen werden.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
        </div>
    </div>
  )
}

export default Datenschutz