// React
import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useUserContext } from '../../context/UserContext';

// CSS
import './KategorieAnzeigen.css';

// Firebase
import { db } from '../../Firebase'
import { getDoc, doc, getDocs, collection, query, where } from 'firebase/firestore';

//UI Imports
import TopShapeWeiss from '../../img/TrennerObenWeiß.png'
import Header from '../ui/Header';
import CustomIcon from '../ui/CustomIcon';
import Loader from '../Loader';
import GratisAnfragePopUp from '../ui/GratisAnfragePopup';
import ScrollNav from '../ui/ScollNav';

//Icons and Images
import imgKeineErgebnisse from '../../img/Icon_Lupe_keine_Suchergebnisse.svg';
import imgSchiff from '../../img/schiff.jpg';
import iconLocationPin from '../../img/Icon_Staedte_Map_Pin_Grosz.svg';
import iconAusrufezeichen from '../../img/Icon_i_Info_Anbieter_Merkmal.svg';
import iconMerkliste from '../../img/Icon_Favorit_Verlauf.svg';
import iconNotdienst from '../../img/Icon_Notdienst_Verlauf.svg';
import iconNotdienstAktiv from '../../img/Icon_Notdienst_Aktiv.png';
import iconKontakt from '../../img/Icon_Nachricht_schreiben.svg';
import OfferIcon from '../../img/Icon_Schnaeppchen_weiss.svg';
import Trenner from '../ui/Trenner';
import PopUpMerkliste from './PopUpMerkliste';

function Schnaeppchen() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { locationContext } = useUserContext()

    const [loadingSubcategories, setloadingSubcategories] = useState(false);
    const [subcategories, setSubcategories] = useState([]);

    const [currentSubcategory, setCurrentSubcategory] = useState("");

    const [loadingResults, setloadingResults] = useState(false);
    const [results, setResults] = useState([]);

    const [reload, setReload] = useState(false)

    const [contactPopupVisible, setContactPopupVisible] = useState(false);

    const [inseratData, setInseratData] = useState();

    const [merkliste, setMerkliste] = useState(false);
    const [merklisteId, setMerklisteId] = useState();




    // Handle result query and fetch
    useEffect(() => {

        setloadingResults(true);
        results.length = 0;
        const postsRef = collection(db, "posts");
        const posts = locationContext == 0 ? query(postsRef, where("state", "==", "running")) : query(postsRef, where("city", "==", locationContext), where("state", "==", "running"));

        getDocs(posts).then((docs) => {
            var tempArray = []

            //NOW
            var nowDate = new Date(Date.now())
            nowDate.setMinutes(0)
            nowDate.setHours(2)
            nowDate.setSeconds(0)
            nowDate.setMilliseconds(0)

            docs.forEach((doc) => {
                //check if offer
                
                if(doc.data().offerStart){
                    
                    var checkDate = new Date(doc.data().offerStart.toDate())
                    checkDate.setMilliseconds(0)

                    var date = new Date(doc.data().offerStart.toDate())
			        date.setDate(date.getDate() + 6)
                    date.setMilliseconds(0)

			        if(nowDate >= checkDate && nowDate <= date){
                        
                        tempArray.push(doc);
                    }
                }

                //results.push(doc);
                
            })

            //random sort
            tempArray = tempArray.sort((a, b) => 0.5 - Math.random())
            
            //setResults([...results]);
            setResults(tempArray);
            setloadingResults(false);
        })
    }, [locationContext]);

    Date.prototype.addDays = function(days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }

    return (
        <div className="kategorienAnzeigen">
            {contactPopupVisible && <GratisAnfragePopUp setPopupVisible={setContactPopupVisible} inseratData={inseratData} />}

            {merkliste && <PopUpMerkliste adType={0} type={0} id={merklisteId} setMerkliste={setMerkliste}/> }
            <Header className="backgroundAnimation" style={{ paddingTop: "100px" }}>
                <ScrollNav id="scrollNav" />
                <h2>SCHNÄPPCHEN</h2>
                <h3>{locationContext ? locationContext : "Deutschland"}</h3>
            </Header>
            <Trenner shape="small" foreground="grey" background="animated" />
            <main>
                <section className="ergebnisseWrapper" style={{paddingTop: "10px"}}>
                    {loadingResults ?  <Loader /> :
                        <>
                            {results.length !== 0 ?
                                <>
                                    <div className='ergebnisse'>
                                        {results.map((data, key) => (
                                            <div key={key}>
                                                <div className='offerBanner'>
                                                    
                                                    <div>
                                                        <div>SCHNÄPPCHEN: {data.data().offerValue}{data.data().offerType === 1 ? "%" : "€"} Rabatt <span style={{fontSize: "10px"}}>vom {String(data.data().offerStart.toDate().toLocaleDateString("de-DE"))} bis {data.data().offerStart.toDate().addDays(7).toLocaleDateString("de-DE")}</span></div>
                                                        {data.data().offerDescription !== "" ? <div style={{fontSize: "12px", marginTop: "5px"}}>{data.data().offerDescription}</div> : null} 
                                                    </div>
                                                    <div><img src={OfferIcon} /></div>
                                                </div>
                                                <div className='top'>
                                                    <img src={data.data().img[0].url} alt="" />
                                                    <div>
                                                        <h4>{data.data().title}</h4>
                                                        <ul>
                                                            <li><CustomIcon image={iconAusrufezeichen} imageAlt="Location Pin" />{data.data().servicesName}</li>
                                                            <li><CustomIcon image={iconAusrufezeichen} imageAlt="Merkmal" />{data.data().serviceQuick}</li>
                                                            <li><CustomIcon image={iconAusrufezeichen} imageAlt="Merkmal" />{data.data().nameQuick}</li>
                                                            <li><CustomIcon image={iconLocationPin} imageAlt="Merkmal" />{data.data().address}{data.data().address && (data.data().zip || data.data().location) ? ", " : ""}{data.data().zip}{data.data().zip ? " " : ""}{data.data().location}</li>
                                                        </ul>
                                                        {(data.data().type === "flat" || data.data().type === "plus") && <button className="buttonSmallAd" id={data.id} onClick={(e) => navigate(`/adminAd/${e.currentTarget.id}/post`)}>Mehr Infos und Bildergalerie</button>}
                                                    </div>
                                                </div>
                                                {/*<button className='btnMerkliste'><CustomIcon image={iconMerkliste} imageAlt="Merkliste" dimensions={{ width: '20px' }} />Auf Merkliste setzen</button>*/}
                                                <button className='btnMerkliste' style={{borderRadius: "0px"}} onClick={() => {
                                                    setMerklisteId(data.id)
                                                    setMerkliste(true)
                                                }}>
                                                    <CustomIcon image={iconMerkliste} imageAlt="Merkliste" dimensions={{ width: '20px'}} />Auf Merkliste setzen
                                                </button>
                                                <button className='btnNotdienst'><CustomIcon image={iconNotdienst} imageAlt="Notdienst" dimensions={{ width: '25px' }} />Aktuell kein Notdienst</button>
                                                <button className='btnAnfrage' onClick={() => {
                                                    setContactPopupVisible(true);
                                                    setInseratData({ anbieterName: data.data().nameQuick, inseratTitel: data.data().title, email: data.data().userMail });
                                                }}>
                                                    <div>
                                                        <span>Gratis Anfrage </span>
                                                        Mit diseser Anfrage trittst du direkt mit dem Anbieter in kontakt. iVenti ist an dieser Kommunikation nicht beteiligt und speichert sie nicht.
                                                    </div>
                                                    <CustomIcon image={iconKontakt} imageAlt="Kontaktieren" dimensions={{ width: '35px' }} />
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </>
                                :
                                <>                                   
                                     <div className='keineErgebnisse'>   
                                        <img src={imgKeineErgebnisse} alt="Keine Ergebnisse!" />
                                        <p>Noch keine Schnäppchen vorhanden!<br /></p>
                                    </div>
                                
                                </>
                                }
                        </>
                    }
                </section>
            </main>
        </div>
    )
}

export default Schnaeppchen;