import { createContext, useState, useEffect, useContext } from "react";
import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut, sendPasswordResetEmail, sendEmailVerification } from 'firebase/auth'
import { arrayUnion, collection, getDocs, getDoc, doc, query, where, setDoc } from 'firebase/firestore';
import { auth, db } from '../Firebase'

const UserContext = createContext({})

export const useUserContext = () => useContext(UserContext)

export const UserContextProvider = ({ children }) => {

    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState()
    const [error, setError] = useState("")
    const [cartCount, setCartCount] = useState()
    const [admin, setAdmin] = useState(false)
    const [locationContext, setLocationContext] = useState(0)
    const [notdienstSelectedDays, setNotdienstSelectedDays] = useState([]);
    const [search, setSearch] = useState("");
    const [results, setResults] = useState([]);
    const [userType, setUserType] = useState("Kunde")

    useEffect(() => {
        setLoading(true)
        const unsubscribe = onAuthStateChanged(auth, (res) => {
            res ? setUser(res) : setUser(null)
            if (res) {
                if (res.uid === "Ngw615x92JcU6I8HZFWmefIiMFj2" || res.uid === "FOHH4ZfPlEd852QAZDAgfkNUEdi1") {
                    setAdmin(true)
                }
                //GET TYPE
                getDoc(doc(db, "userData", res.uid)).then((innerDoc) => {
                    setUserType(innerDoc.data().type)
                })
            }
            setError("")
            setLoading(false)
        })
        return unsubscribe
    }, [])


    /*
    const registerUser = (email, password) => {
        setLoading(true)
        createUserWithEmailAndPassword(auth, email, password).then((data) => {
            sendEmailVerification(data.user)
            setLoading(false)
            return true
        }).catch((err) => {
            setError(err.message)
            setLoading(false)
            return false
        })
    }
    */

    const registerUser = (email, password) => {
        return createUserWithEmailAndPassword(auth, email, password)
    }


    const resetPassword = (email) => {
        return sendPasswordResetEmail(auth, email)
    }

    /*
    const registerUser = (email, password) => {
        return createUserWithEmailAndPassword(auth, email, password).then((data) => {
            data.user.sendEmailVerification()
        }).catch((err) => {
            console.log(err)
        })
    }
    */

    /*
    const signInUser = (email, password) => {
        setLoading(true)
        signInWithEmailAndPassword(auth, email, password).then((res) => {
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
            setError(err.message)
        })
    }
    */

    const signInUser = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password)
    }

    const logoutUser = () => {
        signOut(auth)
    }

    const forgotPassword = (email) => {
        return sendPasswordResetEmail(auth, email)
    }

    const contextValue = {
        user,
        loading,
        error,
        cartCount,
        admin,
        userType,
        setError,
        registerUser,
        signInUser,
        logoutUser,
        forgotPassword,
        setCartCount,
        setLocationContext,
        locationContext,
        notdienstSelectedDays,
        setNotdienstSelectedDays,
        search,
        setSearch,
        results,
        setResults
    }
    return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
}