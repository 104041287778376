import React from 'react'
import './Footer.css';
import IventiLogo from '../img/LogoIventiWeiß.svg';
import imgFacebook from '../img/Facebook.png';
import imgInstagram from '../img/Instagram.png';
import imgLinkedIn from '../img/LinkedIn.png';
import imgTikTok from '../img/TikTok.png';
import imgYouTube from '../img/YouTube.png';
import imgPinterest from '../img/Pinterest.png';

//TODO: Check Responsiveness

function Footer() {
    return (
        <footer>
            <div>
                <img className="footerLogo" src={IventiLogo} alt="iVenti Logo" />
                <div>
                    Friedlandstraße 60<br />
                    25451 Quickborn<br /><br />
                    04106 640 74 54<br />
                    hey@iventi.de
                </div>
            </div>
            <div>
                <h5>iVenti</h5>
                <a href="https://iventi.de/datenschutz">Datenschutz</a>
                <a href="https://iventi.de/impressum">Impressum</a>
                {/** 
                <a href="#">Kontakt</a>
                <a href="#">FAQ</a>
                */}
            </div>
            <div>
                <h5>Für Anbieter:innen</h5>
                <a href="https://iventi.de">Informationen</a>
                <a href="https://iventi.de/agb">AGB</a>
                {/** <a href="#">Widerrufsrecht</a>*/}
                <a href="https://iventi.de/register">Account anlegen</a>
                <a href="https://iventi.de/login">Log-in</a>
            </div>
            {/** 
            <div>
                <h5>Für Suchende</h5>
                <a href="#">Informationen</a>
                <a href="">Nutzungsbedingungen</a>
                <a href="#">Account anlegen</a>
                <a href="#">Log-in</a>
            </div>
            */}
            <div className="socialMedia">
                <a href="#"><img src={imgFacebook} alt="Facebook" /></a>
                <a href="#"><img src={imgInstagram} alt="Instagram" /></a>
                <a href="#"><img src={imgLinkedIn} alt="Linked-In" /></a>
                <a href="#"><img src={imgTikTok} alt="TikTok" /></a>
                <a href="#"><img src={imgYouTube} alt="YouTube" /></a>
                <a href="#"><img src={imgPinterest} alt="Pinterest" /></a>
            </div>
        </footer>
    )
}

export default Footer;