import React, { useRef, useEffect, useState } from 'react';
import './Tooltip.css';

const Tooltip = props => {
  const caption = useRef(null);
  const [position, setPosition] = useState("left center")

  useEffect(() => {
    let tooltipRect = caption.current.getBoundingClientRect();
    let pseudoLeft = tooltipRect.x + tooltipRect.width;
    let pseudoWidth = parseInt(window.getComputedStyle(caption.current, '::after').width);
    setPosition((pseudoLeft + pseudoWidth > window.innerWidth) ? 'left center' : 'right center');
  }, []);

  return (
    <div className="tooltip">
        <span  ref={caption} className="caption"  data-tooltip={props.caption} data-position={position}>?</span>
    </div>
  );
}

export default Tooltip;