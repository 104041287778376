import React, { useEffect, useState, useRef } from 'react'
import Loader from '../Loader'
import './CreateOffer.css'

//CONTEXT
import { useUserContext } from '../../context/UserContext';

//FIREBASE
import { collection, getDocs, query, where, getDoc, doc } from 'firebase/firestore';
import { db } from '../../Firebase'

//Icons and Images
import iconLocationPin from '../../img/Icon_Staedte_Map_Pin_Grosz.svg';
import iconAusrufezeichen from '../../img/Icon_i_Info_Anbieter_Merkmal.svg';
import iconNotdienst from '../../img/Icon_Notdienst_Verlauf.svg';
import iconNotdienstAktiv from '../../img/Icon_Notdienst_Aktiv.png';
import iconKontakt from '../../img/Icon_Nachricht_schreiben.svg';
import CircularProgress from '@mui/material/CircularProgress';
import Checkmark from '../../img/checkMark.svg'
import iconMerkliste from '../../img/Icon_Favorit_Verlauf.svg';

//UI Imports
import CustomIcon from '../ui/CustomIcon';
import CustomSelect from './CustomSelect';
import MenuItem from '@mui/material/MenuItem';
import { getListItemTextUtilityClass } from '@mui/material';
import PopUp from '../PopUp';
import OfferIcon from '../../img/Icon_Schnaeppchen_weiss.svg';

function CreateOffer() {

    const legal = useRef()

    //INVOICE
    const [company, setCompany] = useState("")
	const [address, setAddress] = useState("")
	const [location, setLocation] = useState("")

    const [loading, setLoading] = useState(true)
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [loadingCode, setLoadingCode] = useState(false)

    const [price, setPrice] = useState(20)
    const [code, setCode] = useState("")
    const [sendCode, setSendCode] = useState("")
    const [posts, setPosts] = useState([])
    const [choosenId, setChoosenId] = useState()
    const [choosenData, setChoosenData] = useState()
    const [slots, setSlots] = useState([])
    const [select, setSelect] = useState(0)
    const [offerType, setOfferType] = useState(0)
    const [offerValue, setOfferValue] = useState(0)
    const [userInput, setUserInput] = useState("")
    const [userDescription, setUserDescription] = useState("")
    const [error, setError] = useState("")

    //CONTEXT
    const { user } = useUserContext()



    function getSlots(){
        setLoading(true)

        //DATE NOW
        var nowDate = new Date(Date.now())
        nowDate.setMinutes(0)
        nowDate.setHours(0)
        nowDate.setSeconds(0)

        getDocs(query(collection(db, "slots"), where("limit", "==", false))).then((docs) => {
            var temp = []
            docs.forEach((doc) => {
                
                var checkDate = doc.data().start.toDate()
                checkDate.setDate(checkDate.getDate() - 7)
                if(checkDate > nowDate) {
                    temp.push({order: doc.data().start.toDate() ,time: getTimeString(doc), id: doc.id})
                }
                
            })

            temp.sort(function(a,b){
                return new Date(a.order) - new Date(b.order);
            })

            setSlots(temp)
            setLoading(false)
        })
    }

    console.log("Slots", slots)

    function getTimeString(ts) {
        var first = new Date(ts.data().start.toDate())
        var second = new Date(first)
        second.setDate(second.getDate() + 6)
        console.log(first.toLocaleDateString("de-DE"))
        return String(first.toLocaleDateString("de-DE", { year: "numeric", month: "2-digit", day: "2-digit" }) + " - " + second.toLocaleDateString("de-DE", { year: "numeric", month: "2-digit", day: "2-digit" }))
    }

    function buyOffer(){

        setLoadingSubmit(true)
        
        if(slots === 0 || offerType === 0 || userInput === ""){
            setError('Bitte gebe einen Zeitraum, Rabattart und Wert an.')
            setLoadingSubmit(false)
            return
        } 
        if(offerType === 1 && userInput > 100 || userInput < 1){
            setError("Prozentualer Rabatt muss zwischen 1 und 100 liegen.")
            setLoadingSubmit(false)
            return
        } 
        if(sendCode === ""){
            if(company === "" || address === "" || location === ""){
                setError("Gebe deine Rechnungsdaten vollständig an.")
                setLoadingSubmit(false)
                return
            } 
        }

        if(!legal.current.checked){
            setError("Akzeptiere unsere Datenschutzerlärung und AGB")
            setLoadingSubmit(false)
            return
        } 
        

        fetch('https://us-central1-iventi-6d02a.cloudfunctions.net/test/createPaymentOffer', {
        //fetch('http://localhost:5001/iventi-6d02a/us-central1/test/createPaymentOffer', {
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		method: 'POST',
		body: JSON.stringify({userUid: user.uid, type: "offer", payment: sendCode === "" ? "stripe" : "code" , company: company, address: address, location: location, userEmail: user.email, post: choosenId, slot: select, offerType: offerType, offerValue: userInput, offerDescription: userDescription, code: sendCode})
        })
        .then((response) => {return response.text()})
        .then((data) => {
            if(data === "error"){
                setLoadingSubmit(false)
                setError("Es ist ein Fehler aufgetreten.")
            } else if(data === "isActive"){ 
                setLoadingSubmit(false)
                setError("Gutschein bereits eingelöst.")
            } else {
                window.location.href = data
            }
		})
        .catch((err) => {
            console.log("error", err)
        })

    }

    function checkCode(){
		setLoadingCode(true)

		if(code === ""){
			setError("Bitte gebe einen Gutschein ein")
			setLoadingCode(false)
			return
		}

		fetch('https://us-central1-iventi-6d02a.cloudfunctions.net/test/checkCouponOffer', {
        //fetch('http://localhost:5001/iventi-6d02a/us-central1/test/checkCouponOffer', {
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		method: 'POST',
		body: JSON.stringify({code: code})
        })
        .then((response) => {return response.text()})
        .then((data) => {
			if(data === "error"){
				setError("Gutschein nicht vorhanden")
				setLoadingCode(false)
			}
			if(data === "true"){
				setSendCode(code)
				setCode("")
				setPrice(0)
				setLoadingCode(false)
			}
		})
        .catch(() => {
            setError("Es ist ein Fehler aufgetreten")
			setLoadingCode(false)
        })
	}
    

    useEffect(() => {
        
        getDocs(query(collection(db, "posts"),where("uid", "==", user.uid), where("state", "==", "running"))).then((docs) => {
            
            //TODAY DATE
            var nowDate = new Date(Date.now())
            nowDate.setMinutes(0)
            nowDate.setHours(0)
            nowDate.setSeconds(0)
            
            docs.forEach((doc) => {
                //CHECK IF OFFER EXISTS IN DUE DATE
                if(doc.data().offerStart){
                    var checkDate = doc.data().offerStart.toDate()
                    checkDate.setDate(checkDate.getDate() + 7)
                    console.log(checkDate, nowDate)
                    if(checkDate < nowDate){
                        posts.push(doc)
                    }
                } else {
                    posts.push(doc)
                }
            })

            getDoc(doc(db, "userData", user.uid)).then((docUser) => {
                setLocation(`${docUser.data().zip ? docUser.data().zip : ""} ${docUser.data().location ? docUser.data().location : ""}`)
                setCompany(docUser.data().company)
                setAddress(docUser.data().address)
            })


            setPosts([...posts])
            setLoading(false)
        })
        
    }, [])

    //DROP DOWN STYLING
    const menuItemStyle = {
		fontFamily: 'Spartan, sans-serif !important',
		fontSize:'14px !important',
		['&.Mui-selected']:{
			background:'rgba(249, 179, 0, .3) !important'
		},
		['&:hover']:{
			background:'rgba(249, 179, 0, .3) !important'
		}
	};
    

    return (
        <div className="CreateOffer">
            {loading && <Loader />}

            {error && <PopUp message={error} setError={setError} />}

            {/** CHOOSE A POST FOR OFFER USE */}

            {(!loading && !choosenId) && <>



            <div style={{marginTop: "40px", textAlign: "center"}} className="headline">
				{posts.length !== 0 && <h2>Für welches Inserat möchtest du ein <br/>»Schnäppchen« anlegen?</h2>}
                {posts.length === 0 && <h2>Kein Inserat für ein Schnäppchen geeignet.</h2>}
			</div>

            <section className="ergebnisseWrapper">
                <div className='ergebnisse'>
                    {posts.length !== 0 && posts.map((data, key) => (
                        <div className="choosePost" onClick={() => {
                            setChoosenData(data)
                            setChoosenId(data.id) 
                            getSlots()
                        }} key={key}>
                            <div className='top'>
                                <img src={data.data().img[0].url} alt="" />
                                <div>
                                    <h4>{data.data().title}</h4>
                                    <ul>
                                        <li><CustomIcon image={iconAusrufezeichen} imageAlt="Location Pin" />{data.data().servicesName}</li>
                                        <li><CustomIcon image={iconAusrufezeichen} imageAlt="Merkmal" />{data.data().serviceQuick}</li>
                                        <li><CustomIcon image={iconAusrufezeichen} imageAlt="Merkmal" />{data.data().nameQuick}</li>
                                        <li><CustomIcon image={iconLocationPin} imageAlt="Merkmal" />{data.data().address}{data.data().address && (data.data().zip || data.data().location) ? ", " : ""}{data.data().zip}{data.data().zip ? " " : ""}{data.data().location}</li>
                                    </ul>
                                    {(data.data().type === "flat" || data.data().type === "plus") && <button className="buttonSmallAd" id={data.id}>Mehr Infos und Bildergalerie</button>}
                                </div>
                            </div>
                            <button className='btnMerkliste'><CustomIcon image={iconMerkliste} imageAlt="Merkliste" dimensions={{ width: '20px' }} />Auf Merkliste setzen</button>
                            <button className='btnNotdienst'><CustomIcon image={data.data().alarm ? iconNotdienstAktiv : iconNotdienst} imageAlt="Notdienst" dimensions={{ width: '25px' }} />{data.data().alarm ? "Notdienst eingestellt" : "Kein Notdienst eingestellt"}</button>
                            <button className='btnAnfrage'>
                                <div>
                                    <span>Gratis Anfrage </span>
                                    Mit diseser Anfrage trittst du direkt mit dem Anbieter in kontakt. iVenti ist an dieser Kommunikation nicht beteiligt und speichert sie nicht.
                                </div>
                                <CustomIcon image={iconKontakt} imageAlt="Kontaktieren" dimensions={{ width: '35px' }} />
                            </button>
                        </div>
                    ))}
                </div>
            </section>

            </>}

            {(choosenId && slots.length === 0) &&
            <div style={{marginTop: "40px", marginBottom: "30px", textAlign: "center"}} className="headline">
                {slots.length === 0 && <h2>Keine Kapazitäten frei.</h2>}
			</div>
            }


            {(!loading && choosenId && slots.length !== 0) && 
            <div style={{maxWidth: "800px", margin: "auto", width: "90%"}}>
                <div style={{marginTop: "40px", marginBottom: "40px", textAlign: "center"}} className="headline">
                    <h2>»Schnäppchen« anlegen:</h2>
                </div>

                <CustomSelect select={select} setSelect={setSelect} className="selectItem">
                    <MenuItem sx={menuItemStyle} value={0}>Zeitraum wählen</MenuItem>
                    {slots.map((data, index) => (
                        <MenuItem key={index} sx={menuItemStyle} value={data.id}>{data.time}</MenuItem>
                    ))}
				</CustomSelect>


                <CustomSelect select={offerType} setSelect={setOfferType} className="selectItem">
                    <MenuItem sx={menuItemStyle} value={0}>Rabatt wählen</MenuItem>
                    <MenuItem sx={menuItemStyle} value={1}>Prozentualer Rabatt (%)</MenuItem>
                    <MenuItem sx={menuItemStyle} value={2}>Fester Rabatt (€)</MenuItem>
				</CustomSelect>

                {(offerType !== 0 && select !== 0) && <>
                    <div className="textFieldInformation">
                        {/** <input style={{marginTop: "20px"}} maxLength="10" className="textField" type="number" value={userInput} onChange={(e) => setUserInput(e.target.value)} placeholder={offerType === 1 ? "Wert in Prozent wählen (%)" : "Wert in Euro wählen (€)"}/>*/}
                        <input style={{marginTop: "20px"}} maxLength="10" className="textField" type="number" value={userInput} onChange={(e) => setUserInput(e.target.value)} placeholder="Wert"/>
                        <p>{offerType === 1 ? "%" : "€"} Rabatt</p>
                    </div>
                    <input style={{marginTop: "20px"}} maxLength="70" className="textField" type="text" value={userDescription} onChange={(e) => setUserDescription(e.target.value)} placeholder="Beschreibung" />
                </>}
                
                <div style={{marginTop: "40px", marginBottom: "40px", textAlign: "center"}} className="headline">
                    <h2>Vorschau:</h2>
                </div>

                <div className='ergebnisse ergebnisseCenter'>
                        <div className="choosePost noHover">
                            {userInput !== "" &&
                                <div className='offerBanner'>
                                    <div>
                                        <div>SCHNÄPPCHEN: {userInput}{offerType === 1 ? "%" : "€"} Rabatt</div>
                                        {userDescription !== "" ? <div style={{fontSize: "12px", marginTop: "5px"}}>{userDescription}</div> : null} 
                                    </div>
                                    <div><img src={OfferIcon} /></div>
                                </div>
                            }
                            <div className='top'>
                                <img src={choosenData.data().img[0].url} alt="" />
                                <div>
                                    <h4>{choosenData.data().title}</h4>
                                    <ul>
                                        <li><CustomIcon image={iconAusrufezeichen} imageAlt="Location Pin" />{choosenData.data().servicesName}</li>
                                        <li><CustomIcon image={iconAusrufezeichen} imageAlt="Merkmal" />{choosenData.data().serviceQuick}</li>
                                        <li><CustomIcon image={iconAusrufezeichen} imageAlt="Merkmal" />{choosenData.data().nameQuick}</li>
                                        <li><CustomIcon image={iconLocationPin} imageAlt="Merkmal" />{choosenData.data().address}{choosenData.data().address && (choosenData.data().zip || choosenData.data().location) ? ", " : ""}{choosenData.data().zip}{choosenData.data().zip ? " " : ""}{choosenData.data().location}</li>
                                    </ul>
                                    {(choosenData.data().type === "flat" || choosenData.data().type === "plus") && <button className="buttonSmallAd" id={choosenData.id}>Mehr Infos und Bildergalerie</button>}
                                </div>
                            </div>
                            <button className='btnMerkliste'><CustomIcon image={iconMerkliste} imageAlt="Merkliste" dimensions={{ width: '20px' }} />Auf Merkliste setzen</button>
                            <button className='btnNotdienst'><CustomIcon image={choosenData.data().alarm ? iconNotdienstAktiv : iconNotdienst} imageAlt="Notdienst" dimensions={{ width: '25px' }} />{choosenData.data().alarm ? "Notdienst eingestellt" : "Kein Notdienst eingestellt"}</button>
                            <button className='btnAnfrage'>
                                <div>
                                    <span>Gratis Anfrage </span>
                                    Mit diseser Anfrage trittst du direkt mit dem Anbieter in kontakt. iVenti ist an dieser Kommunikation nicht beteiligt und speichert sie nicht.
                                </div>
                                <CustomIcon image={iconKontakt} imageAlt="Kontaktieren" dimensions={{ width: '35px' }} />
                            </button>
                        </div>
                </div>

                <div style={{marginTop: "40px", marginBottom: "40px", textAlign: "center"}} className="headline">
                    <h2>Zusammenfassung:</h2>
                </div>

                <div className="sum_cartItem">
					<div className="sum_summary">
						<div className="sum_columnPayment" style={{flexBasis: "50%"}}>
							<div className="sum_headlineSmall">RECHNUNGSADRESSE</div>
							<input value={company} onChange={(e) => setCompany(e.target.value)} className="textFieldUnderline" type="text" placeholder="Name/Firma"/>
							<input value={address} onChange={(e) => setAddress(e.target.value)} className="textFieldUnderline" type="text" placeholder="Adresse"/>
							<input value={(location === " " || location === "") ? null : location} onChange={(e) => setLocation(e.target.value)} className="textFieldUnderline" type="text" placeholder="Plz & Ort"/>


                            <div style={{marginTop: "20px"}} className="sum_headlineSmall">ZAHLUNGSMETHODE</div>
							<div className="sum_text">Kreditkarte</div>
						</div>
						<div className="sum_columnPriceSummary" style={{flexBasis: "50%"}}>

                            <div className="sum_headlineSmall">GUTSCHEIN VORHANDEN?</div>
							<div style={{marginBottom: "20px"}} className="sum_couponHolder">
								<input value={code} onChange={(e) => setCode(e.target.value)} className="sum_coupon_textField" type="text" placeholder="Hier Code eingeben" />
								{!loadingCode ? 
								<div onClick={checkCode} className="sum_sumbmitCoupon">
									<img src={Checkmark} />
								</div>
								:
								<div className="sum_sumbmitCoupon">
									<CircularProgress style={{ color: "white", width: "31px", height: "31px" }} />
								</div>
								}
							</div>  

							<div className="sum_headlineSmall">SUMME</div>
							<div className="sum_priceList">
								<div>Netto-Gesamtpreis</div>
								<div>{price} €</div>
							</div>
							<div className="sum_priceList">
								<div>zzgl. 19% MwSt.</div>
								<div>{(price * 0.19).toFixed(2)} €</div>
							</div>
							<hr/>
							<div className="sum_priceList">
								<div><strong>Brutto-Gesamtpreis</strong></div>
								<div><strong>{(price * 1.19).toFixed(2)} €</strong></div>
							</div>
						</div>
					</div>
				</div>

                <div style={{display: "flex", justifyContent: "center", alignItems: "center", padding: "35px 0 10px 0"}} className="legal">
                    <input type="checkbox" ref={legal}/>
                    <div style={{paddingLeft: "5px"}}>Ich akzeptiere die Datenschutzerklärung und AGB*</div>
                </div>

                {loadingSubmit ? 
                    <div className="submitFlex" style={{backgroundColor: "unset", padding: "30px 0 30px 0"}}>
                        <button className="loadingButton"><CircularProgress style={{color: "white", width: "31px", height: "31px" }} /></button>
                    </div>
                    :
                    <>
                    <div style={{backgroundColor: "unset"}} className="submitFlex">
                        <button className="submitAdButton" onClick={buyOffer}>JETZT KAUFEN</button> 
                    </div>
				</>
			}


            </div>    
            }
            


        </div>
    )
}

export default CreateOffer