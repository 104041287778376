import React from 'react'
import './Nav.css';
import IventiLogo from '../../img/LogoIventiWeiß.svg';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../context/UserContext';



function Nav() {

    const navigate = useNavigate();
    const { user, logoutUser } = useUserContext()

    return (
        <nav className="standardNav">
            <img src={IventiLogo} alt="iVenti.de - Logo" onClick={() => navigate('/')} />
            <input type="checkbox" id="navToggle" name="navToggle" />
            <label id="lblNavToggle" htmlFor="navToggle">
                <div className="navIcon"></div>
            </label>
            <ul className="links">
                <li><a onClick={() => navigate("/")}>STARTSEITE</a></li>
                {/*<li><a>MEINE MERKLISTEN</a></li>*/}
                <li><a onClick={() => navigate("/anbieter")}>FÜR ANBIETER:INNEN</a></li>
                <li><a onClick={() => navigate("/register")}>REGISTRIEREN</a></li>
                {!user && <li><a onClick={() => navigate("/login")}>ANMELDEN</a></li>}
                {user && <li><a onClick={() => navigate("/dashboard")}>ZUM DASHBOARD</a></li>}
            </ul>
        </nav>
    )
}

export default Nav;