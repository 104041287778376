import React from 'react';
import './PopUp.css'

function PopUp(props) {
	return (
		<div className="PopUp">
			<div className="innerPopUp">
				<div className="headlinePopUp">Hinweis</div>
				<div className="messagePopUp">{props.message}</div>
				<button style={{marginBottom: "20px"}} onClick={() => props.setError("")} className="submitAdButton">ALLES KLAR</button>
			</div>
		</div>

	)
}

export default PopUp;
