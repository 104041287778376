import React, { useEffect, useState } from 'react'
import Header from './ui/Header'
import Gallery from './Gallery'
import { db } from '../Firebase'
import { arrayUnion, collection, getDocs, getDoc, doc, query, where, setDoc } from 'firebase/firestore';
import './ShowAd.css'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CelebrationIcon from '@mui/icons-material/Celebration';
import InfoIcon from '@mui/icons-material/Info';
import IventiLogo from '../img/LogoIventiWeiß.svg'
import TopShapeGrey from '../img/TrennerObenGreySmall.png'
import Loader from './Loader';
import { useUserContext } from '../context/UserContext';
import { useParams, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import CustomIcon from './ui/CustomIcon';

// Images and Icons
import iconLocationPin from '../img/Icon_Staedte_Map_Pin_Grosz.svg';
import iconAusrufezeichen from '../img/Icon_i_Info_Anbieter_Merkmal.svg';
import iconMerkliste from '../img/Icon_Favorit_Verlauf_Weiss.png';
import iconNotdienst from '../img/Icon_Notdienst_Verlauf.svg';
import iconKontakt from '../img/Icon_Nachricht_schreiben.svg';
import GratisAnfragePopUp from './ui/GratisAnfragePopup';
import Trenner from './ui/Trenner';
import ZurueckButton from './ui/ZurueckButton';
import iconNotdienstAktiv from '../img/Icon_Notdienst_Aktiv.png';
import OfferIcon from '../img/Icon_Schnaeppchen_weiss.svg';
import PopUpMerkliste from './customer/PopUpMerkliste';

function ShowAd() {

	const [loading, setLoading] = useState(true)
	const [images, setImages] = useState([])
	const [selectedImages, setSelectedImages] = useState({})
	const [post, setPost] = useState()
	const [message, setMessage] = useState()
	const [cancel, setCancel] = useState(false)
	const [loadingAccept, setLoadingAccept] = useState(false)
	const [loadingDecline, setLoadingDecline] = useState(false)

	// Gratis Anfrage Popup
	const [contactPopupVisible, setContactPopupVisible] = useState(false);
	const [inseratData, setInseratData] = useState();

	const { id, type } = useParams()
	const navigate = useNavigate()

	const { admin, notdienstSelectedDays } = useUserContext()

	//MERKLISTE
	const [merkliste, setMerkliste] = useState(false);
    const [merklisteId, setMerklisteId] = useState();

	function accept() {
		setLoadingAccept(true)
		//setDoc(doc(db, "posts", documentID)
		if (post.cat === "inspo") {
			setDoc(doc(db, "inspos", id), {
				state: "running"
			}, { merge: true }).then(() => {
				navigate("/dashboard/admin")
			})
		} else {
			setDoc(doc(db, "posts", id), {
				state: "running"
			}, { merge: true }).then(() => {
				navigate("/dashboard/admin")
			})
		}
	}

	function decline() {
		setLoadingDecline(true)
		if (post.cat === "inspo") {
			setDoc(doc(db, "inspos", id), {
				state: "error",
				errorMessage: message
			}, { merge: true }).then(() => {
				navigate("/dashboard/admin")
			})
		} else {
			setDoc(doc(db, "posts", id), {
				state: "error",
				errorMessage: message
			}, { merge: true }).then(() => {
				navigate("/dashboard/admin")
			})
		}
	}

	function checkEmergency(data){
		var temp = []
		notdienstSelectedDays.forEach((time) => {
			var date = new Date(time)
			date = String(date.getTime()).slice(0, -3)
			temp.push(parseInt(date))
		})

        var nowDate = new Date(Date.now())
        nowDate.setMinutes(0)
        nowDate.setMilliseconds(0)
        nowDate.setSeconds(0)
        nowDate = String(nowDate.getTime()).slice(0, -3)

        if(notdienstSelectedDays.length !== 0){
            if(data.selectedDays.some(e => temp.includes(e.seconds))){
                return true
            } 
        } 

        if(data.selectedDays.filter(e => e.seconds === parseInt(nowDate)).length > 0){
            return true
        } 

        return false
        
    }

	Date.prototype.addDays = function(days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }

	function checkOffer(post){
		if(post.offerStart){

			//today
			var nowDate = new Date(Date.now())
			nowDate.setMinutes(0)
			nowDate.setHours(0)
			nowDate.setSeconds(0)

			var date = new Date(post.offerStart.toDate())
			date.setDate(date.getDate() + 7)

			if(nowDate > post.offerStart.toDate() && nowDate < date){
				return true
			} else {
				return false
			}
		} else {
			return false
		}
	}


	useEffect(() => {

		setLoading(true)

		//"yc0d10hsakc38iv0t1oju5"

		if (type === "post") {
			getDoc(doc(db, "posts", id)).then((data) => {
				console.log(data.data())
				setImages(data.data().img)
				setSelectedImages({url: data.data().img[0].url, type: data.data().img[0].type})
				setPost(data.data())
				setLoading(false)
			})
		} else {
			getDoc(doc(db, "inspos", id)).then((data) => {
				console.log(data.data())
				setImages(data.data().img)
				setSelectedImages({url: data.data().img[0].url, type: data.data().img[0].type})
				setPost(data.data())
				setLoading(false)
			})
		}



	}, [])


	return (
		<div className='showAdWrapper'>
			{contactPopupVisible && <GratisAnfragePopUp setPopupVisible={setContactPopupVisible} inseratData={inseratData} />}
			{merkliste && <PopUpMerkliste adType={type === "post" ? 0 : 1} type={0} id={merklisteId} setMerkliste={setMerkliste}/> }
			<Header className="backgroundAnimation" nav="scroll">
				<h2>{!loading && post.title}</h2>
				<h3>{!loading && post.city}</h3>
			</Header>
			<Trenner shape="small" foreground="grey" background="animated" />
			{loading ? <Loader /> : <>

				{cancel &&
					<div className="PopUp">
						<div className="innerPopUp">
							<div className="headlinePopUp">HEY MARCUS, GIB EINEN GRUND AN:</div>
							<input placeholder="Grund" type="text" className="textField" style={{ maxWidth: "90%", margin: "20px 0 20px 0" }} onChange={(e) => setMessage(e.target.value)} />
							{loadingDecline ?
								<button style={{ height: "61px", marginBottom: "20px" }} className="loadingButton"><CircularProgress style={{ color: "white", width: "31px", height: "31px" }} /></button>
								:
								<button style={{ marginBottom: "20px" }} className="submitAdButton" onClick={() => decline()}>ABSENDEN</button>
							}

							<button style={{ marginBottom: "20px", backgroundColor: "lightgray" }} onClick={() => setCancel(false)} className="submitAdButton">ABBRECHEN</button>
						</div>
					</div>
				}

				{admin && post.state === "check" &&
					<div style={{ display: "flex", justifyContent: "center", alignItems: "center", maxWidth: "1200px", margin: "auto" }}>
						{/** <button style={{margin: "10px", backgroundColor: "lightgrey"}} className="submitAdButton" onClick={() => navigate("/dashboard/admin")}>Zurück</button>*/}
						{loadingAccept ?
							<button style={{ height: "61px" }} className="loadingButton"><CircularProgress style={{ color: "white", width: "31px", height: "31px" }} /></button>
							:
							<button style={{ margin: "10px" }} className="submitAdButton" onClick={() => accept()}>Freigeben</button>
						}
						<button style={{ margin: "10px", backgroundColor: "#f94e4e" }} className="submitAdButton" onClick={() => setCancel(true)}>Ablehnen</button>
					</div>
				}

				<ZurueckButton title="Zurück zur Übersicht" />
				<div className="showAd">
					<div className="SA_flexOne">
						<Gallery images={images} setSelectedImages={setSelectedImages} selectedImages={selectedImages} />
						{post.type !== "zero" &&
							<div className="SA_description">
								<div style={{ padding: "20px 0 10px 0", fontWeight: "700", textTransform: "uppercase" }}>{post.headline}</div>
								<div style={{ fontSize: "14px", whiteSpace: "pre-line", lineHeight: "25px" }}>{post.text}</div>
								<div style={{ padding: "20px 0 10px 0", fontWeight: "700", textTransform: "uppercase" }}>{post.headline2}</div>
								<div style={{ fontSize: "14px", whiteSpace: "pre-line", lineHeight: "25px" }}>{post.text2}</div>
							</div>
						}
					</div>

					<div className="SA_flexTwo">
						{checkOffer(post) === true &&
							<div className='offerBanner offerBannerCustomer'>
								<div>
									<div>SCHNÄPPCHEN: {post.offerValue}{post.offerType === 1 ? "%" : "€"} Rabatt <span style={{fontSize: "10px"}}>vom {String(post.offerStart.toDate().toLocaleDateString("de-DE"))} bis {post.offerStart.toDate().addDays(7).toLocaleDateString("de-DE")}</span></div>
									{post.offerDescription !== "" ? <div style={{fontSize: "12px", marginTop: "5px"}}>{post.offerDescription}</div> : null} 
								</div>
								<div><img src={OfferIcon} /></div>
							</div>
						}
						<div className="fullServiceContainer topButtons">
							<button className='btnMerkliste backgroundAnimation' onClick={() => {
								setMerklisteId(id)
								setMerkliste(true)
							}}><CustomIcon image={iconMerkliste} imageAlt="Merkliste" dimensions={{ height: '28px' }} /><span>Auf Merkliste setzen</span></button>
							<button className={`btnNotdienst ${checkEmergency(post) ? "notdienstActive" : "disabled"}`}><CustomIcon image={checkEmergency(post) ? iconNotdienstAktiv : iconNotdienst} imageAlt="Notdienst" dimensions={{ height: '38px' }} /><span>{post.selectedDays.length != 0 ? "Notdienst Aktiv" : "Kein Notdienst"}</span></button>
						</div>

						<div className="fullServiceContainer showAd_services kurzinfo">
							<p style={{ paddingBottom: "10px", textTransform: "uppercase", fontWeight: "700", fontSize: "16px" }}>Kurzinfo</p>
							<div className="infoPoint">
								<CustomIcon image={iconAusrufezeichen} />
								<div style={{ marginLeft: "5px" }}>{post.service}</div>
							</div>
							<div className="infoPoint">
								<CustomIcon image={iconAusrufezeichen} />
								<div style={{ marginLeft: "5px" }}>{post.serviceQuick}</div>
							</div>
							<div className="infoPoint">
								<CustomIcon image={iconAusrufezeichen} />
								<div style={{ marginLeft: "5px" }}>{post.nameQuick}</div>
							</div>
							<div className="infoPoint">
								<CustomIcon image={iconLocationPin} />
								<div style={{ marginLeft: "5px" }}>{post.address ? <>{post.address},</> : ""} {post.zip} {post.location}</div>
							</div>
						</div>

						{(post.type !== "zero" && type !== "inspo") && Object.keys(post.keywords).map((point, index) => (
							<div className="fullServiceContainer showAd_services" key={index}>
								<p style={{ textTransform: "uppercase", fontWeight: "700", fontSize: "16px" }}>{point}</p>
								<ul>
									{post.keywords[point].map((word, index) => (
										<li key={index}>{word}</li>
									))}
								</ul>
							</div>
						))}

						<button className='btnAnfrage' onClick={() => {
							setContactPopupVisible(true);
							setInseratData({ anbieterName: post.nameQuick, inseratTitel: post.title, email: post.userMail });
						}}>
							<CustomIcon image={iconKontakt} imageAlt="Kontaktieren" dimensions={{ width: '40px' }} />
							<div>
								<span>Gratis Anfrage </span>
								Mit diseser Anfrage trittst du direkt mit dem Anbieter in Kontakt. iVenti ist an dieser Kommunikation nicht beteiligt und speichert sie nicht.
							</div>
						</button>
					</div>

				</div>
			</>}
		</div>
	)
}

export default ShowAd