import React from 'react'
import Header from './provider/Header'
import { Link } from 'react-router-dom'

function AGB() {
  return (
    <div className="legal">
        <Header headline={"AGB"} />
        
        <div style={{width: "90%",wordBreak: "break-word",maxWidth: "1000px", margin: "auto", lineHeight: "25px", paddingBottom: "50px"}} >
        <Link style={{textDecoration: "none", color: "rgb(249, 179, 0)", fontWeight: "700", marginBottom: "20px", display: "block"}} to="/dashboard/start">Zurück</Link>
        <p><strong>AGB iVenti f&uuml;r Anbieter</strong></p>
<p>&nbsp;</p>
<p><strong>1 Vertragsgegenstand, Vertragsparteien</strong>&nbsp;</p>
<ul>
<li>iVenti.de erm&ouml;glicht seinen Kunden / Anbietern, Dienstleistungsanzeigen aus dem Bereich Event &amp; Veranstaltungen in das Internetportal iVenti.de kostenfrei oder als bezahlte Anzeige zu inserieren. iVenti.de erbringt seine hierauf bezogene Leistung (im folgenden &bdquo;vertragliche Leistung&ldquo;) zu den nachfolgenden Gesch&auml;ftsbedingungen. Die Leistung erfolgt nach den zwischen den Vertragspartnern getroffenen Vereinbarungen sowie erg&auml;nzend den nachfolgenden Allgemeinen Gesch&auml;ftsbedingungen. F&uuml;r sonstige Lieferungen und Leistungen von iVenti.de gelten die hierf&uuml;r gesondert getroffenen Vereinbarungen und Gesch&auml;ftsbedingungen. Gesch&auml;ftsbedingungen des Kunden werden nicht Vertragsbestandteil.</li>
<li>Der Kunde / Anbieter kann die Rechte und Pflichten aus diesem Vertrag nur mit schriftlicher Zustimmung von iVenti.de auf einen Dritten &uuml;bertragen.</li>
<li>iVenti erbringt die vertragliche Leistung nicht f&uuml;r Kunden, die Verbraucher im Sinne von &sect;13 BGB sind, sondern ausschlie&szlig;lich f&uuml;r juristische Personen des privaten und &ouml;ffentlichen Rechts sowie Unternehmer im Sinne von &sect; 14 BGB.</li>
</ul>
<p>&nbsp;</p>
<p><strong>2 Anmeldung und Vertragsabschluss</strong></p>
<ul>
<li>Die Nutzung der vertraglichen Leistung setzt voraus, dass sich der Kunde zuvor auf der Internetseite <a href="http://www.iventi.de">iventi.de</a> unter Angabe seiner Daten als Nutzer (Kunde / Anbieter) anmeldet.</li>
<li>Mit Best&auml;tigung des von iVenti versandten Registrierungslinks seitens des Kunden / Anbieter kommt der Vertrag &uuml;ber die vertragliche Leistung zustande. Der Kunde kann seine bei der Anmeldung gespeicherten Daten aus dem passwortgesch&uuml;tzten Bereich heraus jederzeit &auml;ndern.</li>
</ul>
<p>&nbsp;</p>
<p><strong>3 Leistungsbeschreibung</strong></p>
<ul>
<li>Die Leistung von iVenti.de gegen&uuml;ber dem Kunden besteht im Vorhalten eines Informationsportals ausschlie&szlig;lich f&uuml;r Dienstleistungen aus dem Bereich Event und Veranstaltungen f&uuml;r suchende (Nutzer) im Segment Event &amp; Veranstaltungen.</li>
</ul>
<p><br/>Das vertragliche Portal <a href="http://www.iventi.de">www.iventi.de</a> informiert die Nutzer kostenfrei &uuml;ber Dienstleister aller Art aus dem Bereich Event &amp; Veranstaltungen ( Messen, Partys, Tagungen, Privatfeier u.s.w.) die sich aus Deutschland und ggf. Europa anbieten. Die Anzeigeninhalte k&ouml;nnen au&szlig;er in dem vertraglichen Portal <a href="http://www.iventi.de">www.iventi.de</a> ggf. in anderem Erscheinungsbild &ndash; auch auf sonstigen Internetseiten anderer Anbieter erscheinen. iVenti.de beh&auml;lt sich vor, das Erscheinungsbild des vertraglichen Portals sowie sonstiger Internetseiten, auf denen die Veranstaltungsanzeigen erscheinen, jederzeit zu &auml;ndern.</p><br/>
<ul>
<li>iVenti stellt seinem Kunden einen direkten Systemzugang zu einer Online-Datenbank zur Verf&uuml;gung, &uuml;ber die sie ihre Dienstleistung/en inserieren k&ouml;nnen. Die Inserate k&ouml;nnen nur nach Ma&szlig;gabe der hierf&uuml;r eingerichteten Eingabemaske erfolgen. Im Standartfall erfolgen Anzeigen f&uuml;r Einzeldienstleistungen (eine Anzeige pro Leistung).</li>
</ul>
<p><br/>Kunden die mehrere Dienstleistungen anbieten, erfordert f&uuml;r jede Dienstleistung eine jeweilige und individuelle Themenbezogene Darstellung der Anzeige. Der Kunde kann nach Freischaltung der Anzeige, weiterhin jederzeit die Anzeigendetails themenbezogen bearbeiten.</p><br/>
<ul>
<li>iVENTI bietet &uuml;ber das Internetportal <a href="http://www.iVenti.de">iVenti.de</a> ggf. zus&auml;tzliche Leistungen unentgeltlich oder gegen Entgelt an. Sofern hierzu nichts anderes vereinbart ist, gelten die vorliegenden Allgemeinen Gesch&auml;ftsbedingungen auch hierf&uuml;r.</li>
</ul>
<p>&nbsp;</p>
<p><strong>4 Pflichten und Obliegenheiten des Kunden bei den vertraglichen Leistungen.</strong></p>
<ul>
<li>Der Kunde darf die vertraglichen Dienste nicht missbr&auml;uchlich nutzen. Unzul&auml;ssig ist das &Uuml;bermitteln und Anbieten von Inhalten unter Versto&szlig; gegen gesetzliche Vorschriften und Verbote oder gegen Schutzrechte, Pers&ouml;nlichkeitsrechte oder sonstige Rechte Dritter. Der Kunde hat insbesondere daf&uuml;r Sorge zu tragen, dass er keine gesetzlich (z.B. durch das Urhaber-, Marken-, Patent-, Geschmacksmuster- oder Gebrauchsmusterrecht) oder vertraglich gesch&uuml;tzten Inhalte Angebote im Widerspruch zu den gesetzlichen Vorschriften zug&auml;nglich gemacht werden. Werden Dritte durch eine solche unzul&auml;ssige Nutzung gesch&auml;digt, hat der Kunde iVENTI von hieraus resultierenden Anspr&uuml;chen Dritter freizustellen, soweit der Kunde diese Nutzung zu vertreten hat.</li>
<li>Der Kunde hat iVENTI unverz&uuml;glich jede &Auml;nderung seines Namens, seiner Firma, seines Sitzes, seiner Rechnungsanschrift, seiner Bankverbindung bzw. Kreditkartennummer mitzuteilen, sofern diese Daten f&uuml;r die Vertragsabwicklung von Bedeutung sind.</li>
<li>Der Kunde ist verpflichtet, geeignete Vorkehrungen gegen eine unbefugte Nutzung der vertraglichen &uuml;berlassenen Benutzeridentifikation und Passw&ouml;rter geheim zu halten. Der Kunde hat die Passw&ouml;rter regelm&auml;&szlig;ig sowie bei hierf&uuml;r gegebenen Anl&auml;ssen (z.B. Ausscheiden eines Mitarbeiters, dem ein Passwort bekannt war) aus seinem Kundenportal heraus zu &auml;ndern. F&uuml;r die Nutzung durch Dritte ist er gegen&uuml;ber iVENTI verantwortlich, soweit er diese Nutzung zu vertreten hat.</li>
<li>Der Kunde verpflichtet sich gegen&uuml;ber iVENTI, keine unzutreffenden Veranstaltungsanzeigen in das vertragliche Informationsportal (<a href="http://www.iVenti.de">iVenti.de</a>) zu inserieren und Details, die unrichtig oder &uuml;berholt sind, unverz&uuml;glich zu berichtigen oder zu l&ouml;schen.</li>
</ul>
<p>&nbsp;</p>
<p><strong>5 Sperre</strong></p>
<ul>
<li>iVenti darf die vertragliche Dienstleistung sperren, wenn der Kunde Veranlassung zu einer fristlosen K&uuml;ndigung des Vertragsverh&auml;ltnisses gegeben hat. Sonstige Rechte von iVenti.de zur Sperrung des Zugangs, insbesondere bei vertragswidriger Nutzung und Zahlungsverzug, bleiben unber&uuml;hrt.</li>
</ul>
<p>&nbsp;</p>
<p><strong>6 Preise, Zahlungsbedingungen Ausschluss von Einwendungen gegen Rechnungen.</strong></p>
<ul>
<li>Die Anzeigenpreise richten sich nach der jeweils aktuellen Preisliste. Die auf der Internetseite <a href="http://www.iVenti.de">iVenti.de</a> zur Einsicht zu Verf&uuml;gung steht.</li>
<li>Die Vertraglichen Entgelte f&uuml;r eine Anzeige, werden im voraus f&uuml;r das laufende Vertragsjahr abgerechnet. Die Rechnungsstellung erfolgt standartm&auml;&szlig;ig elektronisch im PDF Format und wird dem Kunden per Mail an die angegebene Mail Adresse &uuml;bersandt oder im passwortgesch&uuml;tzten Kundenbereich zum Download zu Verf&uuml;gung gestellt. iVenti wei&szlig;t darauf hin, dass eine solche Rechnung nach der gegenw&auml;rtigen Praxis der Finanzverwaltungen nicht zum Vorsteuerabzug berechtigt.</li>
<li>Die vertragliche Leistung wird nur gegen Vorauszahlung f&uuml;r ein Jahr auf Guthabenbasis erbracht.</li>
<li>Der Kunde kann gegen Zahlungsanspr&uuml;che von iVENTI nur mit unbestrittenen oder rechtskr&auml;ftig festgestellten Anspr&uuml;chen aufrechnen. Ein Zur&uuml;ckbehaltungsrecht steht dem Kunden nur wegen Anspr&uuml;chen aus demselben Vertragsverh&auml;ltnis zu.</li>
</ul>
<p>&nbsp;</p>
<p><strong>7 Vertragsdauer und K&uuml;ndigung</strong></p>
<ul>
<li>Der Vertrag einer kostenlosen oder Kostenpflichtigen Anzeige, wird jeweils immer nur f&uuml;r eine Laufzeit von einem Jahr pro Anzeige abgeschlossen und kann bei Bedarf, jedes Jahr durch eine erneute Zustimmung um ein weiteres Jahr verl&auml;ngert werden. Der Kunde kann den Vertrag jederzeit ohne Einhaltung einer Frist, iVENTI mit einer Frist von einem Monat k&uuml;ndigen. Auch im Fall einer K&uuml;ndigung dauert das laufende Vertragsverh&auml;ltnis f&uuml;r eine oder mehrere Anzeigen immer bis zum vereinbarten Vertragsende an.</li>
<li>Das Recht zur au&szlig;ergew&ouml;hnlichen K&uuml;ndigung aus wichtigem Grund bleibt unber&uuml;hrt.</li>
<li>Vorzeitige K&uuml;ndigungen m&uuml;ssen in Textform (per E-Mail oder Brief) an die im Impressum angegebene Anschrift vermittelt werden. Eine auslaufende Anzeige kann im Kunden Bereich nur verl&auml;ngert werden, wenn das vorgegebene Vorgabefeld zur Verl&auml;ngerung aktiviert wird. Bei nicht Aktivierung, l&auml;uft der Vertrag aus und die Anzeigendarstellung wird gel&ouml;scht. Der Vertrag kann jederzeit wieder aktiviert werden.</li>
<li>Der kostenlose Grundeintrag muss jedes Jahr nach der vereinbarten Laufzeit um ein weiteres Jahr im Kunden Bereich verl&auml;ngert werden.</li>
</ul>
<p>&nbsp;</p>
<p><strong>8 &Auml;nderungen der Allgemeinen Gesch&auml;ftsbedingungen</strong></p>
<ul>
<li>Will iVENTI &Auml;nderungen der Allgemeinen Gesch&auml;ftsbedingungen vornehmen, wird die vorgesehene &Auml;nderung dem Kunden per Mail bekannt gegeben. Die &Auml;nderungen gelten als genehmigt, wenn der Kunde nicht per Mail Widerspruch erhebt. Auf diese Folge wird iVENTI bei der Bekanntgabe der &Auml;nderungen besonders hinweisen. Der Widerspruch muss innerhalb von 6 Wochen nach Bekanntgabe der &Auml;nderungen bei iVENTI eingegangen sein. Erfolgt ein solcher Widerspruch, wird der Vertrag ohne die vorgeschlagenen &Auml;nderungen fortgesetzt. Das Recht der Vertragspartner zur K&uuml;ndigung des Vertrages bleibt hiervon unber&uuml;hrt.</li>
<li>Die von iVENTI angegebenen Preise einschlie&szlig;lich Mehrwertsteuer errechnen sich aus Preisen ohne Mehrwertsteuer zuz&uuml;glich der Mehrwertsteuer in der jeweiligen gesetzlich geltenden H&ouml;he. Bei einer &Auml;nderung des Mehrwertsteuersatzes werden die Preise einschlie&szlig;lich geltenden Mehrwertsteuer automatisch entsprechend angepasst.</li>
</ul>
<p>&nbsp;</p>
<p><strong>9 Haftung</strong></p>
<ul>
<li>iVENTI haftet nur bei Vorsatz, grober Fahrl&auml;ssigkeit und der schuldhaften Verletzung von Vertragspflichten, deren Erf&uuml;llung die ordnungsgem&auml;&szlig;e Durchf&uuml;hrung des Vertrages &uuml;berhaupt erst erm&ouml;glicht und auf deren Einhaltung der Vertragspartner regelm&auml;&szlig;ig vertrauen darf (wesentliche Vertragspflichten); trifft iVENTI hierbei nur einfache Fahrl&auml;ssigkeit, ist die H&ouml;he des Schadensersatzes auf den typischerweise vorhersehbaren Schaden begrenzt.</li>
<li>Die Haftung f&uuml;r Sch&auml;den aus der schuldhaften Verletzung des Lebens, des K&ouml;rpers oder der Gesundheit und die Haftung aus Garantien sowie nach dem Produkthaftungsgesetz bleibt unber&uuml;hrt.</li>
</ul>
<p>&nbsp;</p>
<p><strong>10 Gerichtstand, Anwendbares Recht</strong></p>
<p>Ist der Kunde Kaufmann, juristische Person des &ouml;ffentlichen Rechts oder &ouml;ffentliches Sonderverm&ouml;gen, so ist ausschlie&szlig;licher Gerichtsstand f&uuml;r alle Streitigkeiten aus dem Vertragsverh&auml;ltnis der Sitz von iVENTI. Es gilt deutsches Recht.</p>
<p>&nbsp;</p>
<p><strong>11 Datenschutz</strong></p>
<p>iVenti erhebt (unter anderem mit Hilfe von Coockies), verarbeitet und nutzt personenbezogene Daten zum Zweck der Durchf&uuml;hrung dieses Vertrages und s&auml;mtlicher Auftr&auml;ge, die im Zusammenhang hiermit durchgef&uuml;hrt werden, nach Ma&szlig;gabe der einschl&auml;gigen datenschutzrechtlichen Bestimmungen. iVenti ist berechtigt, f&uuml;r die Erbringung und Abrechnung der vertraglichen Leistungen Dritte einzuschalten und die hierf&uuml;r erforderlichen Daten an diese Dritten zu &uuml;bermitteln Die Verpflichtung zur Wahrung der gesetzlichen Datenschutzbestimmung bleibt hiervon unber&uuml;hrt. Nach Beendigung des Vertragsverh&auml;ltnisses werden die personenbezogenen Daten gel&ouml;scht, soweit sie nicht mehr f&uuml;r Abrechnungszwecke ben&ouml;tigt werden.</p>
<p>Sie haben jederzeit ein Recht auf kostenlose Auskunft, Berechtigung, Sperrung und L&ouml;schung ihrer gespeicherten Bitte wenden Sie sich an uns per Mail unter <a href="mailto:hey@iventi.de">hey@iventi.de</a> oder senden sie ihr Verlangen per Post.</p>
<p>Verschl&uuml;sselung: Damit ihre Daten auch auf sicherem Wege zu uns gelangen, wird die Datenkommunikation an den relevanten Stellen &uuml;ber eine 128bit Verschl&uuml;sselung (SSL) erm&ouml;glicht. Je nach verwendetem Browser k&ouml;nnen sie dies beispielweise duch ein geschlossenes Schloss Symbol &uuml;berpr&uuml;fen.</p>
        </div>
    </div>
  )
}

export default AGB