import React from 'react'
import Header from './provider/Header'
import { Link } from 'react-router-dom'

function Impressum() {
	return (
		<div className="legal">
			<Header headline={"IMPRESSUM"} />
			<div style={{ width: "90%",wordBreak: "break-word" ,maxWidth: "1000px", margin: "auto", lineHeight: "25px", paddingBottom: "50px" }} >
				<Link style={{ textDecoration: "none", color: "rgb(249, 179, 0)", fontWeight: "700", marginBottom: "20px", display: "block" }} to="/dashboard/start">Zurück</Link>
				<p><strong>&nbsp;</strong></p>
				<p><strong>Angaben gem&auml;&szlig; &sect; 5 TMG</strong></p>
				<p>e.i. Group</p>
				<p>Marcus Schmelzer</p>
				<p>Friedlandstra&szlig;e 60</p>
				<p>25451 Quickborn</p>
				<p>&nbsp;</p>
				<p>Telefon:&nbsp;04106 640 74 54</p>
				<p>E Mail: <a href="mailto:hey@iventi.de"><strong>hey@iventi.de</strong></a></p>
				<p><strong>&nbsp;</strong></p>
				<p>Inhaltlich verantwortlich gem&auml;&szlig; &sect; 10 Absatz MDStV</p>
				<p>&nbsp;e.i. Group</p>
				<p>&nbsp;</p>
				<p>Plattform der EU-Kommision zur Online-Streitbeilegung:&nbsp; folgt</p>
				<p>Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder verpflichtet noch bereit.</p>
				<p>&nbsp;</p>
				<p><strong>Konzeption und Gestaltung</strong></p>
				<p>Grafik und Layout: Frosh Mediendesign</p>
				<p>Webdesign: we-site GmbH</p>
				<p>&nbsp;</p>
				<p><strong>Haftung f&uuml;r Inhalte&nbsp;</strong></p>
				<p>Als Dienstanbieter sind wir gem&auml;&szlig; &sect; 7 Abs. 1 TMG f&uuml;r eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach &sect;&sect; 8 bis 10 TMG sind wir als Dienstanbieter jedoch nicht verpflichtet, &uuml;bermittelte oder gespeicherte fremde Informationen zu &uuml;berwachen oder nach Umst&auml;nden zu forschen, die auf eine rechtswidrige T&auml;tigkeit hinweisen.&nbsp;</p>
				<p>Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung m&ouml;glich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
				<p>&nbsp;</p>
				<p><strong>Haftung f&uuml;r Links</strong>&nbsp;</p>
				<p>Unser Angebot enth&auml;lt Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb k&ouml;nnen wir f&uuml;r dies fremden Inhalte auch keine Gew&auml;hr &uuml;bernehmen. F&uuml;r die Inhalte der verlinkten Seite ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf m&ouml;gliche Rechtsverst&ouml;&szlig;e &uuml;berpr&uuml;ft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.</p>
				<p>Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne Konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte und Links entfernen.</p>
				<p><strong>&nbsp;</strong></p>
				<p><strong>&nbsp;</strong></p>
				<p><strong>Urheberrecht</strong><strong>&nbsp;</strong></p>
				<p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielf&auml;ltigung, Bearbeitung, Verbreitung und jede Art der Verwertung au&szlig;erhalb der Grenzen des Urheberrechtes bed&uuml;rfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.</p>
				<p>Downloads und Kopien dieser Seite sind nur f&uuml;r den privaten, nicht kommerziellen Gebrauch gestattet.</p>
				<p>Soweit die Inhalte der Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>
			</div>
		</div>
	)
}

export default Impressum