// React
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../context/UserContext';


// CSS
import './CustomerStart.css';

// UI Imports
import Accordion from '../ui/Accordion';
import Header from '../ui/Header';
import ImgButton from '../ui/ImgButton';
import ScrollNav from '../ui/ScollNav';
import SearchForm from '../ui/SearchForm';

//Icons and Images
import iconRubriken from '../../img/Icon_Kategorien_weiss.svg';
import iconInspiration from '../../img/Icon_Inspiration_weiss.svg';
import iconSchnaeppchen from '../../img/Icon_Schnaeppchen_weiss.svg';
import TopShape from '../../img/TrennerObenGrey.svg'
import TopShapeWeiss from '../../img/TrennerObenWeiß.png'
import imgHamburg from '../../img/hamburg.jpg'
import ButtonsLayout from '../ui/ButtonsLayout';
import Trenner from '../ui/Trenner';

function CustomerStart() {
    const navigate = useNavigate();
    const { locationContext, setLocationContext } = useUserContext();

    const imgButtonStyle = {
        width: "100%",
        maxWidth: "400px"
    };

    return (
        <div className="customerStart">
            <Header className="backgroundAnimation" nav="simple">
                <ScrollNav onlyVisibleOnScroll={true} scrollOffset={500} id="scrollNav" notdienst={true} />
                <h2>Einfach alles für dein Event.</h2>
                <SearchForm design="normal" notdienst={true} click={() => navigate("/searchResults")}/>
            </Header>
            <main>
                <section style={{paddingBottom: "0px"}} className="wrapperAngebotStoebern">
                    <h2>Oder stöbere einfach im gesamten Angebot:</h2>
                    <div className="angebotStoebern bigButtons">
                        <button className="backgroundAnimation" onClick={() => navigate('/kategorien')}>
                            <img src={iconRubriken} alt="Rubriken - Icon" />
                            <span>Rubriken</span>
                        </button>
                        <button className="backgroundAnimation" onClick={() => navigate('/inspirationen')}>
                            <img src={iconInspiration} alt="Inspiration - Icon" />
                            <span>Inspiration</span>
                        </button>
                        <button className="backgroundAnimation" onClick={() => navigate('/schnaeppchen')}>
                            <img src={iconSchnaeppchen} alt="Schnäppchen - Icon" />
                            <span>Schnäppchen</span>
                        </button>
                    </div>
                </section>

                <Trenner shape="small" foreground="grey" background="white" />

                <section style={{paddingTop: "30px", paddingBottom: "0px"}} className="wrapperSoFunktioniertIventi">
                    <h2>SO FUNKTIONIERT iVENTI </h2>
                    <div className="bulletpoints">
                        <div className="left">
                            <Accordion title="Alles für dein Event in einem Portal" content="Egal welche Art von Feier oder Event du planst, auf iVenti findest du eine riesige Auswahl von Event-Dienstleistungen und -artikeln. Hier trifft die gesamte Event-Welt zusammen und zeigt allen, die ein Event planen, was sie in deiner Region zu bieten hat." />
                            <Accordion title="Was finde ich unter „Rubriken“?" content="In diesem Bereich findest du alle Dienstleistungen oder Dienstleister:innen, sortiert nach den jeweiligen Rubriken. Wenn du möchtest, kannst du dir beim Stöbern jederzeit eine oder mehrere Merklisten in einem persönlichen Account anlegen. Alternativ kannst du aber auch ohne Account direkt mit Anbieter:innen in Kontakt treten." />
                            <Accordion title="Ständig frische Schnäppchen!" content="Dieser Bereich ist das Paradies für Jäger und Rechner! Hier werden dir von diversen Anbietern in unregelmäßigen Abständen neue Sonderangebote unterbreitet. Eine Sonderaktion dauert jeweils 7 Tage. Es lohnt sich auf jeden Fall, am Ball zu bleiben und immer wieder mal auf dieser Seite vorbeizuschauen." />
                            <Accordion title="MEIN iVENTI – ein „Darf“, kein „Muss“" content="Ein persönlicher Account ist nicht zwingend erforderlich, um bei iVenti Dienstleister:innen zu finden und mit ihnen in Kontakt zu treten. Er dient dir lediglich als zusätzlicher Service, um deine Kontakte auch zu einem späteren Zeitpunkt einzusehen und zu verwalten. 
Vier Wochen nach deinem letzten Event wird dein Account mit allen Daten automatisch gelöscht. Du kannst jederzeit einen neuen Account erstellen, oder du deaktivierst im Account die Löschung und er bleibt weiterhin bestehen." />
                            
                        </div>
                        <div className="right">
                            <Accordion title="Keine Agentur! Nur du und die Anbieter:innen" content="Als Suchende:r trittst du immer direkt mit einem/r Dienstleister:in in Kontakt. iVenti bleibt bei diesem Kontakt außen vor und hat keine Kenntnis von den Inhalten eurer Kommunikation. Von iVenti hast du keinerlei Kosten zu erwarten." />
                            <Accordion title="„Inspiration“? Wie inspirierend!" content="Bei der Planung eines Events fehlen uns manchmal noch Ideen, was wir alles benötigen. Genau dafür gibt es den Bereich „Inspiration“. Hier findest du speziell ausgewählte Themensammlungen, die dir Anregungen geben, welche möglichen Highligts und Dienstleistungen es zu deinem Event gibt und wo sie dir angeboten werden." />
                            <Accordion title="Wozu ist der Notdienst-Button da?" content="Dein Event steht kurz bevor oder läuft womöglich bereits ... und eine:r deiner Diensleis­ter:innen ist kurzfristig abgesprungen! In diesem Fall ist unser Notdienst-Button dein Retter in der Not! Wenn du ihn bei deiner Suche aktivierst, erhältst du sofort alle verfügbaren Dienstleister:innen, die aktuell in deiner Region einen Notdienst anbieten. 
Kleiner Button mit großer Wirkung: Er spart dir viel Zeit, schont deine Nerven ... und dein Event ist gerettet!" />
                            <Accordion title="Was ist eine Merkliste?" content="Mit der Einrichtung eines Accounts hast du die Möglichkeit, mehrere Merklisten für anstehende Events anzulegen. Auf einer Merkliste findest du alle Anbieter:innen, die du als Favoriten markiert hast. Somit kannst du und jede Person, der du deine iVenti-Zugangsdaten gibst, bei deiner Eventplanung jederzeit auf deine Notizen zugreifen. Auch kannst du mehrere Accounts anlegen, z. B. einen für dich und einen weiteren, den du mit anderen teilst
Vier Wochen nach deinem letzten Event werden Merkliste(n) und Account automatisch gelöscht – falls du die Löschung deines Account nicht deaktiviert hast. Du kannst jederzeit ganz einfach wieder einen neuen Account anlegen." />
                            
                        </div>
                    </div>
                </section>
                <Trenner shape="small" foreground="white" background="#F6F5F9" />
                <section style={{paddingTop: "40px"}} className="wrapperStaedte">
                    <h2>STARK IM NORDEN!</h2>
                    <p>Wir starten kraftvoll durch in Hamburg, Schleswig-Holstein und Niedersachsen.<br />Weitere große und mittlere Städte werden zeitnah folgen, und schon bald findest du bei iVenti deutschlandweit alles für dein Event.</p>
                    <ButtonsLayout>
                        <ImgButton style={imgButtonStyle} image={"https://firebasestorage.googleapis.com/v0/b/iventi-6d02a.appspot.com/o/system%2FStadt_Hamburg_iVenti.png?alt=media&token=b01affde-8d5f-4772-8d67-fac5f89acdbd"} onClick={() => {
                            setLocationContext('Hamburg & Umland');
                            navigate(`/kategorien`)
                        }
                        } >
                            <span>Hamburg</span>
                        </ImgButton>
                        <ImgButton style={imgButtonStyle} image={"https://firebasestorage.googleapis.com/v0/b/iventi-6d02a.appspot.com/o/system%2FStadt_Flensburg_iVenti.png?alt=media&token=079baf5d-0d80-48d4-9c9b-7b6085823e37"} onClick={() => {
                            setLocationContext('Flensburg & Umland');
                            navigate(`/kategorien`)
                        }
                        } >
                            <span>Flensburg</span>
                        </ImgButton>
                        <ImgButton style={imgButtonStyle} image={"https://firebasestorage.googleapis.com/v0/b/iventi-6d02a.appspot.com/o/system%2FStadt_Husum_iVenti.png?alt=media&token=f8ac647e-d4c9-4f59-9468-67f9e5ead14e"} onClick={() => {
                            setLocationContext('Husum & Umland');
                            navigate(`/kategorien`)
                        }
                        } >
                            <span>Husum</span>
                        </ImgButton>
                        <ImgButton style={imgButtonStyle} image={"https://firebasestorage.googleapis.com/v0/b/iventi-6d02a.appspot.com/o/system%2FStadt_Kiel_iVenti.png?alt=media&token=0e32f452-c202-4210-afd5-29b96e1f3377"} onClick={() => {
                            setLocationContext('Kiel & Umland');
                            navigate(`/kategorien`)
                        }
                        } >
                            <span>Kiel</span>
                        </ImgButton>
                        <ImgButton style={imgButtonStyle} image={"https://firebasestorage.googleapis.com/v0/b/iventi-6d02a.appspot.com/o/system%2FStadt_Luebeck_iVenti.png?alt=media&token=178ebc78-6870-4adc-869d-183573e250a1"} onClick={() => {
                            setLocationContext('Lübeck & Umland');
                            navigate(`/kategorien`)
                        }
                        } >
                            <span>Lübeck</span>
                        </ImgButton>
                        <ImgButton style={imgButtonStyle} image={"https://firebasestorage.googleapis.com/v0/b/iventi-6d02a.appspot.com/o/system%2FStadt_Lueneburg_iVenti.png?alt=media&token=205f7e20-95d3-498b-9379-a1279e6a3400"} onClick={() => {
                            setLocationContext('Lüneburg & Umland');
                            navigate(`/kategorien`)
                        }
                        } >
                            <span>Lüneburg</span>
                        </ImgButton>
                    </ButtonsLayout>
                </section>
            </main>
        </div>
    )
}

export default CustomerStart;