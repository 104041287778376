import { CircularProgress } from '@mui/material';
import React, { useState, useRef } from 'react';
import './GratisAnfragePopup.css'
import PopUp from './PopUp';

function GratisAnfragePopUp(props) {

    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [success, setSuccess] = useState(false)

    //FORM VALUES 
    const [name, setName] = useState("")
    const [mail, setMail] = useState("")
    const [date, setDate] = useState("")
    const [guests, setGuests] = useState("")
    const [anlass, setAnlass] = useState("")
    const [message, setMessage] = useState("")
    const [phone, setPhone] = useState("")
    const checked = useRef()

    const [mailError, setMailError] = useState(false)
    const [nameError, setNameError] = useState(false)
    const [error, setError] = useState("")


    function validation(){

        setNameError(false)
        setMailError(false)
        setError("")

        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if(name === ""){
            setNameError(true)
            setError("Bitte einen Namen angeben.")
            return false
        } else if(!mail.match(validRegex)){
            setMailError(true)
            setError("Bitte eine gültige E-Mail angeben.")
            return false
        } else if(!checked.current.checked) {
            setError("Bitte akzeptiere unsere AGB und Datenschutzbestimmungen")
            return false
        } else {
            return true
        }

    }

    function sendMail(e){
        e.preventDefault()
        if(validation()){
            setLoadingSubmit(true)
            
            //fetch('http://localhost:5001/iventi-6d02a/us-central1/test/sendMail', {
            fetch('https://us-central1-iventi-6d02a.cloudfunctions.net/test/sendMail', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({email: props.inseratData.email, inserat: props.inseratData.inseratTitel, name: name, userEmail: mail, date: date, guests: guests, anlass: anlass, phone: phone, message: message})
            })
            .then((response) => {return response.text()})
            .then((data) => {
                setLoadingSubmit(false)
                setSuccess(true)
            })
            .catch((err) => {
                setError("Es ist ein Fehler aufgetreten.")
                setLoadingSubmit(false)
            })
        } 
    }

	return (
		<PopUp setPopupVisible={props.setPopupVisible} className="gratisAnfrage">
            {!success &&<>
            <h3>GRATIS-ANFRAGE BEI {props.inseratData.anbieterName}</h3>
            <h4>{props.inseratData.inseratTitel}</h4>
            <form>
                <div className="contactHalf">
                    <input onChange={(e) => setName(e.target.value)} style={{border: nameError ? "solid 1px red" : "solid 1px lightgrey", width: "50%"}} type="text" name="name" placeholder="Vor- und Nachname"/>
                    <input onChange={(e) => setMail(e.target.value)} style={{border: mailError ? "solid 1px red" : "solid 1px lightgrey",width: "50%"}} type="text" name="email" placeholder="E-Mail-Adresse"/>
                </div>
                <div className="contactHalf">
                    <input onChange={(e) => setPhone(e.target.value)} style={{width: "50%"}} type="text" name="phone" placeholder="Telefon (optional)"/>
                    <input onChange={(e) => setDate(e.target.value)} style={{width: "50%"}} type="text" name="wunschtermine" placeholder="Wunschtermin(optional)"/>
                </div>
                <div className="contactHalf">
                    <input onChange={(e) => setGuests(e.target.value)} style={{width: "50%"}} type="text" name="anzahl-gaeste" placeholder="Anzahl Gäste (optional)"/>
                    <input onChange={(e) => setAnlass(e.target.value)} style={{width: "50%"}} type="text" name="anlass-event" placeholder="Anlass/Event (optional)"/>
                </div>
                
                <textarea onChange={(e) => setMessage(e.target.value)} type="textarea" name="nachricht" placeholder="Meine Nachricht"/>
                <label className="lblAgb-check">
                    <input ref={checked} type="checkbox" name="agb-check"/>
                    <span>
                    Ich habe die AGB zur Kenntnis genommen und akzeptiere sie.
                    Mir ist bekannt, dass ich mit Bestätigung des "Absenden"-Buttons einen Kontakt <b>ausschließlich mit dem Anbieter/Inserenten</b> aufnehme. Die Firma iVenti ist in dieser Kommunikation lediglich als Vermittler involviert, erhält keinerlei Kenntnis von deren Inhalt und speichert keine Daten.
                    </span>
                </label>
                {error && <p style={{color: "red"}}>{error}</p>}
                {loadingSubmit ? 
                    <div className="submitFlex" style={{backgroundColor: "unset", padding: "0px 0 20px 0"}}>
                        <button style={{width: "100%"}} className="loadingButton"><CircularProgress style={{color: "white", width: "31px", height: "31px" }} /></button>
                    </div> 
                    : 
                    <button style={{ marginBottom: "20px" }} onClick={(e) => sendMail(e)} className="submitAdButton">ANBIETER KONTAKTIEREN</button>
                }
                
            </form>
            </>}
            {success && <>
                <h3>GESCHAFFT!</h3>
                <h4>VIELEN DANK FÜR DEINE ANFRAGE.</h4>
            </>}
        </PopUp>
	)
}

export default GratisAnfragePopUp;