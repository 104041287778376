// React
import React, { useState } from "react";
import { useUserContext } from "../../context/UserContext";

//CSS
import "./SearchForm.css";

// UI Imports
import MenuItem from "@mui/material/MenuItem";
import CustomSelect from "../ui/CustomSelect";
import NotdienstButton from "../ui/NotdienstButton";

const SearchForm = (props) => {
	//const [eventSuche, setEventSuche] = useState();
	const { locationContext, setLocationContext } = useUserContext();
	const { notdienstSelectedDays, search, setSearch } = useUserContext();
	let customSelectStyle = {};
	let classes = props.className ? props.className + " " : "";

	if (!props.design || props.design == "normal") {
		classes += "normal-design";
		customSelectStyle =
			notdienstSelectedDays.length != 0
				? { "& .MuiOutlinedInput-root": { height: "65px", color: "red" } }
				: { "& .MuiOutlinedInput-root": { height: "65px" } };
	} else if (props.design == "inside-scroll-nav") {
		classes += "inside-scroll-nav-design";
		customSelectStyle = {
			"& .MuiOutlinedInput-root": {
				height: "55px",
				backgroundColor: "transparent",
				border: "solid 2px #fff!important",
				color: "#fff",
				fontWeight: 600,
				"& .MuiOutlinedInput-notchedOutline": {
					boxShadow: "none",
				},
				"& .MuiSelect-icon": {
					color: "#fff",
				},
			},
		};
	}

	if (props.notdienst === true) {
		classes += " notdienstActive";
	}

	const menuItemStyle = {
		fontFamily: "Spartan, sans-serif !important",
		fontSize: "14px !important",
		["&.Mui-selected"]: {
			background: "rgba(249, 179, 0, .3) !important",
		},
		["&:hover"]: {
			background: "rgba(249, 179, 0, .3) !important",
		},
	};

	function runFunction(e) {
		e.preventDefault();
		props.click();
	}

	return (
		<form className={`searchForm ${classes}`} id={props.id}>
			<CustomSelect
				sx={customSelectStyle}
				error={false}
				className="selectEventOrt"
				select={locationContext}
				setSelect={setLocationContext}
			>
				<MenuItem sx={{ display: "block", ...menuItemStyle }} value={0}>
					<b>Wo</b> soll dein Event stattfinden?
				</MenuItem>
				<MenuItem sx={menuItemStyle} value={"Hamburg & Umland"}>
					Hamburg & Umland
				</MenuItem>
				<MenuItem sx={menuItemStyle} value={"Flensburg & Umland"}>
					Flensburg & Umland
				</MenuItem>
				<MenuItem sx={menuItemStyle} value={"Kiel & Umland"}>
					Kiel & Umland
				</MenuItem>
				<MenuItem sx={menuItemStyle} value={"Lübeck & Umland"}>
					Lübeck & Umland
				</MenuItem>
				<MenuItem sx={menuItemStyle} value={"Neumünster & Umland"}>
					Neumünster & Umland
				</MenuItem>
				<MenuItem sx={menuItemStyle} value={"Husum & Umland"}>
					Husum & Umland
				</MenuItem>
				<MenuItem sx={menuItemStyle} value={"Lüneburg & Umland"}>
					Lüneburg & Umland
				</MenuItem>
			</CustomSelect>
			<label
				className="labelEventSuche"
				style={
					props.design == "normal" && notdienstSelectedDays.length != 0
						? { color: "red" }
						: {}
				}
			>
				<input
					className={"inpEventSuche " + (search ? "hasContent" : "")}
					name="eventSuche"
					type="text"
					value={search}
					onChange={(event) => setSearch(event.target.value)}
					style={
						props.design == "normal" && notdienstSelectedDays.length != 0
							? { color: "red" }
							: {}
					}
				/>
				<span>
					<b>Was</b> suchst du für dein Event?
				</span>
			</label>
			{props.notdienst && <NotdienstButton />}
			<button className="btnSuchen" onClick={(e) => runFunction(e)}>
				SUCHEN!
			</button>
		</form>
	);
};

export default SearchForm;
