import React from 'react';
import IventiLogo from '../../img/LogoIventiWeiß.svg'
import './Header.css'
import TopShapeGrey from '../../img/TrennerObenGreySmall.png'

function Header(props) {
	return (
	<div className="Header">
			<img className="logo" src={IventiLogo} />
			<div className="db_header" style={{height: "unset", padding: "100px 0 0 0"}}>
				<div className="db_headline">{props.headline}</div>
			</div>
			<div className="lineBreakTopWhite">
					<img style={{display: "block", width: "100%"}} src={TopShapeGrey} />
			</div>
	</div>
	)
}

export default Header;
