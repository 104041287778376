import React, { useState, useEffect, useRef } from 'react';
import './CreateAd.css';
import { db, storage } from '../../Firebase'
import { arrayUnion ,collection, getDocs, getDoc, doc, query, where, setDoc } from 'firebase/firestore';
import Checkbox from '@material-ui/core/Checkbox';
import Loader from '../Loader';
import ProviderGallery from './ProviderGallery';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CelebrationIcon from '@mui/icons-material/Celebration';
import CircularProgress from '@mui/material/CircularProgress';
import InfoIcon from '@mui/icons-material/Info';
import { ref, getDownloadURL, uploadBytesResumable, deleteObject, uploadBytes } from "firebase/storage";
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import PopUp from '../PopUp';
import DayPickerCustom from './DayPickerCustom';
import DayPickerCustomFunction from './DayPickerCustomFunction';
import { useUserContext } from '../../context/UserContext';
import CustomSelect from './CustomSelect';
import MenuItem from '@mui/material/MenuItem';
import TopShape from '../../img/TrennerObenWeißSmall.svg'
import TopShapeGrey from '../../img/TrennerObenGreySmall.svg'
import Trenner from '../ui/Trenner';

//allow read, write: if request.auth.uid == resource.data.uid;

const userID = "test123"

function EditAd() {

	const [saveAdress, setSaveAdress] = useState(false)

	const { user } = useUserContext()

	const categorieRef = useRef()

	const navigate = useNavigate()

	const zeroRef = useRef()
	const plusRef = useRef()

	const [loading, setLoading] = useState(true)
	const [loadingSubmit, setLoadingSubmit] = useState(false)
	const [categories, setCategories] = useState([])
	const [services, setServices] = useState(0)
	const [service, setService] = useState(0)
	const [servicesName, setServicesName] = useState("")
	const [serviceSelect, setServiceSelect] = useState(0)
	const [collectionId, setCollectionId] = useState(null)
	const [keywords, setKeywords] = useState({})
	const [documentID, setDocumentID] = useState(Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15))
	const [type, setType] = useState("plus")
	const [error, setError] = useState("")
	const [urls , setUrls] = useState([])
	const [edit , setEdit] = useState(false)
	const [selectedDays , setSelectedDays] = useState([])
	const [isFirst, setIsFirst] = useState()
	const [isZero, setIsZero] = useState()
	const [isFlat, setIsFlat] = useState()
	const [state, setState] = useState("")
	const [message, setMessage] = useState("")

	//QuickInfo
	const [serviceQuick, setServiceQuick] = useState(0)
	const [nameQuick, setNameQuick] = useState("")

	const [alarm, setAlarm] = useState(false)

	
	const [title, setTitle] = useState("")
	const [address, setAddress] = useState("")
	const [location, setLocation] = useState("")
	const [zip, setZip] = useState("")
	const [radius, setRadius] = useState(10)
	const [city, setCity] = useState("")
	const [headline, setHeadline] = useState("")
	const [headline2, setHeadline2] = useState("")
	const [text, setText] = useState("")
	const [text2, setText2] = useState("")

	const [images, setImages] = useState([])
	const [imagesUpload, setImagesUpload] = useState([])
	//const [oldImages, setOldImages] = useState([])


	//validation
	const [errorTitle, setErrorTitle] = useState(false)
	const [errorService, setErrorService] = useState(false)
	const [errorName, setErrorName] = useState(false)
	const [errorZip, setErrorZip] = useState(false)
	const [errorLocation, setErrorLocation] = useState(false)
	const [errorLocationSelect, setErrorLocationSelect] = useState(false)
	const [errorHeadline, setErrorHeadline] = useState(false)
	const [errorText, setErrorText] = useState(false)

	/*
	useEffect(() => {
		if(type === "zero"){
			setKeywords([])
			setHeadline(null)
			setHeadline2(null)
			setText(null)
			setText2(null)
		}
	}, [type]);
	*/

	/*
	function saveToDb(){
		setImagesUpload([])
		var promises = []
		images.map((item, index) => {
			if(item.uploaded){
				
			} else {
			if(item.type === "img"){
				var storageRef = ref(storage, `userData/${user.uid}/img/${Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}`)
			} else if(item.type === "video"){
				var storageRef = ref(storage, `userData/${user.uid}/video/${Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}`)
			}
			var uploadTask = uploadBytesResumable(storageRef, item.file)
			promises.push(uploadTask)
			uploadTask.on("state_changed", (snapshot) => {
				//nothing
				console.log("upload")						
			},
				(error) => {
					console.log(error)
				},
				async () => {
					await getDownloadURL(uploadTask.snapshot.ref).then((url) => {
						setDoc(doc(db, "posts", documentID), {
							img: arrayUnion({ id: uploadTask.snapshot.ref.name, url: url, type: item.type, upoaded: true }),
							state: "progress",
							prem: false
						}, { merge: true }).then(() => console.log("dones"))
					})
				})
			}
		})
		Promise.all(promises).then(() => {
			console.log("done all")
		}).catch((err) => {
			console.log("error")
		})
	}
	*/

	function handlePlan(value){
		if(value === "zero"){
			if(images.length > 1){
				setError("Du kannst nur ein Bild in iVenti Zero benutzen. Lösche deine Bilder und versuche es erneut.")
			} else if(images[0]?.type === "video"){
				setError("Du kannst nur ein Bild in iVenti Zero benutzen. Lösche dein Video und versuche es erneut.")
			} else {
				setKeywords([])
				setHeadline("")
				setHeadline2("")
				setText("")
				setText2("")
				setType("zero")
			}
		} else {
			setType(value)
		}
	}
	

	const { id } = useParams()

	function handleCheckbox(value){
		if(value.target.checked){
			if(keywords[value.target.getAttribute("data-group")]) {
				keywords[value.target.getAttribute("data-group")].push(value.target.value)
			} else {
				keywords[value.target.getAttribute("data-group")] = [value.target.value]
	
			}
		} else {
			keywords[value.target.getAttribute("data-group")].splice(keywords[value.target.getAttribute("data-group")].indexOf(value.target.value), 1)
			if(keywords[value.target.getAttribute("data-group")].length === 0){
				delete keywords[value.target.getAttribute("data-group")]
			}
		}
		setKeywords({...keywords})
	}
	
	async function runProgress(value) {
		if(value.state === 1 && value.data !== "0"){
			setServiceSelect(0)
			setService(0)
			const querySnapshot =  await getDoc(doc(db, "categories", value.data))
			setServices(querySnapshot.data().services)
			setServicesName(querySnapshot.data().name)
			setCollectionId(querySnapshot.id)		
		} 

		if(value.state === 1 && value.data === "0"){
			setServiceSelect(0)
			setService(0)
			setServices(0)
			setServicesName("")
			setCollectionId(0)		
		} 

		if(value.state === 2 && value.data !== "0"){
			setServiceSelect(value.data)
			const querySnapshot =  await getDoc(doc(db, "categories", collectionId))
			setService(querySnapshot.data().services[value.data])
		} 

		if(value.state === 2 && value.data === "0"){
			setServiceSelect(0)
			setService(0)
		} 
	}

	/*
	function saveAd() {
		setLoadingSubmit(true)
		if(type !== "zero"){
			if(address === "" || location === "" || title === "" || images.length === 0 || headline === "" || text === ""){
				setError("Bitte alle Pflichtfelder ausfüllen und ein Bild hochladen.")
				setLoadingSubmit(false)
				return
			}
		}
		var promises = []
		images.map((item, index) => {
			if(item.uploaded){
				if(index === 0){
					setDoc(doc(db, "posts", documentID), {
						services: collectionId,
						servicesName: servicesName,
						service: serviceSelect,
						address: address,
						location: location,
						keywords: keywords,
						title: title,
						state: "cart",
						radius: radius,
						headline: headline,
						text: text,
						headline2: headline2,
						text2: text2,
						type: type,
						img: [{ id: item.id, url: item.url, type: item.type, uploaded: true }],
						prem: false
					}).then(() => console.log("dones"))
				} else {
					setDoc(doc(db, "posts", documentID), {
						img: arrayUnion({ id: item.id, url: item.url, type: item.type, uploaded: true }),
						state: "progress",
						prem: false
					}, { merge: true }).then(() => console.log("dones"))
				}
			} else {

				//CLEAR DB AND SAVE NEW ORDER IN IMAGE, ALSO ERSTMAL WERT AUF NULL UND DANN WEITER
				if(item.type === "img"){
					var storageRef = ref(storage, `testCollection/img/${Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}`)
				} else if(item.type === "video"){
					var storageRef = ref(storage, `testCollection/video/${Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}`)
				}
				var uploadTask = uploadBytesResumable(storageRef, item.file)
				promises.push(uploadTask)
				uploadTask.on("state_changed", (snapshot) => {
					//nothing
					console.log("upload")						
				},
					(error) => {
						console.log(error)
					},
					async () => {
						await getDownloadURL(uploadTask.snapshot.ref).then((url) => {
							if(index === 0){
								setDoc(doc(db, "posts", documentID), {
									services: collectionId,
									servicesName: servicesName,
									service: serviceSelect,
									address: address,
									location: location,
									keywords: keywords,
									title: title,
									state: "cart",
									radius: radius,
									headline: headline,
									text: text,
									headline2: headline2,
									text2: text2,
									type: type,
									img: arrayUnion({ id: uploadTask.snapshot.ref.name, url: url, type: item.type, uploaded: true }),
									state: "progress",
									prem: false
								}, { merge: true }).then(() => console.log("dones"))
							} else {
								setDoc(doc(db, "posts", documentID), {
									img: arrayUnion({ id: uploadTask.snapshot.ref.name, url: url, type: item.type, uploaded: true }),
									state: "progress",
									prem: false
								}, { merge: true }).then(() => console.log("dones"))
							}
						})
					})			
				}
		})

		Promise.all(promises).then(() => {
			console.log("done all")
			setLoadingSubmit(false)
		}).catch((err) => {
			console.log("error")
		})

		/* OLD
		setDoc(doc(db, "posts", documentID), {
			services: collectionId,
			servicesName: servicesName,
			service: serviceSelect,
			address: address,
			location: location,
			keywords: keywords,
			title: title,
			state: "cart",
			radius: radius,
			headline: headline,
			text: text,
			headline2: headline2,
			text2: text2,
			type: type
		}, { merge: true }).then(() => {
			navigate("/cart")
		}).catch(() => {
			setLoadingSubmit(false)
			setError("Es ist ein Fehler aufgetreten")
		})
		 
	}*/

	/*
	function uploadImage(item, index, imagesToUpload){
		if(item.type === "img"){
			var storageRef = ref(storage, `userData/${user.uid}/img/${Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}`)
		} else if(item.type === "video"){
			var storageRef = ref(storage, `userData/${user.uid}/video/${Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}`)
		}
		return uploadBytes(storageRef, item.file).then((snapshot) => {
			return getDownloadURL(snapshot.ref).then((url) => {
				imagesToUpload.push({order: index, item: { id: snapshot.ref.name, url: url, type: item.type, uploaded: true }})
			})
		})
	}
	*/

	//NEW WITH PROMISE
	function uploadImage(item, index, imagesToUpload){
		

		var innerPromise = []

		if(item.type === "img"){
			var storageRef = ref(storage, `userData/${user.uid}/img/${Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}`)
			innerPromise.push(uploadBytes(storageRef, item.file))
		} else if(item.type === "video"){
			var storageRef = ref(storage, `userData/${user.uid}/video/${Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}`)
			innerPromise.push(uploadBytes(storageRef, item.file))
			var storageRefTwo = ref(storage, `userData/${user.uid}/img/${Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}`)
			innerPromise.push(uploadBytes(storageRefTwo, item.thumb))
		}

		return Promise.all(innerPromise).then((event) => {
			if(event.length === 1){
				return getDownloadURL(event[0].ref).then((url) => {
					imagesToUpload.push({order: index, item: { id: event[0].ref.name, url: url, type: item.type, uploaded: true }})
				})
			}
			if(event.length === 2){
				return getDownloadURL(event[0].ref).then((url) => {
					return getDownloadURL(event[1].ref).then((url2) => {
						imagesToUpload.push({order: index, item: { id: event[0].ref.name, url: url, type: item.type, uploaded: true, thumb: url2 }})
					})
				})
			}
		})
	}

	function saveAd() {
		setLoadingSubmit(true)
		var imagesToUpload = []
		if(type !== "zero"){
			if(location === "" || title === "" || images.length === 0 || headline === "" || text === "" || nameQuick === "" || zip === "" || city === 0 || serviceQuick === 0){
				validation()
				setError("Bitte alle Pflichtfelder ausfüllen und ein Bild hochladen.")
				setLoadingSubmit(false)
				return
			}
			else if(images[0].type === "video"){
				setError("An Position 1 muss ein Bild sein.")
				setLoadingSubmit(false)
				return
			}
		}

		if(type === "zero"){
			if(location === "" || title === "" || images.length === 0 || nameQuick === "" || zip === "" || city === 0 || serviceQuick === 0){
				validation()
				setError("Bitte alle Pflichtfelder ausfüllen und ein Bild hochladen.")
				setLoadingSubmit(false)
				return
			}
			else if(images[0].type === "video"){
				setError("An Position 1 muss ein Bild sein.")
				setLoadingSubmit(false)
				return
			}
		}

		var promises = []
		images.map((item, index) => {
			if(item.uploaded){
				imagesToUpload.push({order: index, item: item})
			} else {
				promises.push(uploadImage(item, index, imagesToUpload))	
			}
		})

		Promise.all(promises).then(() => {
			imagesToUpload.sort((a, b) => {
				return a.order - b.order
			})

			var tempArray = []

			imagesToUpload.forEach((elem) => {
				tempArray.push(elem.item)
			})

			setDoc(doc(db, "posts", documentID), {
				//services: collectionId,
				//servicesName: servicesName,
				service: serviceSelect,
				address: address,
				location: location,
				keywords: keywords,
				title: title,
				state: type === "zero" || state === "running" || state === "error" ? "check" : "cart",
				radius: radius,
				headline: headline,
				text: text,
				headline2: headline2,
				text2: text2,
				type: type,
				img: tempArray,
				selectedDays: selectedDays,
				serviceQuick: serviceQuick,
				nameQuick: nameQuick,
				zip: zip,
				radius: radius,
				city: city,
				alarm: alarm
			}, {merge: true}).then(() => {

				if(saveAdress) {
					setDoc(doc(db, "userData", user.uid), {
						address: address,
						zip: zip,
						location: location,
						company: nameQuick
					}, {merge: true})
				}
				//console.log(state)
				if(type === "zero" || state === "running" || state === "error"){
					navigate("/dashboard/adsProfile")
				} else if(state === "cart") {
					navigate("/dashboard/cart")
				}
				
				setLoadingSubmit(false)
			}).catch((err) => {
				setLoadingSubmit(false)
				console.log(err)
				setError("Es ist ein Fehler aufgetreten")
			})
		}).catch((err) => {
			setLoadingSubmit(false)
			setError("Es ist ein Fehler aufgetreten")
			//console.log("error " , err)
		})
	}

    function getUserData(id){
		getDoc(doc(db, "posts", id)).then((data) => {
			//getDoc(doc(db, "categories", data.data().services)).then((innerData) => {
			//console.log({test: data.data().services})
			getDocs(query(collection(db, "categories"), where("name", "==", data.data().services))).then((innerData) => {

				setService(innerData.docs[0].data().services[data.data().service])
				setTitle(data.data().title)
				//setServicesName(innerData.docs[0].data().name)
				console.log({test: innerData.docs[0].data().name})
				setCollectionId(innerData.docs[0].id)
				setServiceSelect(data.data().service)
				setType(data.data().type)
				setAddress(data.data().address)
				setLocation(data.data().location)
				setRadius(data.data().radius)
				setHeadline(data.data().headline)
				setText(data.data().text)
				setHeadline2(data.data().headline2)
				setText2(data.data().text2)
				setKeywords(data.data().keywords ? data.data().keywords : [])
				setImages(data.data().img)
				setNameQuick(data.data().nameQuick)
				setZip(data.data().zip)
				setServiceQuick(data.data().serviceQuick)
				setCity(data.data().city)
				setState(data.data().state)
				setAlarm(data.data().alarm)
				setMessage(data.data().errorMessage)

				var tempDaysArray = []
				data.data().selectedDays.forEach((item) => {
					tempDaysArray.push(item.toDate())
				})
				setSelectedDays(tempDaysArray)
				setLoading(false)
			})
		})
	}

	async function getAds(){

		var returnObject = {}

		const q = query(collection(db, "posts"), where("state", "==", "cart"), where("uid", "==", user.uid))
		const docs = getDocs(q)
		const docs2 = getDocs(query(collection(db, "posts"), where("prem", "==", true), where("uid", "==", user.uid)))
		const docs3 = getDocs(query(collection(db, "posts"), where("type", "==", "zero"), where("uid", "==", user.uid)))
		const docs4 = getDocs(query(collection(db, "posts"), where("type", "==", "flat"), where("uid", "==", user.uid)))

		const [docsSnapshot, docs2Snapshot, docs3Snapshot, docs4Snapshot] = await Promise.all([
			docs,
			docs2,
			docs3,
			docs4
		])

		if(docsSnapshot.size > 0 || docs2Snapshot.size > 0){
			returnObject["first"] = true
		} else {
			returnObject["first"] = false
		}

		if(docs3Snapshot.size > 0){
			returnObject["zero"] = true
		} else {
			returnObject["zero"] = false
		}

		if(docs4Snapshot.size > 0){
			returnObject["flat"] = true
		} else {
			returnObject["flat"] = false
		}

		return returnObject


	}


	useEffect(() => {
		if(!user) return
		setLoading(true)
		if(id){
			setDocumentID(id)
			getUserData(id)
			setEdit(true)
		} else {
			getDocs(collection(db, "categories")).then((docs) => {
				docs.forEach((doc) => {
					categories.push({id: doc.id, name: doc.data().name})
				})
				setCategories([...categories])
				getAds().then((e) => {
					setIsFirst(e.first)
					setIsZero(e.zero)
					setIsFlat(e.flat)
					setLoading(false)
				}).catch(() => {
					//nothing
				})
			}).catch(() => {
				//nothing
			})
		}
	}, []);		

	const menuItemStyle = {
		fontFamily: 'Spartan, sans-serif !important',
		fontSize:'14px !important',
		['&.Mui-selected']:{
			background:'rgba(249, 179, 0, .3) !important'
		},
		['&:hover']:{
			background:'rgba(249, 179, 0, .3) !important'
		}
	};

	function validation(){
		//reset
		setErrorTitle(false)
		setErrorService(false)
		setErrorName(false)
		setErrorZip(false)
		setErrorLocation(false)
		setErrorLocationSelect(false)
		setErrorHeadline(false)
		setErrorText(false)

		if(title === "") setErrorTitle(true)
		if(serviceQuick === 0) setErrorService(true)
		if(nameQuick === "") setErrorName(true)
		if(zip === "") setErrorZip(true)
		if(location === "") setErrorLocation(true)
		if(city === 0) setErrorLocationSelect(true)
		if(headline === "") setErrorHeadline(true)
		if(text === "") setErrorText(true)
	}

	function sortInputArray(array){
		array.sort(function(a, b) {
			var nameA = a.toUpperCase() // ignore upper and lowercase
			var nameB = b.toUpperCase() // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		})

		return array

	}

	return (
		<>	{!user.emailVerified ?  <p>BESTÄTIGE DEINE E-MAIL-ADRESSE. DANACH KANNST DU INSERATE ANLEGEN</p> : 
			<>
			{!user && <Navigate to="/login"/>}
			{error && <PopUp message={error} setError={setError} />}
			{loading ? <Loader />
			:
			<>
			<div style={{paddingBottom: "0px"}} className="CreateAd">
				<div className="headline">
					{id ? <h2>Inserat bearbeiten</h2> : <h2>Neues Inserat anlegen</h2>}
				</div>
				{!id && 
				<div className="productChoose">
					{!isFirst && !isZero && 
					<a className={"checkboxSwitch" + (type === "zero" ? " activePlan" : "")} onClick={() => handlePlan("zero")}>
						iVenti ZERO (0€/Jahr)
					</a>
					}
					{!isFlat && 
					<a className={"checkboxSwitch" + (type === "plus" ? " activePlan" : "")} onClick={() => handlePlan("plus")}>
						{isFirst ? "iVenti PLUS (49€/Jahr)" : "iVenti PLUS (99€/Jahr)"}
					</a>
					}
					<a className={"checkboxSwitch" + (type === "flat" ? " activePlan" : "")} onClick={() => handlePlan("flat")}>
						iVenti FLAT (149€/Jahr)
					</a>
				</div>
				}

				{!id && 

				<div className="selectContainer">
					<select ref={categorieRef} onChange={(e) => runProgress({state: 1, data: e.target.value})}>
						<option defaultValue={true} value={0}>Rubrik - Bitte wählen*</option>
						{categories.map((categorie, index) => (
							<option key={index} value={categorie.id}>{categorie.name}</option>
						))}
					</select>
					<select value={serviceSelect} onChange={(e) => runProgress({state: 2, data: e.target.value})} disabled={services === 0 ? true : false}>
						<option defaultValue={true} value={0}>Produkt/Leistung - Bitte wählen*</option>
						{Object.keys(services).map((service, index) => (
							<option key={index} value={service}>{service}</option>
						))}
					</select>
				</div>

				}

				{state === "error" && 
					<div>
						<p>Feedback: {message}</p>
					</div>
				}


				{service !== 0 &&

				<>

				<div style={{display: "flex",gap: "20px"}}>
					<input style={{width: "50%",border: errorTitle ? "1px solid red" : "", fontWeight: "800"}} value={title} maxLength="50" className="textField textFieldHeadlineMiddle" type="text" placeholder="DEINE INSERATSÜBERSCHRIFT" onChange={(e) => setTitle(e.target.value)}/>		
					<div style={{width: "50%"}}>
						<CustomSelect error={errorService} className="selectItem" select={serviceQuick} setSelect={setServiceQuick}>
							<MenuItem sx={menuItemStyle} value={0}>Wähle eine Leistung</MenuItem>
							{sortInputArray(service["keywords"][0].words).map((item, index) => (
								<MenuItem sx={menuItemStyle} key={index} value={item}>{item}</MenuItem>
							))
							}
						</CustomSelect>
					</div>
				</div>


				<div className="galleryAndContent">
					<div className="firstColumn">
						<ProviderGallery edit={edit} documentID={documentID} type={type} images={images} setImages={setImages} />
						{type !== "zero" &&
						<>
							<input style={{border: errorHeadline ? "1px solid red" : ""}} maxLength="30" value={headline} className="textField textFieldHeadlineMiddle" type="text" placeholder="Überschrift" onChange={(e) => setHeadline(e.target.value)}/>
							<textarea style={{border: errorText ? "1px solid red" : ""}} value={text} rows="4" maxLength={2500} placeholder="Gib hier einen freien Text ein (max. 2500 Zeichen)" onChange={(e) => setText(e.target.value)}></textarea>
							<input maxLength="50" value={headline2} className="textField textFieldHeadlineMiddle" type="text" placeholder="Weitere Überschrift (optional)" onChange={(e) => setHeadline2(e.target.value)}/>
							<textarea value={text2} rows="4" maxLength={2500} placeholder="Gib hier einen weiteren freien Text ein (max. 2500 Zeichen)" onChange={(e) => setText2(e.target.value)}></textarea>
						</>
						}
					</div>
					<div className="secondColumn">


						<input style={{marginTop: "0px", border: errorName ? "1px solid red" : ""}} value={nameQuick} maxLength="50" className="textField" type="text" placeholder="Name/Firma/Künstler*" onChange={(e) => setNameQuick(e.target.value)}/>
						<input value={address} maxLength="50" className="textField" type="text" placeholder="Straße und Hausnummer" onChange={(e) => setAddress(e.target.value)}/>
						<input maxLength="5" style={{width: "40%", border: errorZip ? "1px solid red" : ""}} className="textField" type="number" placeholder="Plz" value={zip} onChange={(e) => setZip(e.target.value)}/>
						<input maxLength="30" value={location} style={{width: "57%", float: "right", border: errorLocation ? "1px solid red" : ""}} className="textField" type="text" placeholder="Ort" onChange={(e) => setLocation(e.target.value)} />
						
						<div style={{marginTop: "20px", display: "flex", alignItems: "center"}}>
							<input style={{flexBasis: "unset"}} checked={saveAdress} type="checkbox" className="checkboxContainer" onChange={(e) => setSaveAdress(e.target.checked)}/>
							<div style={{marginLeft: "10px", flexBasis: "unset"}}>Angaben in Profil speichern.</div>
						</div>

						<div style={{marginTop: "20px",textTransform: "uppercase", fontWeight: "700"}}>Wo willst du gefunden werden?</div>
						<CustomSelect error={errorLocationSelect} className="selectItem" select={city} setSelect={setCity}>
							<MenuItem sx={menuItemStyle} value={0}>Ort wählen</MenuItem>
							<MenuItem sx={menuItemStyle} value={"Hamburg & Umland"}>Hamburg & Umland</MenuItem>
							<MenuItem sx={menuItemStyle} value={"Flensburg & Umland"}>Flensburg & Umland</MenuItem>
							<MenuItem sx={menuItemStyle} value={"Kiel & Umland"}>Kiel & Umland</MenuItem>
							<MenuItem sx={menuItemStyle} value={"Lübeck & Umland"}>Lübeck & Umland</MenuItem>
							<MenuItem sx={menuItemStyle} value={"Neumünster & Umland"}>Neumünster & Umland</MenuItem>
							<MenuItem sx={menuItemStyle} value={"Husum & Umland"}>Husum & Umland</MenuItem>
							<MenuItem sx={menuItemStyle} value={"Lüneburg & Umland"}>Lüneburg & Umland</MenuItem>
						</CustomSelect>

						<div style={{marginTop: "35px", textTransform: "uppercase", fontWeight: "700"}}>Kurzinfo</div>
						<div className="infoPoint">
							<div><CelebrationIcon/></div>
							<div style={{marginLeft: "5px"}}><strong>{serviceSelect}</strong></div>
						</div>
						<div className="infoPoint">
							<div><InfoIcon/></div>
							<div style={{marginLeft: "5px"}}>{serviceQuick ? <strong>{serviceQuick}</strong> : "Deine Leistung"}</div>
						</div>
						<div className="infoPoint">
							<div><InfoIcon/></div>
							<div style={{marginLeft: "5px"}}>{nameQuick ? <strong>{nameQuick}</strong> : "Dein Name"}</div>
						</div>
						<div style={{marginBottom: "25px"}} className="infoPoint">
							<div><LocationOnIcon/></div>
							<div style={{marginLeft: "5px"}}>{address || location || zip ? (<><strong>{address}{address && (zip || location) ? ", " : "" }{zip} {location}</strong></>) : "Deine Adresse"}</div>
						</div>

						{/*
						<CustomSelect className="selectItem" select={radius} setSelect={setRadius}>
							<MenuItem sx={menuItemStyle} value={10}>+ 10 km</MenuItem>
							<MenuItem sx={menuItemStyle} value={50}>+ 50 km</MenuItem>
						</CustomSelect>
						*/}
						{type !== "zero" && service !== 0 && service.keywords.map((point, index) => (
							<div className="fullServiceContainer" key={index}>
								<p style={{paddingBottom: "10px", textTransform: "uppercase", fontWeight: "700"}}>{point.name} (auch Suchebegriffe)</p>
								<div className="checkboxServiceContainer">
									{sortInputArray(point.words).map((word, index) => (
										<div style={{marginTop: "10px"}} key={index} className="checkboxContainer">
											<input checked={keywords[point.name] ? (keywords[point.name].includes(word) ? true : false) : false} type="checkbox" data-group={`${point.name}`} value={word} onChange={(e) => handleCheckbox(e)}/>
											<div style={{marginLeft: "10px"}}>{word}</div>
										</div>
									))}
								</div>
							</div>
						))}
					</div>
				</div>

				

				

				</>
				}

				

			</div>

			{service !== 0 && 
			<>
			<Trenner shape="small" foreground="white" background="#F6F5F9" />
			{/* type !== "zero" &&
			<>
			<div className="alarmContainer">
				<div className="headline">
					<h2>NOTDIENST-SERVICE ?</h2>
				</div>
				<div className="alarmText">
				Werde ein:e Retter:in in der Not! iVenti bietet eine Notdienst-Funktion an für Menschen, denen bei ihrer Eventplanung ein:e Dienstleister:in abgesprungen ist.Wenn du als Anbieter:in einen Notdienst-Service für dein oben stehendes Inserat bereitstellen möchtest, markiere bitte alle Tage, an denen du zur Verfügung stehst. Deine Angaben kannst du jederzeit ändern.Der Notdienst ist eine Extra-Leistung für deine Kunden. Es entstehen dir keine zusätzlichen Kosten.
				</div>
				<div style={{marginTop: "10px", display: "flex", justifyContent: "center"}}>
					<input checked={alarm} type="checkbox" className="checkboxContainer" onChange={(e) => setAlarm(e.target.checked)}/>
					<div style={{marginLeft: "10px"}}>Klingt super. Will ich!</div>
				</div>
				{alarm && 
					<DayPickerCustomFunction selectedDays={selectedDays} setSelectedDays={setSelectedDays} />
				}
			</div>

			<div style={{backgroundColor: "white"}}>
					<img style={{display: "block"}} src={TopShapeGrey} />
			</div>
			</>
			*/}

			


			<div style={{width: "100%", display: "flex", justifyContent: "center", padding: "50px 0 50px 0", backgroundColor: "white"}}>
			{loadingSubmit ? 
					<button className="loadingButton"><CircularProgress style={{ color: "white", width: "31px", height: "31px" }} /></button>
					:
					<>
					{type === "zero" ?
						<button className="submitAdButton" onClick={saveAd}>Speichern</button> :
						<button className="submitAdButton" onClick={saveAd}>Speichern</button>
					}
					</>
			}
			</div>
			</>
			}
			</>
			}
			</>
			}
		</>
	)
}

export default EditAd;
