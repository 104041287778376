import { useUserContext } from '../context/UserContext'
import { Outlet } from 'react-router-dom'
import LoginProvider from './provider/LoginProvider'
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom'
import Loader from './Loader';

const ProtectedRoutes = () => {

    const { user, loading } = useUserContext()

    return (
        <>
            {loading ? <Loader /> : 
            <>
                {user ? <Outlet /> : <LoginProvider />}
            </>
            }            
        </>
    )
    
}
export default ProtectedRoutes