import React, { useState, useEffect } from 'react'
import Loader from '../Loader'
import Header from './Header'
import PopUp from '../PopUp';
import { useUserContext } from '../../context/UserContext';
import { sendEmailVerification, reload } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'

function EmailVerification() {

    const [error, setError] = useState("")

    const { user, forgotPassword} = useUserContext()

    const navigate = useNavigate()

    async function reset(){
        sendEmailVerification(user).then(() => {
            setError("Wir haben dir eine E-Mail gesendet")
        }).catch((err) => {
            setError("Warte ein wenig, bevor wir dir eine weitere E-Mail senden.")
        })
	}	

    useEffect(() => {
        const inter = setInterval(() => {
            reload(user).then(() => {
                console.log(user.emailVerified)
                if(user.emailVerified === true){
                    window.location.href = "https://iventi.de/dashboard/start"
                    clearInterval(inter)
                }
            })
        }, 2000)
    }, [])
    

    return (
        <div>
            {error && <PopUp message={error} setError={setError} />}
            <Header headline={"BESTÄTIGE DEIN KONTO"} />
            <Loader />
            <div style={{ lineHeight: "25px", fontWeight: "700", maxWidth: "1000px", width: "90%", margin: "auto", textAlign: "center", marginBottom: "50px" }}>Wir haben dir eine E-Mail gesendet (Schaue auch in deinem SPAM-Ordner). <br />Bitte folge den Anweisungen um dein Konto zu bestätigen.</div>
            <div onClick={reset} style={{cursor: "pointer", fontSize: "12px", textDecoration: "underline", maxWidth: "1000px", width: "90%", margin: "30px auto 50px auto", textAlign: "center" }}>Du hast keine E-Mail erhalten? Hier erneut senden</div>
        </div>
    )
}

export default EmailVerification