import React from 'react';
import './Header.css';
import Nav from './Nav';
import './Animations.css';
import ScrollNav from './ScollNav';

const Header = props => {
    let nav;
    let headerStyle;
    if (props.nav == "scroll") {
        nav = <ScrollNav id="scrollNav" notdienst={true} />;
        headerStyle = { paddingTop: "100px" };
    } else if (props.nav == "simple") {
        nav = <Nav notdienst={true} />;
    }

    return (
        <header style={{ ...props.style, ...headerStyle }} className={props.className}>
            {props.nav && nav}
            {props.children}
        </header>
    );
}

export default Header;