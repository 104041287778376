import React, { useEffect, useState } from 'react'
import './PopUpMerkliste.css'

//material icons
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

//CONTEXT
import { useUserContext } from '../../context/UserContext';

//FIREBASE
import { getDoc ,collection, getDocs, query, where, setDoc, arrayUnion, arrayRemove, doc, addDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../Firebase'
import Loader from '../Loader';
import DayPickerSingle from '../provider/DayPickerSingle';

//ROUTER
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';


function PopUpMerkliste(props) {

    const { user } = useUserContext()

    //DATA
    const [lists, setLists] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [update, setUpdate] = useState(true)
    const [input, setInput] = useState("")
    //const [type, setType] = useState(1)
    const [selectedDay, setSelectedDay] = useState();

    //ROUTER
    const navigate = useNavigate()

    function addToList(id){
        setDoc(doc(db, `userData/${user.uid}/merklisten`, id), {
            items: arrayUnion({id: props.id, adType: props.adType})
        }, {merge: true}).then(() => {
            console.log("done")
            setUpdate(!update)
        })
    }

    function removeFromList(id){
        console.log("remove")
        setDoc(doc(db, `userData/${user.uid}/merklisten`, id), {
            items: arrayRemove({id: props.id, adType: props.adType})
        }, {merge: true}).then(() => {
            console.log("done")
            setUpdate(!update)
        })
    }

    function save() {
        setLoadingSubmit(true)
        if(input !== "" && props.type === 0){
            addDoc(collection(db, `userData/${user.uid}/merklisten`), {
                name: input,
                items: arrayUnion({id: props.id, adType: props.adType}),
                date: selectedDay ? selectedDay : null
            }, {merge: true}).then(() => {
                props.setMerkliste(false)
                setLoadingSubmit(false)
            })
        } else if(input !== "" && props.type === 1) {
            addDoc(collection(db, `userData/${user.uid}/merklisten`), {
                name: input,
                date: selectedDay ? selectedDay : null
            }, {merge: true}).then(() => {
                props.setMerkliste(false)
                props.setReload(!props.reload)
                setLoadingSubmit(false)
            })
        } else {
            props.setMerkliste(false)
            setLoadingSubmit(false)
        }
    }

    function saveEdit(){
        setLoadingSubmit(true)
        setDoc(doc(db, `userData/${user.uid}/merklisten`, props.id), {
            name: input,
            date: selectedDay ? selectedDay : null
        }, {merge: true}).then(() => {
            props.setMerkliste(false)
            props.setReload(!props.reload)
            setLoadingSubmit(false)
        })
    }

    function deleteList(){
        deleteDoc(doc(db, `userData/${user.uid}/merklisten`, props.id)).then(() => {
            props.setMerkliste(false)
            props.setReload(!props.reload)
        })
    }

    function cancel(){
        props.setMerkliste(false)
    }

    useEffect(() => {

        setLoading(true)

        var temp = []
        
        if(props.type === 0 && user){
            getDocs(collection(db, `userData/${user.uid}/merklisten`)).then((docs) => {
                docs.forEach((doc) => {
                    temp.push(doc)
                })
                setLists([...temp])
                setLoading(false)
            })
        } else if(props.id && user) {
            getDoc(doc(db, `userData/${user.uid}/merklisten`, props.id)).then((doc) => {
                setInput(doc.data().name)
                setSelectedDay(doc.data().date?.toDate())
                setLoading(false)
            })
        } else {
            setLoading(false)
        }
        

    }, [update])


    return (
        <div className="PopUp PopMerkliste">
            {(props.type === 0 && user) &&
                <div className="innerPopUp">
                    <div className="cancelIcon" onClick={() => cancel()}>+</div>
                    <div className="headlinePopUp pm_headline">Auf Merkliste setzen</div>
                    

                    {loading && <Loader />}

                    {!loading && lists.length !== 0 && input === "" && lists.map((data, index) => (
                        //<div data-value={data.id} onClick={data.data().items?.includes(props.id) ? (e) => removeFromList(e.currentTarget.getAttribute("data-value")) : (e) => addToList(e.currentTarget.getAttribute("data-value"))} className="pm_checkListItem">{data.data().items?.includes(props.id) ? <CheckBoxIcon style={{ paddingRight: "5px", color: "#FAB300" }} /> : <CheckBoxOutlineBlankIcon style={{ paddingRight: "5px", color: "lightgrey" }} /> }{data.data().name}</div>
                        <div data-value={data.id} onClick={data.data().items?.some(e => e.id === props.id) ? (e) => removeFromList(e.currentTarget.getAttribute("data-value")) : (e) => addToList(e.currentTarget.getAttribute("data-value"))} className="pm_checkListItem">{data.data().items?.some(e => e.id === props.id) ? <CheckBoxIcon style={{ paddingRight: "5px", color: "#FAB300" }} /> : <CheckBoxOutlineBlankIcon style={{ paddingRight: "5px", color: "lightgrey" }} /> }{data.data().name}</div>
                    ))}

                    {!loading && <div className="pm_checkListItem"><input value={input} onChange={(e) => setInput(e.target.value)} className="pm_input" type="text" placeholder="NEU ERSTELLLEN.." /></div>}

                    {!loading && input && <>
                        <div style={{marginTop:"20px", color: "#525252", fontWeight: "800", textTransform: "uppercase", fontSize: "14px"}}>Datum deines Events wählen:</div>
                        <DayPickerSingle selectedDays={selectedDay} setSelectedDays={setSelectedDay}/>
                    </>}
                    {loadingSubmit ? 
                    <div className="submitFlex" style={{backgroundColor: "unset", padding: "0px 0 20px 0"}}>
                        <button className="loadingButton"><CircularProgress style={{color: "white", width: "31px", height: "31px" }} /></button>
                    </div> 
                    : 
                    <button style={{ marginBottom: "20px" }} onClick={() => save()} className="submitAdButton">FERTIG</button>
                    }
                    
                </div>
            }
            {(props.type === 1 && user) &&
                <div className="innerPopUp">
                    <div className="headlinePopUp pm_headline">Merkliste hinzufügen</div>
                    <div className="cancelIcon" onClick={() => cancel()}>+</div>
                    

                    {loading && <Loader />}

                    {!loading && <>
                        <div className="pm_checkListItem"><input style={{textAlign: "center", width: "100%"}} value={input} onChange={(e) => setInput(e.target.value)} className="pm_input" type="text" placeholder="NAME EINGEBEN..." /></div>
                        <div style={{marginTop:"20px", color: "#525252", fontWeight: "800", textTransform: "uppercase", fontSize: "14px"}}>Datum deines Events wählen:</div>
                        <DayPickerSingle selectedDays={selectedDay} setSelectedDays={setSelectedDay}/>
                    </>}
                    {loadingSubmit ? 
                    <div className="submitFlex" style={{backgroundColor: "unset", padding: "0px 0 20px 0"}}>
                        <button className="loadingButton"><CircularProgress style={{color: "white", width: "31px", height: "31px" }} /></button>
                    </div> 
                    : 
                    <button style={{ marginBottom: "20px" }} onClick={props.id ? () => saveEdit() : () => save()} className="submitAdButton">FERTIG</button>
                    }
                    {props.id && <a style={{textDecoration: "underline" ,cursor: "pointer", marginBottom: "20px", fontSize: "12px", color: "red"}} onClick={() => deleteList()}>MERKLISTE LÖSCHEN</a>}
                </div>
            }
            {!user &&
                <div className="innerPopUp" style={{justifyContent: "center", alignItems: "center"}}>
                    <div className="cancelIcon" onClick={() => cancel()}>+</div>
                    <div style={{textAlign: "center", padding: "10px 30px 5px 30px"}} className="headlinePopUp pm_headline">Konto anlegen und <br/>Merkliste nutzen</div>
                    <button style={{ marginBottom: "20px" }} onClick={() => navigate("/register")} className="submitAdButton">KONTO ERSTELLEN</button>
                </div>
            }

        </div>
    )
}

export default PopUpMerkliste