import React, { useState, useEffect } from "react";
import "./ScrollNav.css";
import { useUserContext } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";

import CustomSelect from "../ui/CustomSelect";
import MenuItem from "@mui/material/MenuItem";

import IventiLogo from "../../img/LogoIventiWeiß.svg";
import CustomIcon from "./CustomIcon";
import iconNotdienstWeiss from "../../img/Icon_Notdienst_Weiss.svg";
import SearchForm from "./SearchForm";


const ScrollNav = (props) => {
	const { user, logoutUser } = useUserContext()
	const [scrolled, setScrolled] = useState(false);
	const scrollOffset = props.scrollOffset ? props.scrollOffset : 80;
	const navigate = useNavigate();

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	function handleScroll(event) {
		if (
			document.body.scrollTop > scrollOffset ||
			document.documentElement.scrollTop > scrollOffset
		) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	}

	return (
		<nav
			className={`scrollNav backgroundAnimation ${
				props.onlyVisibleOnScroll ? "onlyVisibleOnScroll" : ""
			} ${scrolled ? "scrolled" : ""}`}
		>
			<img
				src={IventiLogo}
				alt="iVenti.de - Logo"
				onClick={() => navigate("/")}
			/>
			<SearchForm
				click={() => navigate("/searchResults")}
				design="inside-scroll-nav"
				notdienst={props.notdienst}
				id="searchForm--desktop-only"
			/>
			<input type="checkbox" id={props.id} name={props.id} />
			<label htmlFor={props.id}>
				<div className="navIcon"></div>
			</label>
			<div className="content">
				<ul className="links">
					<li>
						<a onClick={() => navigate("/")}>STARTSEITE</a>
					</li>
					{/* <li><a>MEINE MERKLISTEN</a></li> */}
					<li>
						<a onClick={() => navigate("/anbieter")}>FÜR ANBIETER:INNEN</a>
					</li>
					<li>
						<a onClick={() => navigate("/register")}>REGISTRIEREN</a>
					</li>
					{!user && <li><a onClick={() => navigate("/login")}>ANMELDEN</a></li>}
                	{user && <li><a onClick={() => navigate("/dashboard")}>ZUM DASHBOARD</a></li>}
				</ul>
				<SearchForm
					click={() => navigate("/searchResults")}
					design="inside-scroll-nav"
					notdienst={props.notdienst}
					id="searchForm--mobile-only"
				/>
			</div>
		</nav>
	);
};

export default ScrollNav;
