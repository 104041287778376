import React, { useState, useEffect } from 'react'
import Loader from '../Loader'
import './MemoryList.css'

//CONTEXT
import { useUserContext } from '../../context/UserContext';

//FIREBASE
import { collection, getDocs, query, where, getDoc, doc, deleteDoc, setDoc, arrayRemove } from 'firebase/firestore';
import { db } from '../../Firebase'
import PopUpMerkliste from '../customer/PopUpMerkliste';

//ROUTER
import { useNavigate, useParams } from 'react-router-dom';

//UI Imports
import CustomIcon from '../ui/CustomIcon';

//Icons and Images
import iconLocationPin from '../../img/Icon_Staedte_Map_Pin_Grosz.svg';
import iconAusrufezeichen from '../../img/Icon_i_Info_Anbieter_Merkmal.svg';
import iconMerkliste from '../../img/Icon_Favorit_Verlauf.svg';
import iconNotdienst from '../../img/Icon_Notdienst_Verlauf.svg';
import iconNotdienstAktiv from '../../img/Icon_Notdienst_Aktiv.png';
import iconKontakt from '../../img/Icon_Nachricht_schreiben.svg';
import GratisAnfragePopUp from '../ui/GratisAnfragePopup';

function MemoryList() {

    const [loading, setLoading] = useState(true)
    const [reload, setReload] = useState(true)

    //DATA
    const [merklisten, setMerklisten] = useState([])
    const [merkliste, setMerkliste] = useState(false)
    const [merklisteEdit, setMerklisteEdit] = useState(false)
    const [results, setResults] = useState()
    const [items, setItems] = useState([])

    //CONTACT
    const [contactPopupVisible, setContactPopupVisible] = useState(false)
    const [inseratData, setInseratData] = useState()

    const { user, notdienstSelectedDays } = useUserContext()

    const { id } = useParams()

    const navigate = useNavigate()

    useEffect(() => {

        setLoading(true)

        getDocs(collection(db, `userData/${user.uid}/merklisten`)).then((docs) => {

            var tempMerklisten = []

            docs.forEach((doc) => {
                tempMerklisten.push(doc)
            })

            //SORT BY DATE
            tempMerklisten.sort(function(a,b){
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(a.data().date?.toDate()) - new Date(b.data().date?.toDate());
            })

            setMerklisten([...tempMerklisten])
            setLoading(false)
        })

        
    }, [reload])

    async function getItems(docs){
        var promises = []
        
        if(docs.data().items){
            docs.data().items.forEach((item) => {
                //1 = INSPO
                //0 = POST
                if(item.adType === 0){
                    promises.push(getDoc(doc(db, "posts", item.id)))
                } else if(item.adType === 1){
                    promises.push(getDoc(doc(db, "inspos", item.id)))
                }
            })
        }

        var returnArray = await Promise.all(promises)
        return returnArray
    }


    useEffect(() => {

        if(id){
            var tempItems = []
            getDoc(doc(db, `userData/${user.uid}/merklisten`, id)).then((docs) => {
                if(docs.exists()){
                    getItems(docs).then((data) => {
                        setItems([...data])
                        setResults(docs)
                    })
                } else {
                    setResults()
                }
            })
        }
        
    }, [id, reload])


    function getTime(time){
        if(time){
            var time = new Date(time.toDate())
            return time.toLocaleDateString("de-DE")
        } else {
            return ""
        }
    }

    function checkEmergency(data){
		var temp = []
		notdienstSelectedDays.forEach((time) => {
			var date = new Date(time)
			date = String(date.getTime()).slice(0, -3)
			temp.push(parseInt(date))
		})

        if(notdienstSelectedDays.length !== 0){
            if(data.data().selectedDays.some(e => temp.includes(e.seconds))){
                return true
            } 
        } 

        return false
        
    }

    function deleteFromList(deleteId, type){
        setDoc(doc(db, `userData/${user.uid}/merklisten`, id), {
            items: arrayRemove({id: deleteId, adType: type})
        }, {merge: true}).then(() => {
            console.log("done")
            setReload(!reload)
        })
    }



    return (
        <div className="MemoryList">
            {loading && <Loader />}

            {merkliste && <PopUpMerkliste type={1} setReload={setReload} reload={reload} setMerkliste={setMerkliste}/> }
            {merklisteEdit && <PopUpMerkliste id={id} type={1} setReload={setReload} reload={reload} setMerkliste={setMerklisteEdit}/> }
            {contactPopupVisible && <GratisAnfragePopUp setPopupVisible={setContactPopupVisible} inseratData={inseratData} />}

            {!loading && <>
                
                <div className="headline" style={{textAlign: "center", marginTop: "40px"}}>
                    <h2>Meine Merklisten</h2>
                    <div onClick={() => setMerkliste(true)} style={{textDecoration: "underline", color: "#FAB300", fontSize: "14px", fontWeight: "600", padding: "10px 0 10px 0", cursor: "pointer"}}>Merkliste hinzufügen</div>
                </div> 

                {/** BUTTONS MERKLISTEN */}
                <div className="ml_buttonContainer">
                    {merklisten.length !== 0 && merklisten.map((data, index) => ( 
                        <div onClick={() => navigate(`/Dashboard/merkliste/${data.id}`)} key={index} className={"ml_button" + (data.id === id ? " active" : "")}>
                            <div className="ml_headline">{data.data().name}</div>
                            <div className="ml_date">{getTime(data.data().date)}</div>
                        </div>
                    ))}
                </div>
                
            </>}

            {/** SHOW RESULTS FROM MEMORY LIST */}
            
            {results && <>
                <div className="headline" style={{textAlign: "center", marginTop: "40px"}}>
                    <h2>{results.data().name} {results.data().date?.toDate().toLocaleDateString("de-DE")}</h2>
                    <div onClick={() => setMerklisteEdit(true)} style={{textDecoration: "underline", color: "#FAB300", fontSize: "14px", fontWeight: "600", padding: "10px 0 10px 0", cursor: "pointer"}}>Merkliste bearbeiten</div>
                </div>

                {items.length === 0 && <p style={{textAlign: "center", marginTop: "20px"}}>Keine Inserate auf deiner Merkliste...</p>}

                {/** SHOW RESULTS */}
                <div style={{marginTop: "25px"}} className='ergebnisse'>
                    {items.length !== 0 && items.map((data, key) => (
                        <div key={key}>
                            <div className='top'>
                                <img src={data.data().img[0].url} alt="" />
                                <div>
                                    <h4>{data.data().title}</h4>
                                    <ul>
                                        <li><CustomIcon image={iconAusrufezeichen} imageAlt="Location Pin" />{data.data().servicesName}</li>
                                        <li><CustomIcon image={iconAusrufezeichen} imageAlt="Merkmal" />{data.data().serviceQuick}</li>
                                        <li><CustomIcon image={iconAusrufezeichen} imageAlt="Merkmal" />{data.data().nameQuick}</li>
                                        <li><CustomIcon image={iconLocationPin} imageAlt="Merkmal" />{data.data().address}{data.data().address && (data.data().zip || data.data().location) ? ", " : ""}{data.data().zip}{data.data().zip ? " " : ""}{data.data().location}</li>
                                    </ul>
                                    {(data.data().type === "flat" || data.data().type === "plus") && <button className="buttonSmallAd" id={data.id} onClick={(e) => navigate(`/adminAd/${e.currentTarget.id}/${data.data().cat}`)}>Mehr Infos und Bildergalerie</button>}
                                </div>
                            </div>
                            
                            <button id={data.id} className='btnMerkliste' style={{borderRadius: "0px"}} onClick={(e) => deleteFromList(e.currentTarget.id, data.data().cat === "inspo" ? 1 : 0)}>
                                <CustomIcon image={iconMerkliste} imageAlt="Merkliste" dimensions={{ width: '20px'}} />Von Merkliste entfernen
                            </button>
                            
                            <button className='btnNotdienst'><CustomIcon image={checkEmergency(data) ? iconNotdienstAktiv : iconNotdienst} imageAlt="Notdienst" dimensions={{ width: '25px' }} />{checkEmergency(data) ? "Notdienst aktiv" : "Aktuell kein Notdienst"}</button>
                            <button className='btnAnfrage' onClick={() => {
                                setContactPopupVisible(true);
                                setInseratData({ anbieterName: data.data().nameQuick, inseratTitel: data.data().title, email: data.data().userMail });
                            }}>
                                <div>
                                    <span>Gratis Anfrage </span>
                                    Mit diseser Anfrage trittst du direkt mit dem Anbieter in kontakt. iVenti ist an dieser Kommunikation nicht beteiligt und speichert sie nicht.
                                </div>
                                <CustomIcon image={iconKontakt} imageAlt="Kontaktieren" dimensions={{ width: '35px' }} />
                            </button>
                        </div>
                    ))}
                </div>
                
            </>}
            


        </div>
    )
}

export default MemoryList