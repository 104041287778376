// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage";
import { getAuth } from 'firebase/auth'
 
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAyl028s8IZf9qeTREi_ZewblWABBSZGdQ",
  authDomain: "iventi-6d02a.firebaseapp.com",
  projectId: "iventi-6d02a",
  storageBucket: "iventi-6d02a.appspot.com",
  messagingSenderId: "770930290486",
  appId: "1:770930290486:web:6259b83247a5f13d0c0d97"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const storage = getStorage(app)
export const db = getFirestore(app)
export const auth = getAuth(app)